<template>
	<div class="modal" tabindex="-1">
		<div class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
			role="document"
			style="pointer-events: auto;">
			<div class="modal-content">
				<div class="modal-body p-6">
					<div class="inner-modal flex justify-content-center">
						<div class="close-modal" data-toggle="modal" data-target="#propertyModal">×</div>
						<div class="modal-body-content m-0" v-show="ready">
							<h3>{{ photosDescription[index] | capitalize }}</h3>
							<img style="max-height: 600px;" @load="ready = true" :src="photos[index]">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>	
</template>
<script>

	export default {
		name: 'PropertyModal',	
		props: ['title', 'img', 'photos', 'photosDescription', 'index'],
		data() {
			return {
				ready: false,
			}
		},
		watch: {
			index(){
				this.ready = false;
			}
		},
		filters: {
            capitalize: function (value) {
                if (!value) return ''
                    value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
	}
</script>
