<template>
    <div class="page-main-footer">
        <footer>
            <div class="col-logo">
                <a target="_blank" href="http://www.apolar.com.br" class="apolar-logo">
                    <img src="../images/logo_apolar_digital.png"/>
                </a>
            </div>
            <div class="col-social">
                <a target="_blank" href="https://pt-br.facebook.com/ApolarImoveis/">
                    <img src="../images/fb-button-footer.png"/>
                </a>
                <a target="_blank" href="https://www.instagram.com/apolarimoveis/?hl=pt-br">
                    <img src="../images/twt-button-footer.png"/>
                </a>
            </div>
            <div class="col-rights">2021 Copyright – Apolar Imóveis | Todos os direitos reservados</div>

            <div class="purple-bar"></div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'MainFooter',
        components: {
        },
        computed: {
            
        }
    }
</script>
