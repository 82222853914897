import axios from 'axios'

//const apiDomain = 'https://api-procura.apolar.com.br';
const apiDomain = 'https://homologation-api-procura.apolar.srv.br';
//const apiDomain = 'http://localhost:4001';
const apiUrl = `${apiDomain}/api/v1`;

export default axios.create({
    'baseURL': apiUrl
});
