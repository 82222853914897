<template>
  <div class="modal" tabindex="-1">
    <div
      class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
      role="document"
      style="pointer-events: auto"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div v-if="type == 'discard'" class="inner-modal">
            <div
              class="close-modal"
              ref="closeModal"
              data-toggle="modal"
              :data-target="`#discardModal_${property_id}`"
            >
              ×
            </div>
            <div class="modal-body-content">
              <img src="../images/discard-property.svg" />
              <h3>Quer mesmo descartar este imóvel?</h3>
              <p style="margin-bottom: 0rem !important">
                Ao descartar esse imóvel, ele não aparecerá mais no seus
                resultados de busca atual, realizando uma nova busca o mesmo
                pode retornar como uma opção de escolha!
              </p>
              <div
                style="margin-top: 1rem !important"
                class="secondary-button hidden md:flex"
                @click="discardProperty"
              >
                Descartar
              </div>
              <div class="modal-mobile-footer">
                <a
                  data-toggle="modal"
                  :data-target="`#discardModal_${property_id}`"
                >
                  <i class="icon icon-arrow-left highlight"></i>
                  Cancelar
                </a>
                <a @click="discardProperty">
                  <i class="icon icon-discard highlight"></i>
                  Descartar
                </a>
              </div>
            </div>
          </div>
          <div v-if="type == 'favorite'" class="inner-modal">
            <div
              class="close-modal"
              ref="closeModalFavorite"
              data-toggle="modal"
              :data-target="`#favoriteModal_${property_id}`"
            >
              ×
            </div>
            <div class="modal-body-content">
              <h3>
                Deseja adicionar alguma anotação/observação a este favorito para
                você?
              </h3>
              <div class="input-set">
                <input
                  class="form-input"
                  v-model="$v.comment.$model"
                  required
                />
                <label
                  class="form-label md:hidden pointer-events-none"
                  :class="{ 'hide-small': !comment }"
                  >Escreva um comentário</label
                >
                <label
                  class="form-label hidden md:block pointer-events-none"
                  :class="{ 'hide-small': !comment }"
                >
                  Escreva um comentário sobre o imóvel aqui
                </label>
              </div>
              <p
                class="text-error mt-n2 mb-0"
                v-if="sent && $v.comment.$invalid"
              >
                Esse campo é obrigatório
              </p>
              <div
                class="primary-button"
                @click="sendForm"
                style="margin-top: 1rem !important"
              >
                <div
                  v-if="loadingFavoritar"
                  class="spinner-border text-success-darker"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-else>Favoritar</span>
              </div>
              <div
                class="secondary-button"
                @click="favoriteProperty"
                style="margin-top: 1rem !important"
              >
                <div
                  v-if="loadingFavoritarSemComentar"
                  class="spinner-border text-success-darker"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-else>Favoritar sem comentar</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import FavoriteService from "../js/api/FavoriteService";
import CommentService from "../js/api/CommentService";

export default {
  name: "SearchModal",
  props: {
    type: {
      type: String,
      required: false,
      default: () => [],
    },
    property_id: { type: Number },
    relevance: { type: Number },
  },
  data() {
    return {
      comment: null,
      sent: false,
      loadingFavoritar: false,
      loadingFavoritarSemComentar: false,
    };
  },
  validations: {
    comment: {
      required,
    },
  },

  methods: {
    discardProperty() {
      this.$store.commit("discardProperty", this.property_id);

      this.$refs.closeModal.click();
    },

    async favoriteProperty() {
      this.loadingFavoritarSemComentar = true;
      let self = this;
      try {
        await FavoriteService.post(
          { property_id: this.property_id, relevance: this.relevance },
          this.$store.state.userToken
        );
        self.$refs.closeModalFavorite.click();
        this.$emit("updateFavorite", true);
        this.loadingFavoritarSemComentar = false;
      } catch (err) {
        this.loadingFavoritarSemComentar = false;
      }
    },

    async sendForm() {
      this.loadingFavoritar = true;
      this.sent = true;

      if (!this.$v.comment.$invalid) {
        try {
          await CommentService.post(
            { comment: this.comment, property_id: this.property_id },
            this.$store.state.userToken
          );
          await this.favoriteProperty();
        } catch (err) {
          this.loadingFavoritar = false;
        }
      } else {
        this.loadingFavoritar = false;
      }
    },
  },
};
</script>
