<template>
  <div class="input-set autocomplete">
    <input
      autocomplete="off"
      class="form-input"
      id="autocomplete"
      v-bind:value="search"
      v-on:input="search = $event.target.value"
      @input="onChange"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      @keydown.tab="onEnter"
      :placeholder="placeholder"
      required
    />
    <label v-if="label" class="form-label" for="autocomplete">{{ label }}</label>
    <ul v-show="isOpen" class="autocomplete-results">
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >{{ result }}</li>
    </ul>
  </div>
</template>

<script>
var accents = require("remove-accents");

export default {
  name: "autocomplete",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: null,
    initial_value: null,
    placeholder: null,
  },
  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
      isLoading: false,
      arrowCounter: -1,
    };
  },
  created() {
    this.search = this.initial_value;
  },
  watch: {
      initial_value: function() {
          this.search = this.initial_value      
      }
  },
  methods: {
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.search = this.results[this.arrowCounter == -1 ? 0 : this.arrowCounter];
      this.isOpen = false;
      this.arrowCounter = -1;
      this.filterResults();
      this.$emit("optionSelected", this.results, this.search);
    },
    setResult(result) {
      this.search = result;
      this.isOpen = false;
      this.filterResults();
      this.$emit("optionSelected", this.results, this.search);
    },
    onChange() {
      this.isOpen = true;
      this.filterResults();
      this.$emit("optionSelected", this.results, this.search);
    },
    filterResults() {
      this.results = this.items.filter(
        (item) =>
          accents
            .remove(item.toLowerCase())
            .indexOf(accents.remove(this.search.toLowerCase())) > -1
      );
    },
  },
  mounted() {
      this.search = this.initial_value;
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
