import Api from '../api/Api.js'

const apiPath = 'demands';
export default {
    get(filter = "") {
        return Api.get(apiPath + filter);
    },
    getById(id){
        return Api.get(apiPath+`/${id}`);
    },

    changeStatus(id, newStatus){
        return Api.put(apiPath+`/status/${id}`, {"status": newStatus})
    },

    postDemand(payload){
        return Api.post(apiPath, {demand: payload});
    }
};
