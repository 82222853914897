<template>
    <div v-if="isLoaded" class="page" style="background-color: #C2E3EA">
        <div class="page-header md:hidden">
            <a href="" class="close-button">
                <i class="icon icon-arrow-link-left dark-gray"></i>
            </a>
            <h1>Seus favoritos</h1>
        </div>
        <div class="page-header hidden md:flex" style="background-color: #C2E3EA;">
            <div class="container">
                <a class="main-desktop-button" href="#/">Voltar</a>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="row md:row-reverse md:pt-8">
                    <div class="map-column col-lg-5">
                        <div class="map-wrapper">
                            <button class="button-border-yellow" @click="$refs.propertyMapMobile.applyTraffic()">
                                Ver Tráfego Ao Vivo
                            </button>
                            <!-- GoogleMapContainerMultiple ref="propertyMapMobile" v-if="isLoaded" :properties="properties" :main-address="null" /-->
                            <OpenStreetContainerMultiple :mainAddress="null"
                                                            :properties="properties"/>
                        </div>
                    </div>
                    <div class="cards-column col-lg-7">
                        <div class="cards-header hidden md:flex">
                            <h1>Seus Favoritos</h1>
                        </div>

                        <div class="feed">
                            <div v-for="property in getPaginatedFavorites" :key="property.property_id"
                                 class="property-card-wrapper">
                                <PropertyCard
                                        :favorites_page="true"
                                        :relevance="property.relevance"
                                        :property_reference="property.property_reference"
                                        :remove_buttons="true"
                                        @updateRemoved="updateRemoved"
                                />
                            </div>
                            <Pagination :offset.sync="offset" :instances-per-page="instancesPerPage"
                                        :total-count="getFilteredFavorites.length"></Pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from "../js/api/UserService";
    import UserClass from "../js/classes/user";
    import PropertyCard from "../components/property/PropertyCard.vue";
    //import GoogleMapContainerMultiple from "../components/GoogleMapContainerMultiple";
    import OpenStreetContainerMultiple from "../components/OpenStreetContainerMultiple";
    import Pagination from "../components/filters/Pagination";

    export default {
        name: 'Favorites',
        components: {
            PropertyCard,
            //GoogleMapContainerMultiple,
            OpenStreetContainerMultiple,
            Pagination
        },
        data() {
            return {
                'user': UserClass,
                isLoaded: false,
                removedProperties: [],
                instancesPerPage: 5,
                favorites: [],
                properties: [],
                mapProperties: [],
                offset: 0,
            }
        },
        created() {
            this.getData(localStorage.token);
        },
        computed: {
            getFilteredFavorites(){
                return this.favorites.filter( favorite => !this.removedProperties.includes(favorite.property_id))
            },
            getPaginatedFavorites() {
                return this.getFilteredFavorites.slice(this.offset, this.offset + this.instancesPerPage)
            }
        },
        methods: {
            async getData(token) {
                try {
                    const {data} = await UserService.getData(token);
                    this.user = new UserClass(data['data']);
                    this.favorites = this.user.favorite;
                    this.properties = this.favorites.map(prop => prop.property);
                    this.mapProperties = this.favorites.map(prop => prop.property);
                    this.isLoaded = true;
                } catch (err) {
                    // console.log(err);
                }
            },

            updateRemoved(property_id) {
                this.removedProperties.push(property_id);
                this.favorites = this.favorites.slice();

            }
        },
    }
</script>
