<template>
  <div class="page">
    <div class="main" style="background-color: #C2E3EA;">
      <div class="main-icons">
        <a class="icon" href="#/" style="background-color: #C2E3EA;">
          <i class="icon icon-arrow-link-left white"></i>
        </a>
      </div>
      <div class="main-line">
        <h2 style="font-size: 2rem;">
          DESCREVA O
          <strong>IMÓVEL PERFEITO</strong> PARA VOCÊ
        </h2>
      </div>
    </div>
    <div class="main-desktop" style="background-color: #C2E3EA; border-color: #C2E3EA;">
      <div class="container">
        <a
          class="main-desktop-button"
          style="cursor:pointer;"
          @click="cleanAndRedirect()"
        >Voltar à home</a>
        <StepCounter step-count="3" steps-done="1" />
      </div>
    </div>
    <div class="content">
      <div class="container flex">
        <div class="complementary-info">
          <div class="complementary-info-content">
            <h2 style="font-size: 2rem;">DESCREVA O IMÓVEL PERFEITO PARA VOCÊ</h2>
            <p>
              Preencha os filtros de busca. Além da cidade, selecione pelo menos mais 2 filtros.
              <b>Quanto mais informações, mais assertivo o resultado para a sua pesquisa.</b>
            </p>
          </div>
          <img src="@/images/search-filters-desktop-house.svg" />
        </div>
        <form class="main-info">
          <div style="display:none" class="switch-button mb-3">
            <div
              :class="{'active': dealType === 'Vendas'}"
              @click="dealType = 'Vendas'"
            >Para Comprar</div>
            <div
              :class="{'active': dealType === 'Locação'}"
              @click="dealType = 'Locação'"
            >Para Alugar</div>
          </div>
          <Autocomplete
            :initial_value="city ? city.label : null"
            :items="cities.map(city => city.label)"
            @optionSelected="onSelectCity"
            label="Em qual cidade? *"
          />
          <div class="swap" v-if="$store.getters.getSearchExchange.length === 0">
            <img class="img-swap" src="@/images/swap-offer.svg" />
            <div class="swap-text" @click="$router.push({name: 'exchange'})">
              <h1>Tem algum bem para dar como parte do pagamento?</h1>
              <a>
                <p style="font-size:15px;">Cadastre agora sua permuta</p>
                <i class="icon icon-arrow-down white"></i>
              </a>
            </div>
          </div>
          <div class="swap flex-column" v-else>
            <div class="swap-info">
              <h1
                class="ml-6 mt-4"
                v-if="$store.getters.getSearchExchange[0]['exchange_type'] === 'property'"
              >
                Ofereço
                {{$store.getters.getSearchExchange[0]['property_type_label']}} no
                {{$store.getters.getSearchExchange[0]['neighborhood_label']}}
              </h1>
              <h1
                class="ml-6 mt-4"
                v-else
              >Ofereço {{$store.getters.getSearchExchange[0]['vehicle_make']}} {{$store.getters.getSearchExchange[0]['vehicle_model']}}</h1>
              <a
                href
                class="swap-close-button"
                @click.prevent="$store.commit('clearSearchExchange')"
              >
                <i class="icon icon-close white"></i>
              </a>
              <div class="flex ml-6 mt-4">
                <div class="value mr-8">
                  <span>Valor Aproximado</span>
                  <div class="currency-display">
                    <p class="currency-type">R$</p>
                    <div class="currency-value">
                      {{$store.getters.getSearchExchange[0]['value'] |
                      currency}}
                    </div>
                    <div class="currency-cents">,00</div>
                  </div>
                </div>
                <!--div
                  class="value"
                  v-if="$store.getters.getSearchExchange[0]['exchange_type'] === 'property'"
                >
                  <span>Área Útil</span>
                  <div class="currency-display">
                    <div class="currency-value">{{$store.getters.getSearchExchange[0]['area']}}</div>
                    <div class="currency-cents mt-3 ml-1">m²</div>
                  </div>
                </div -->
                <div class="value" v-if="$store.getters.getSearchExchange[0]['exchange_type'] !== 'property'">
                  <span>Ano</span>
                  <div class="currency-display">
                    <div class="currency-value">{{$store.getters.getSearchExchange[0]['year']}}</div>
                  </div>
                </div>
              </div>
              <div
                class="demand-card-features row"
                v-if="$store.getters.getSearchExchange[0]['exchange_type'] == 'property'"
              >
                <div class="feature col-6 mb-4">
                  <i class="icon icon-bed white"></i>
                  <p class="feature-value">
                    {{ $store.getters.getSearchExchange[0]['rooms'] }}
                    <span
                      class="feature-description"
                      v-if="$store.getters.getSearchExchange[0]['rooms'] == 1"
                    >Dormitório</span>
                    <span class="feature-description" v-else>Dormitórios</span>
                  </p>
                </div>

                <div class="feature col-6 mb-4">
                  <i class="icon icon-suite white"></i>
                  <p class="feature-value">
                    {{ $store.getters.getSearchExchange[0]['suites'] }}
                    <span
                      class="feature-description"
                      v-if="$store.getters.getSearchExchange[0]['suites'] == 1"
                    >Suíte</span>
                    <span class="feature-description" v-else>Suítes</span>
                  </p>
                </div>

                <div class="feature col-6">
                  <i class="icon icon-toilet white"></i>
                  <p class="feature-value">
                    {{ $store.getters.getSearchExchange[0]['bathrooms'] }}
                    <span
                      class="feature-description"
                      v-if="$store.getters.getSearchExchange[0]['bathrooms'] == 1"
                    >Banheiro</span>
                    <span class="feature-description" v-else>Banheiros</span>
                  </p>
                </div>

                <div class="feature col-6">
                  <i class="icon icon-car white"></i>
                  <p class="feature-value">
                    {{ $store.getters.getSearchExchange[0]['garages'] }}
                    <span
                      class="feature-description"
                      v-if="$store.getters.getSearchExchange[0]['garages'] == 1"
                    >Vaga</span>
                    <span class="feature-description" v-else>Vagas</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="exchange-separator"></div>
            <div class="ml-auto mr-4 mb-4">
              <a href class="edit-offer" @click.prevent="$router.push({name: 'exchange'})">
                Alterar
                Oferta
              </a>
            </div>
          </div>
          <InputBox
            :form-data="propertyTypes"
            :selected-data="inputBoxValues['propertyType']"
            @updateCheckedValues="updateCheckedValues"
            description="Quer apartamento, sala comercial?"
            label="propertyType"
            title="Tipo de Imóvel *"
          />

          <div class="input-box h-auto">
            <h1>Área de Busca</h1>
            <div class="switch-button mt-4 mb-5">
              <div
                :class="{'active': addressSearchType === 'neighborhood'}"
                @click="setAddressSearchType('neighborhood')"
              >Bairros</div>
              <!--div
                :class="{'active': addressSearchType === 'within_radius'}"
                @click="setAddressSearchType('within_radius')"
              >No Raio</div-->
              <div
                :class="{'active': addressSearchType === 'on_address'}"
                @click="setAddressSearchType('on_address')"
              >Endereço</div>
            </div>
            <div class="bar-property" v-show="addressSearchType === 'neighborhood'">
              <InputBox
                :form-data="alphabeticallySortedNeighborhoods"
                
                :selected-data="inputBoxValues['neighborhoodsFilter']"
                @updateCheckedValues="updateCheckedValues"
                class="mt-n2"
                description="Selecione um ou mais bairros"
                label="neighborhoodsFilter"
                title="Bairros"
              />
            </div>
            <div v-show="addressSearchType === 'within_radius' && editingAddress">
              <div v-if="editingAddressStep === 1">
                <div id="autocomplete-map-filter">
                  <div class="input-set h-20">
                    
                    <!-- vue-google-autocomplete
                      :country="['br']"
                      class="form-input"
                      id="map-autocomplete"
                      placeholder="Busque pelo ponto de referência"
                      v-on:placechanged="getAddressData"
                      types="establishment"
                    ></vue-google-autocomplete-->

                    <Autocomplete
                      :initial_value="city ? city.label : null"
                      :items="cities.map(city => city.label)"
                      @optionSelected="onSelectCity"
                      placeholder="oii"
                    />

                  </div>
                  <img src="@/images/reference-point.svg" class="block mx-auto" />
                  <span
                    class="reference-point-text"
                  >Busque pelo endereço do ponto de referência para continuar</span>
                </div>
              </div>
              
            </div>
            <div
              class="menu-filters"
              v-show="addressSearchType === 'within_radius' && !editingAddress && address.address !== null"
            >
              <div class="flex">
                <div>
                  <p class="text-2xl text-dark-gray">{{address.radius | metersToKm}}</p>
                  <span class="icon icon-local-yellow"></span>
                </div>
                <div class="flex flex-col justify-around ml-8">
                  <p class="text-dark-gray">
                    {{address.address}}
                    <template v-if="address.number">, {{address.number}}</template>
                  </p>
                  <p class="text-sm text-gray">{{address.neighborhood}}, {{address.city}}</p>
                </div>
              </div>
              <div @click="editingAddress = true">
                <i class="icon icon-edit highlight cursor-pointer"></i>
              </div>
            </div>

            <div v-show="addressSearchType === 'on_address' && editingAddress">
              <div id="autocomplete-map-filter-on-address">
                <div class="input-set h-20">
                  <!--vue-google-autocomplete
                    :country="['br']"
                    class="form-input"
                    id="map-autocomplete-on-address"
                    placeholder="Digite o endereço"
                    v-on:placechanged="getAddressData"
                  ></vue-google-autocomplete-->

                <Autocomplete
                  :initial_value="address.address ? address.address : null"
                  :items="listAddress"
                  @optionSelected="onSelectAddress"
                  placeholder="Digite o endereço"
                />

                </div>
              </div>
            </div>
            <div
              class="menu-filters"
              v-show="addressSearchType === 'on_address' && !editingAddress && address.address !== null"
            >
              <div class="flex">
                <div>
                  <!--                                    <p class="text-2xl text-dark-gray">{{address.radius | metersToKm}}</p>-->
                  <span class="local-icon"></span>
                </div>
                <div class="flex flex-col justify-around ml-8">
                  <p class="text-dark-gray">
                    {{address.address}}
                    <template v-if="address.number">, {{address.number}}</template>
                  </p>
                  <p class="text-sm text-gray">{{address.neighborhood}}, {{address.city}}</p>
                </div>
              </div>
              <div @click="editingAddress = true">
                <i class="icon icon-edit highlight cursor-pointer"></i>
              </div>
            </div>
          </div>
          <div class="input-container">
            <div class="double-inputs">
              <div class="input-set pl-0">
                <input
                  class="form-input"
                  id="minValue forceBlurFields"
                  required
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  v-model="minValue"
                  @blur="checkMoney"
                  v-money="minValue === null ? null : money"
                />
                <label :class="{'hide-small': !minValue}" class="form-label" for="minValue">
                  Valor
                  Mínimo
                </label>
              </div>
              <div class="input-set pr-0">
                <input
                  class="form-input forceBlurFields"
                  id="maxValue"
                  required
                  v-model="maxValue"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  @blur="checkMoney"
                  v-money="maxValue === null ? null : money"
                />
                <label :class="{'hide-small': !maxValue}" class="form-label" for="maxValue">
                  Valor
                  Máximo
                </label>
              </div>
            </div>
            <div :class="{flex: dealType === 'Locação'}" v-show="dealType === 'Locação'">
              <div
                :class="{active: valueRangeType === 'rent'}"
                @click="valueRangeType = 'rent'"
                class="radio-button"
              >
                <i></i>
                Valor do Aluguel
              </div>
              <div
                :class="{active: valueRangeType === 'total'}"
                @click="valueRangeType = 'total'"
                class="radio-button"
              >
                <i></i>
                Valor Total
              </div>
            </div>
          </div>
          <div class="input-container pb-0">
            <div class="double-inputs">
              <div class="input-set pl-0">
                <input
                  class="form-input forceBlurFields"
                  id="minArea"
                  required
                  v-model="minArea"
                  @blur="checkArea"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  v-money="minArea === null? null : area"
                />
                <label :class="{'hide-small': !minArea}" class="form-label" for="minArea">
                  Área
                  Mínima Total
                </label>
              </div>
              <div class="input-set pr-0">
                <input
                  class="form-input forceBlurFields"
                  id="maxArea"
                  required
                  v-model="maxArea"
                  @blur="checkArea"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  v-money="maxArea === null? null: area"
                />
                <label :class="{'hide-small': !maxArea}" class="form-label" for="maxArea">
                  Área
                  Máxima Total
                </label>
              </div>
            </div>
          </div>
          <MultiSelect
            :initial-value="multiSelectValues['room_count']"
            @updateMultiSelectValues="updateMultiSelectValues"
            label="room_count"
            register="false"
            title="Número de Quartos (contando Suíte, Demi-Suíte)"
          />
          <MultiSelect
            :initial-value="multiSelectValues['suite_count']"
            @updateMultiSelectValues="updateMultiSelectValues"
            label="suite_count"
            register="false"
            title="Número de Suítes"
          />
          <MultiSelect
            :initial-value="multiSelectValues['bathroom_count']"
            @updateMultiSelectValues="updateMultiSelectValues"
            label="bathroom_count"
            register="false"
            title="Número de Banheiros (contando Lavabo, Banheiro Suíte, Banheiro Empregada)"
          />
          <MultiSelect
            :initial-value="multiSelectValues['garage_count']"
            @updateMultiSelectValues="updateMultiSelectValues"
            label="garage_count"
            register="false"
            title="Número de Vagas"
          />
          <div style="display:none" class="filter-section mt-16">
            <h5>Mobiliado</h5>
            <div class="flex furnished">
              <div
                :class="{active: furnished === ''}"
                @click="furnished = ''"
                class="simple-button"
              >Indiferente</div>
              <div
                :class="{active: furnished === 'yes'}"
                @click="furnished = 'yes'"
                class="simple-button"
              >Sim</div>
              <div
                :class="{active: furnished === 'no'}"
                @click="furnished = 'no'"
                class="simple-button"
              >Não</div>
              <div
                :class="{active: furnished === 'semi'}"
                @click="furnished = 'semi'"
                class="simple-button mt-1"
              >Semimobiliado</div>
            </div>
          </div>
          <div style="display:none" class="filter-section mt-8">
            <h5>Pet Friendly</h5>
            <div class="flex">
              <div
                :class="{active: petFriendly === ''}"
                @click="petFriendly = ''"
                class="simple-button"
              >Indiferente</div>
              <div
                :class="{active: petFriendly === 'yes'}"
                @click="petFriendly = 'yes'"
                class="simple-button"
              >Sim</div>
              <div
                :class="{active: petFriendly === 'no'}"
                @click="petFriendly = 'no'"
                class="simple-button"
              >Não</div>
            </div>
          </div>
          <InputBox
            style="display:none"
            :form-data="orderedPropertyAttrs"
            :selected-data="inputBoxValues['customFeaturesProperty']"
            @updateCheckedValues="updateCheckedValues"
            description="Quer Churrasqueira, Sacada, Jardim?"
            label="customFeaturesProperty"
            title="Atributos do Imóvel"
          />
          <InputBox
            style="display:none"
            :form-data="orderedCondominiumAttrs"
            :selected-data="inputBoxValues['customFeaturesCondominium']"
            @updateCheckedValues="updateCheckedValues"
            description="Quer Playground, Portaria 24h?"
            label="customFeaturesCondominium"
            title="Atributos do Condomínio"
          />
          <a class="primary-button my-8 mt-16" v-on:click.prevent="sendForm">Continuar</a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "../components/filters/MultiSelect";
import InputBox from "../components/filters/InputBox";
import StepCounter from "../components/StepCounter";
import { required } from "vuelidate/lib/validators";
import { VMoney } from "v-money";
import Autocomplete from "../components/filters/Autocomplete.vue";
import FetchCities from "../js/mixins/fetchCities";
import FetchAddresses from "../js/mixins/fetchAddresses";
import FetchPropertyTypes from "../js/mixins/fetchPropertyTypes";
import FetchDealTypes from "../js/mixins/fetchDealTypes";
import FetchCustomFeatures from "../js/mixins/fetchCustomFeatures";
//import GoogleMapContainer from "../components/GoogleMapContainer";
//import VueGoogleAutocomplete from "vue-google-autocomplete";
import CityService from "../js/api/CityService";
import NeighborhoodClass from "../js/classes/neighborhood";

export default {
  name: "Filters",
  components: {
    StepCounter,
    MultiSelect,
    InputBox,
    Autocomplete,
    //GoogleMapContainer,
    //VueGoogleAutocomplete,
  },
  directives: { money: VMoney },
  mixins: [
    FetchCities,
    FetchPropertyTypes,
    FetchCustomFeatures,
    FetchDealTypes,
    FetchAddresses
  ],
  props: {
    initial_city: {
      default: null,
      type: Object,
    },
    initial_dealType: {
      default: "Vendas",
      type: String,
    },
  },

  created() {
    const storedSearch = this.$store.state.searchData;
    if (storedSearch.city) {
      this.city = storedSearch.city;
      this.fetchNeighborhoods(storedSearch.city.id);
    }
    this.dealType = storedSearch.dealType;
    this.inputBoxValues["neighborhoodsFilter"] = storedSearch.neighborhoods;
    this.inputBoxValues["propertyType"] = storedSearch.propertyTypes;
    this.minValue = storedSearch.minValue;
    this.maxValue = storedSearch.maxValue;
    this.valueRangeType = storedSearch.valueRangeType;
    this.minArea = storedSearch.minArea;
    this.maxArea = storedSearch.maxArea;
    this.multiSelectValues["room_count"] = storedSearch.room_count;
    this.multiSelectValues["bathroom_count"] = storedSearch.bathroom_count;
    this.multiSelectValues["suite_count"] = storedSearch.suite_count;
    this.multiSelectValues["garage_count"] = storedSearch.garage_count;
    this.furnished = storedSearch.furnished;
    this.petFriendly = storedSearch.petFriendly;
    this.inputBoxValues["customFeaturesProperty"] =
      storedSearch.customFeaturesProperty;
    this.inputBoxValues["customFeaturesCondominium"] =
      storedSearch.customFeaturesCondominium;
    this.address = storedSearch.address;
    this.addressSearchType = storedSearch.addressSearchType;
    if (this.address.address !== null) {
      this.editingAddress = false;
    } else {
      this.addressSearchType = "neighborhood";
    }

    if (this.addressSearchType !== "neighborhood") {
      this.inputBoxValues["neighborhoodsFilter"] = [];
    }
  },

  data() {
    return {
      inputBoxValues: {},
      multiSelectValues: {},
      city: this.initial_city,
      neighborhoods: null,
      dealType: this.initial_dealType,
      addressSearchType: "neighborhood",
      address: {
        address: null,
        number: null,
        radius: 300,
        latitude: null,
        longitude: null,
        city: null,
        neighborhood: null,
      },
      minRadius: 100,
      maxRadius: 3000,
      radiusTooltipLeft: 0,
      editingAddressStep: 1,
      editingAddress: true,
      editingRadius: false,
      minValue: null,
      maxValue: null,
      valueRangeType: "rent",
      minArea: null,
      maxArea: null,
      sent: false,
      petFriendly: "",
      furnished: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true /* doesn't work with directive */,
      },
      area: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "m²",
        precision: 0,
        masked: true /* doesn't work with directive */,
      },
    };
  },
  validations: {
    city: {
      required,
    },
    minValue: {
      required,
    },
    maxValue: {
      required,
    },
    minArea: {
      required,
    },
    maxArea: {
      required,
    },
  },
  computed: {
    
    radius() {
      return this.address.radius;
    },

    alphabeticallySortedNeighborhoods() {
      if (!this.neighborhoods) {
        return null;
      }

      return this.orderList(this.neighborhoods)
    },
    orderedPropertyAttrs() {
          if (!this.customFeatures[`property`]) {
              return false
          }

          return this.orderList(this.customFeatures[`property`])
      },
    orderedCondominiumAttrs() {
        if (!this.customFeatures[`condominium`]) {
            return false
        }

        return this.orderList(this.customFeatures[`condominium`])
    },

    listAddress() {
      if (!this.city) {
        return []
      }
      var _returnAddress = []

      this.addresses.forEach((item)=>{
        if (item.city == this.city.label) {
          if (_returnAddress.indexOf(item.address) == -1) {
             _returnAddress.push(item.address)
          }
        }
      })
      return _returnAddress
    }

  },
  watch: {
    radius() {
      this.updateTooltipPosition();
    },
  },
  mounted() {
    this.updateTooltipPosition();
    
  },
  methods: {
    cleanAndRedirect() {
      const vuex_apolar = this.$store.state;
      vuex_apolar.searchData.dealTypeId = null;
      vuex_apolar.searchData.city = null;
      vuex_apolar.searchData.addressSearchType = "";
      vuex_apolar.searchData.neighborhoods = [];
      vuex_apolar.searchData.propertyTypes = [];
      vuex_apolar.searchData.address.address = null;
      vuex_apolar.searchData.address.number = null;
      vuex_apolar.searchData.address.radius = 300;
      vuex_apolar.searchData.address.latitude = null;
      vuex_apolar.searchData.address.longitude = null;
      vuex_apolar.searchData.address.city = null;
      vuex_apolar.searchData.address.neighborhood = null;
      vuex_apolar.searchData.minValue = null;
      vuex_apolar.searchData.maxValue = null;
      vuex_apolar.searchData.minArea = null;
      vuex_apolar.searchData.maxArea = null;
      vuex_apolar.searchData.valueRangeType = "rent";
      vuex_apolar.searchData.room_count.min = 0;
      vuex_apolar.searchData.room_count.max = 0;
      vuex_apolar.searchData.bathroom_count.min = 0;
      vuex_apolar.searchData.bathroom_count.max = 0;
      vuex_apolar.searchData.suite_count.min = 0;
      vuex_apolar.searchData.suite_count.max = 0;
      vuex_apolar.searchData.garage_count.min = 0;
      vuex_apolar.searchData.garage_count.max = 0;
      vuex_apolar.searchData.petFriendly = "";
      vuex_apolar.searchData.furnished = "";
      vuex_apolar.searchData.customFeaturesProperty = [];
      vuex_apolar.searchData.customFeaturesPropertyObj = [];
      vuex_apolar.searchData.customFeaturesCondominium = [];
      vuex_apolar.searchData.customFeaturesCondominiumObj = [];
      vuex_apolar.searchData.dealType = "Vendas";
      vuex_apolar.filtersOrder = [];
      vuex_apolar.filterMessages = [];
      vuex_apolar.searchResults = [];
      vuex_apolar.exchangesRegisterProperty = [];
      vuex_apolar.searchExchange = [];
      vuex_apolar.discardedProperties = [];
      this.$store.commit("clearSearchData", vuex_apolar);
      this.$router.push({ name: `new-home` });
    },
    slugify(str) {
      const map = {
        a: "á|à|ã|â|À|Á|Ã|Â",
        e: "é|è|ê|É|È|Ê",
        i: "í|ì|î|Í|Ì|Î",
        o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
        u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
        c: "ç|Ç",
        n: "ñ|Ñ",
      };

      str = str.toLowerCase();

      for (let pattern in map) {
        str = str.replace(new RegExp(map[pattern], "g"), pattern);
      }

      return str;
    },
    updateTooltipPosition() {
      const inputRangeDiv = document.getElementById("input-range-map");
      if (inputRangeDiv) {
        const inputWidth = inputRangeDiv.offsetWidth;
        const percentage =
          (this.address.radius - this.minRadius) /
          (this.maxRadius - this.minRadius);
        document.getElementById(
          "input-radius"
        ).style.backgroundImage = `linear-gradient(
                        to right,
                        #002ADA,
                        #002ADA ${percentage * 100}%,
                        rgba(0, 42, 218, 0.25) ${percentage * 100}%
                )`;
        this.radiusTooltipLeft = inputWidth * percentage;
      }
    },
    orderList(list) {
        if (!list) {
            return null;
        }

        let listOrdered = list;

        listOrdered.sort((a,b) => (this.slugify(a.label) > this.slugify(b.label)) ? 1 : ((this.slugify(b.label) > this.slugify(a.label)) ? -1 : 0))

        return listOrdered;
    },
    getAddressData(address) {
      let addressInputValue;
      if (this.addressSearchType === "within_radius") {
        addressInputValue = document.getElementById("map-autocomplete").value;
      } else {
        addressInputValue = document.getElementById(
          "map-autocomplete-on-address"
        ).value;
      }
      const cityInfo = this.getAddressCityInfo(addressInputValue);
      this.address = {
        address: address["route"],
        number: address["street_number"],
        radius: this.address.radius,
        latitude: address["latitude"],
        longitude: address["longitude"],
        neighborhood: cityInfo["neighborhood"],
        city: cityInfo["city"],
      };
      if (this.addressSearchType === "within_radius") {
        this.editingAddressStep = 2;
        this.$nextTick(() => this.updateTooltipPosition());
      } else {
        this.editingAddress = false;
      }
    },

    getAddressCityInfo(address) {
      //Rodovia Anchieta - Sacomã, São Paulo - SP, Brasil => [neighborhood: Sacomã, city: São Paulo]
      address = address.split(`-`)[1].split(",");
      return { neighborhood: address[0], city: address[1] };
    },

    storeSearchData() {
      this.$store.commit("assignSearchCity", {
        city: this.city,
        dealType: this.dealType,
        dealTypeId: this.dealTypes.find(
          (dealType) => dealType.label === this.dealType
        ).id,
        addressSearchType: this.addressSearchType,
        address: this.address,
        neighborhoods: this.inputBoxValues["neighborhoodsFilter"],
        petFriendly: this.petFriendly,
        furnished: this.furnished,
        propertyTypes: this.inputBoxValues["propertyType"],
        minValue: this.minValue || "R$ 0,00",
        maxValue: this.maxValue || "R$ 0,00",
        valueRangeType: this.valueRangeType,
        minArea: this.minArea || "0m²",
        maxArea: this.maxArea,
        room_count: this.multiSelectValues["room_count"],
        bathroom_count: this.multiSelectValues["bathroom_count"],
        suite_count: this.multiSelectValues["suite_count"],
        garage_count: this.multiSelectValues["garage_count"],
        customFeaturesProperty: this.inputBoxValues["customFeaturesProperty"],
        customFeaturesCondominium: this.inputBoxValues[
          "customFeaturesCondominium"
        ],
        customFeaturesPropertyObj: this.customFeatures[
          "property"
        ].filter((property) =>
          this.inputBoxValues["customFeaturesProperty"].includes(property.label)
        ),
        customFeaturesCondominiumObj: this.customFeatures[
          "condominium"
        ].filter((condominium) =>
          this.inputBoxValues["customFeaturesCondominium"].includes(
            condominium.label
          )
        ),
      });
    },

    sendForm() {
      
      this.storeSearchData()

      const filterKeys = this.$store.getters.getSearchFilters.map(
        (filt) => Object.keys(filt)[0]
      );
      if (
        this.$store.state.filtersOrder.length !== filterKeys.length ||
        !filterKeys.every((filt) =>
          this.$store.state.filtersOrder.includes(filt)
        )
      ) {
        // criando orders
        this.$store.commit("setFilterOrder", filterKeys);
      }

      const filters = this.$store.getters.getSearchFilters;
      if (filters.length < 2) {
        alert("Preencha pelo menos 2 filtros!");
        return false;
      }

      this.$router.push({
        name: "search-order",
      });

      // this.sent = true;
      // this.$v.$touch();
      //
      // if (this.$v.$invalid) {
      //     console.log(`form is invalid!`);
      //     return false;
      // }
      //
      // console.log('form is valid!')
    },

    onSelectCity(result, searchString) {
      if (
        searchString.toString().toLowerCase() ===
        result[0].toString().toLowerCase()
      ) {
        this.city = this.cities.find((city) => city.label === result[0]);
        this.fetchNeighborhoods(this.city.id);
      } else {
        this.city = null;
      }
      this.storeSearchData()
    },

    onSelectAddress(result, searchString) {
      console.log("Search string: ", searchString)
      console.log("Search result: ", result)
      if (
        searchString.toString().toLowerCase() ===
        result[0].toString().toLowerCase()
      ) {
        this.address.address = result[0].toString()
        this.address.city = this.city.label
        this.address.neighborhood = 'n/a'
      } else {
        this.address.address = null;
      }
    },

    async fetchNeighborhoods(cityId) {
      try {
        const { data } = await CityService.getById(cityId);
        this.neighborhoods = data["data"]["neighborhoods"];
        this.neighborhoods = this.neighborhoods.map(
          (v) => new NeighborhoodClass(v)
        );
      } catch (err) {
        // console.log(err);
      }
    },

    updateCheckedValues(value) {
      const label = Object.keys(value)[0];
      this.inputBoxValues[label] = value[label];
    },

    updateMultiSelectValues(value) {
      const label = Object.keys(value)[0];
      this.multiSelectValues[label] = value[label];
    },

    confirmAddress() {
      this.editingAddressStep = 1;
      this.editingAddress = false;
    },

    setAddressSearchType(type) {
      // se é bairro, no raio ou no endereço exato. Caso for raio ou endereço, setar editingAddress = true pra que ele consiga cadastrar um novo endereço. Por mais que os campos sejam parecidos, eles não são reaproveitaveis
      this.addressSearchType = type;
      if (type === "within_radius" || type === "on_address") {
        this.editingAddress = true;
        this.clearAddress();
      }
    },
    clearAddress() {
      this.address = {
        address: null,
        number: null,
        radius: 300,
        latitude: null,
        longitude: null,
        city: null,
        neighborhood: null,
      };
    },

    checkMoney() {
      //   if (this.minValue && !this.maxValue) {
      //     this.maxValue = this.minValue;
      //   }

      if (this.minValue && this.maxValue) {
        const min = this.removeMoneyFormatting(this.minValue);
        const max = this.removeMoneyFormatting(this.maxValue);

        if (min > max) {
          this.maxValue = this.minValue;
        }
      }
    },

    checkArea() {
      //   if (this.minArea && !this.maxArea) {
      //     this.maxArea = this.minArea;
      //   }

      if (this.minArea && this.maxArea) {
        const min = this.removeAreaFormatting(this.minArea);
        const max = this.removeAreaFormatting(this.maxArea);

        if (min > max) {
          this.maxArea = this.minArea;
        }
      }
    },

    removeMoneyFormatting(value) {
      if (value) {
        let newValue = value
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", "");
        return parseInt(newValue);
      }
    },

    removeAreaFormatting(value) {
      let newValue = value.replace("m²", "").replace(".", "");
      return parseInt(newValue);
    },
  },
};
</script>
