<template>
	<div class="input-box">
		<details class="input-box-details" @click.prevent.stop="openDetails" :open="isOpen">
			<summary>
				<div class="flex justify-content-between">
					<h1> {{ title }} </h1>
					<i class="arrow-up" v-show="isOpen"></i>
				</div>
				<div class="bar-property">
					<div class="button-bar">
						<p class="placeholder -open" v-show="checkedValues.length === 0">
							{{ description }} </p>
						<p class="placeholder -open placeholder-selected" v-show="checkedValues.length > 0">
							{{ sortedCheckedValues | toSentence("e") | limitString()}} </p>
						<i class="icon icon-arrow-down highlight"></i>
					</div>
				</div>		
			</summary>
			<InputOptions :allow-multiple="allowMultiple" :isOpen="isOpen" :title="title" @closeModal="openDetails" :selected-data="selectedData" @updateValue="updateValue" :multi="multi" :form-data="formData" />
		</details>
	</div>
</template>

<script>
	import InputOptions from "./InputOptions";

	export default {
		name: 'InputBox',
		components: {
			InputOptions
		},
		data() {
			return {
				checkedValues: this.selectedData || [],
				isOpen: false
			}
		},
		computed: {
			sortedCheckedValues(){
				return this.checkedValues.slice().sort();
			}
		},
		props: {
			title: {
				default: null,
			},
			description: {
				default: null,
			},
			label: {
				default: null,
			},
			formData: {
				default: null,
			},
			multi: {
				default: null,
			},
			selectedData: {
				default: null,
			},
			allowMultiple: {
				default: true,
			}
		},
		methods: {
			updateValue(value){
				value = value.length === 0? [] : value;
				this.$emit('updateCheckedValues', {[this.label]: value});
				this.checkedValues = value;
			},
			openDetails(){
				if(this.formData){
					this.isOpen = !this.isOpen;
				}
			}
		},
        mounted() {

            let funcCloseInput = (e) => {
                if ( e.target.closest(".input-box") != null ) {
                    return false
                } else {
                    this.isOpen = false
                }
            }

            let touchEvent = 'ontouchstart' in window ? 'touchstart' : 'click';

            document.body.addEventListener(touchEvent, (e)=>{
                funcCloseInput(e)
            }, true)

        }
	}
</script>
