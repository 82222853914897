<template>
  <div class="page">
    <div class="container">
      <div class="content">
        <img src="@/images/login.png" />
        <h1>Minha Conta</h1>
        <p class="text-login">Acesse sua conta para favoritar imóveis e acessar planilha comparativa de imóveis favoritos.</p>
      </div>
      <div class="form">
        <form>
          <div class="input-set">
            <input
              autocapitalize="none"
              class="form-input"
              id="email"
              v-model="$v.email.$model"
              required
            />
            <label class="form-label" for="email" :class="{'hide-small': !email}">E-mail</label>
            <p class="form-error" v-show="sent && !$v.email.required">Esse campo é obrigatório</p>
            <p class="form-error" v-show="sent && !$v.email.email">Insira um e-mail válido</p>
          </div>
          <div class="input-set">
            <input
              class="form-input"
              :type="showPasswordType"
              id="password"
              v-model="$v.password.$model"
              required
            />
            <label class="form-label" for="password" :class="{'hide-small': !password}">Senha</label>
            <span class="reveal-password" v-on:click="togglePassword">{{showPasswordText}}</span>
            <p class="form-hint" v-show="$v.password.$dirty && !sent">Use entre 6 e 20 caracteres</p>
            <p class="form-error" v-show="sent && wrongCredentials && !$v.$invalid">
              Credenciais incorretas.
              Por favor, tente novamente!
            </p>
            <p class="form-error" v-show="sent && !$v.password.required">Esse campo é obrigatório</p>
            <p
              class="form-error"
              v-show="sent && (!$v.password.minLength || !$v.password.maxLength)"
            >
              Use
              entre 6 e 20 caracteres
            </p>
          </div>
          <a class="forgot-password-link" href="#/forgotpassword">Esqueci minha senha</a>
          <button :class="{valid:!$v.$invalid}" v-on:click.prevent="sendForm">
            <span v-if="!loading">Entrar</span>
            <div v-if="loading" class="spinner-border text-success-darker" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
          <p class="dont-have-a-account">
            Não possui uma conta na Apolar?
            <a href="#/register">Criar conta</a>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LoginService from "../../js/api/LoginService";
import togglePasswordMixin from "../../js/mixins/togglePasswordMixin";
import {
  required,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "Login",
  mixins: [togglePasswordMixin],
  data() {
    return {
      email: null,
      password: null,
      sent: false,
      wrongCredentials: false,
      loading: false
    };
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20)
    }
  },
  methods: {
    redirectUser(role) {
      if (role === "Usuário") {
        this.$router.push({ name: "profile" });
      } else {
        this.$router.push({ name: "dashboard-demands" });
      }
    },

    async login(user) {
        this.loading = true;
      const $self = this;
      try {
        const { data } = await LoginService.login(user);
        this.$store.commit("setUserToken", data.token);
        await this.$store.dispatch("loadUser").then(function() {
          $self.redirectUser($self.$store.state.user.role);
        });
      } catch (err) {
        this.wrongCredentials = true;
        this.loading = false;
      }
    },
    sendForm() {
      this.sent = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }

      var user = {
        email: this.email,
        password: this.password
      };

      this.login(user);
    }
  },
  created() {
    if (this.$store.state.user) {
      this.redirectUser(this.$store.state.user.role);
    }
  }
};
</script>
