import PropertyClass from "./property";
class MatchClass  {
    constructor(data){
        this.id = data.id;
        this.client = data.client;
        this.property = new PropertyClass(data.property);
        this.relevance = data.relevance;
        this.status = data.status;
        this.inserted_at = data.inserted_at;
        this.offered_exchange = data.offered_exchange;
    }



    firstName = () => {
        var arr = this.client.name.split(" ");

        return arr[0];
    };
}

export default MatchClass
