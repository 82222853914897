<template>
  <header
    class="master-header"
    style="background-color: #C2E3EA; border-color: #C2E3EA"
  >
    <div class="container">
      <RouterLink :to="{ name: 'new-home' }" class="apolar-logo">
        <img src="../images/logo_apolar_procura.png" width="160" />
      </RouterLink>
      <ul class="nav" v-if="dashboardRoutes.includes($route.name)">
        <li>
          <a
            href
            @click.prevent="$router.push({ name: `new-home` }).catch((err) => {})"
            style="color: #382c7a"
            >Portal Apolar</a
          >
        </li>
        <li class="divider" />
        <li>
          <a
            href
            @click.prevent="
              $router.push({ name: `dashboard-matches` }).catch((err) => {})
            "
            style="color: #382c7a"
            >Matches</a
          >
        </li>
        <li v-if="checkAdminStoreCode(this.$store.state.user.storecode)">
          <a
            href
            @click.prevent="
              $router.push({ name: `dashboard-demands` }).catch((err) => {})
            "
            style="color: #382c7a"
            >Demandas</a
          >
        </li>
      </ul>
      <ul class="nav" v-else>
        <!-- DEFAULT HEADER -->
        <!-- <li><a href>Temporada</a></li> -->
        <!-- <li><a href>Real Estate</a></li> -->
        <li>
          <a
            href="https://www.apolar.com.br/franquias/"
            target="_blank"
            style="color: #382c7a"
            >Nossas Lojas</a
          >
        </li>
        <li>
          <a href="#/favorites" style="color: #382c7a">Favorito</a>
        </li>
        <li class="divider" />
        <li>
          <a href="#/comparative" style="color: #382c7a">
            <div class="counter hidden">
              <span>1</span>
            </div>
            Comparativos
          </a>
        </li>
      </ul>
      <div class="header-user whitespace-no-wrap">
        <div
          class="header-user-logged-in cursor-pointer dropdown dropdown-toggle"
          v-if="$store.state.user"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="user-img" v-if="$store.getters.getUserInitials">
            <span style="color: white">{{
              $store.getters.getUserInitials
            }}</span>
          </div>
          <a v-if="$store.getters.getUserFirstName" style="color: #382c7a"
            >Olá, {{ $store.getters.getUserFirstName }}</a
          >
          <i
            class="icon icon-arrow-down darker-gray"
            style="width: 40px !important; height: 40px !important"
          ></i>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <a
              class="dropdown-item"
              @click="$router.push({ name: `profile` }).catch((err) => {})"
              >Meu Perfil</a
            >
            <a
              class="dropdown-item"
              v-if="$store.state.user.role === `Corretor`"
              @click="
                $router.push({ name: `dashboard-matches` }).catch((err) => {})
              "
              >Dashboard</a
            >
            <a class="dropdown-item" @click="logout">Sair</a>
          </div>
        </div>
        <a class="font-bold" href="#/login" v-else>Entrar</a>
      </div>
      <div class="mobile-menu">
        <svg
          fill="none"
          height="40"
          viewBox="0 0 40 40"
          width="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            stroke="#CACADA"
            stroke-linecap="round"
            stroke-width="2"
            x1="13"
            x2="26"
            y1="16"
            y2="16"
          />
          <line
            stroke="#CACADA"
            stroke-linecap="round"
            stroke-width="2"
            x1="13"
            x2="18"
            y1="21"
            y2="21"
          />
          <line
            stroke="#CACADA"
            stroke-linecap="round"
            stroke-width="2"
            x1="13"
            x2="22"
            y1="26"
            y2="26"
          />
        </svg>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      dashboardRoutes: [`dashboard-demands`, `dashboard-matches`],
    };
  },
  methods: {
    checkAdminStoreCode(storecode) {

      storecode = typeof storecode != 'string' ? storecode.toString() : storecode
      
      if ( storecode.split(',').indexOf('1') != -1 ) {
        return true
      }

      return false

    },
    logout() {
      this.$store.commit(`logoutUser`);
      if (this.$route.name !== `home`) {
        this.$router.push({ name: `demands-page` });
      }
    },
  },
};
</script>
