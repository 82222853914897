import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home'
import NewHome from '../views/NewHome'
import Profile from '../views/profile/Profile'
import EditProfile from '../views/profile/EditProfile'
import RegisterProperty from '../views/RegisterProperty'
import Demand from "../views/demands/Demand";
import Demands from "../views/demands/Demands";
import DashboardMatches from "../views/dashboard/Matches";
import DashboardDemands from "../views/dashboard/Demands";
import PageNotFound from "../views/status/PageNotFound";
import Forbidden from "../views/status/Forbidden";
import Sold from "../views/status/Sold";
import Comparation from "../views/Comparation";
import Property from "../views/Property";
import Login from "../views/login/Login";
import Register from "../views/login/Register";
import Filters from "../views/Filters";
import Order from "../views/Order";
import Search from "../views/Search";
import Favorites from "../views/Favorites";
import Exchange from "../views/Exchange";
import Confirmation from "../views/login/Confirmation";
import ForgotPassword from "../views/login/ForgotPassword";
import ResetPassword from "../views/login/ResetPassword";
import InputOptions from "../components/filters/InputOptions";

Vue.use(VueRouter);

const routes = [
    /*{
        path: '/',
        name: 'demands-page',
        component: Demands
    }*/
    {
        path: '/',
        name: 'new-home',
        component: NewHome
    },
    {
        path: '/filters',
        name: 'search-filters',
        component: Filters,
        props: true
    },
    {
        path: '/input-options',
        name: 'input-options',
        component: InputOptions
    },
    {
        path: '/order',
        name: 'search-order',
        component: Order
    },
    {
        path: '/search',
        name: 'result-page',
        component: Search
    },
    {
        path: '/demands',
        name: 'demands-page',
        component: Demands
    },
    {
        path: '/demand/:reference',
        name: 'demand',
        component: Demand
    },
    {
        path: '/comparative',
        name: 'comparation-page',
        component: Comparation
    },
    {
        path: '/register-property',
        name: 'register-property',
        component: RegisterProperty,
        props: true
    },
    {
        path: '/property/:reference',
        name: 'property',
        component: Property
    },
    {
        path: '/login',
        name: 'login',
        type: 'loginpage',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile
    },
    {
        path: '/dashboard/matches',
        name: 'dashboard-matches',
        component: DashboardMatches
    },
    {
        path: '/dashboard/demands',
        name: 'dashboard-demands',
        component: DashboardDemands
    },
    {
        path: '/favorites',
        name: 'favorites',
        component: Favorites
    },
    {
        path: '/exchange',
        name: 'exchange',
        component: Exchange
    },
    {
        path: '/editprofile',
        name: 'editprofile',
        component: EditProfile
    },
    {
        path: '/confirmation', // TEST ONLY
        name: 'confirmation',
        component: Confirmation
    },
    {
        path: '/forgotpassword', // TEST ONLY
        name: 'forgotpassword',
        component: ForgotPassword
    },
    {
        path: '/resetpassword', // TEST ONLY
        name: 'resetpassword',
        component: ResetPassword
    },
    {
        path: '/forbidden', // TEST ONLY
        name: 'forbidden',
        component: Forbidden
    },
     {
        path: '/sold', // TEST ONLY
        name: 'sold',
        component: Sold
    },
    {
        path: '*',
        name: 'not-found',
        component: PageNotFound
    }
];

const router = new VueRouter({
    //mode: 'history',
    routes
});

export default router
