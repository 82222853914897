<template>
    <div class="modal" tabindex="-1">
        <div class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
             role="document"
             style="pointer-events: auto;">
            <div class="modal-content">
                <div class="modal-body">
                    <div v-if="demands" class="inner-modal demands">
                        <div class="modal-dashboard-header">
                            <div class="close-modal" ref="closeModalButton" data-toggle="modal" :data-target="`#modal-dashboard-${getId}`">
                                <div class="icon icon-close dark-gray"></div>
                            </div>
                            <h1>{{demand.property_type | toSentence('ou')}} <br/> {{demand.minMaxArea()}}</h1>
                            <div class="details-footer">
                                <div class="dashboard-card-footer mt-0">
                                    <p class="dashboard-status">{{demandStatusMessage[demand.status]}}</p>
                                    <div class="icon icon-clock light-gray"></div>
                                    <p class="dashboard-paragraph">Gerado <strong>{{elapsedTimeString}}</strong></p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-dashboard-body">
                            <div class="first-row">
                                <p>Cliente</p>
                                <h2>{{demand.client.name}}</h2>
                                <div class="call-to-action">
                                    <i class="icon icon-phone highlight"></i>
                                    <div class="description">
                                        <h3>Ligar agora</h3>
                                        <p>{{demand.client.phone_number}}</p>
                                    </div>
                                </div>
                                <div class="call-to-action">
                                    <i class="icon icon-mail highlight"></i>
                                    <div class="description">
                                        <h3>Enviar E-mail</h3>
                                        <p>{{demand.client.email}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="second-row">
                                <div class="demand-card-values">
                                    <div class="value mr-8">
                                        <span class="text-gray">Valor Mínimo</span>
                                        <div class="currency-display">
                                            <p class="currency-type">R$</p>
                                            <p class="currency-value">{{Math.round(demand.min_value) | currency}}</p>
                                            <p class="currency-cents">,00</p>
                                        </div>
                                    </div>
                                    <div class="value">
                                        <span class="text-gray">Valor Máximo</span>
                                        <div class="currency-display">
                                            <p class="currency-type">R$</p>
                                            <p class="currency-value">{{Math.round(demand.max_value) | currency}}</p>
                                            <p class="currency-cents">,00</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="demand-card-features">
                                    <div class="feature w-1/2">
                                        <i class="icon icon-bed"></i>
                                        <p class="feature-value">{{demand.minMaxRooms()}}</p>
                                        <p class="feature-description">Dormitórios</p>
                                    </div>
                                    <div class="feature">
                                        <i class="icon icon-suite"></i>
                                        <p class="feature-value">{{demand.minMaxSuites()}}</p>
                                        <p class="feature-description">Suítes</p>
                                    </div>
                                </div>
                                <div class="demand-card-features">
                                    <div class="feature w-1/2">
                                        <i class="icon icon-toilet"></i>
                                        <p class="feature-value">{{demand.minMaxBathrooms()}}</p>
                                        <p class="feature-description">Banheiros</p>
                                    </div>
                                    <div class="feature">
                                        <i class="icon icon-car"></i>
                                        <p class="feature-value">{{demand.minMaxGarages()}}</p>
                                        <p class="feature-description">Vagas</p>
                                    </div>
                                </div>
                            </div>
                            <div class="third-row">
                                <div class="demand-card-local">
                                    <div class="local">
                                        <div class="local-icon">
                                            <p v-if="demand.address !== null && demand.address.radius !== 0">{{demand.address.radius * 1000 | metersToKm}}</p>
                                            <i class="icon icon-local-yellow"></i>
                                        </div>
                                        <div class="address">
                                            <p v-if="demand.address !== null">{{demand.fullStreet()}}</p>
                                            <span v-if="demand.address !== null">{{demand.fullCityDetails()}}</span>
                                            <p v-if="demand.neighborhood !== null">{{demand.neighborhoodNames() | toSentence("ou")}}</p>
                                        </div>

                                    </div>
                                    <GoogleMapContainer v-if="demand.address !== null" :center-position="{lat: demand.address.latitude, lng: demand.address.longitude}" :radius="demand.address.radius" />
                                </div>
                                <div class="demand-condominium">
                                    <div class="demand-features on-min justify-content-around">
                                        <div class="feature">
                                            <i class="icon icon-pet" :class="{'light-gray': !demand.features.pet_friendly}"></i>
                                            <p class="feature-name">Pet Friendliy</p>
                                        </div>
                                        <div class="feature">
                                            <i class="icon icon-mobiliado" :class="{'icon-mobiliado-gray': demand.features.furnished !== 'yes' && demand.features.furnished !== 'semi'}"></i>
                                            <p class="feature-name" v-if="demand.features.furnished === 'yes' || demand.features.furnished === 'no'">Mobiliado</p>
                                            <p class="feature-name" v-if="demand.features.furnished === 'semi'">Semimobiliado</p>
                                        </div>
                                    </div>

                                    <div class="attributes">
                                        <div class="attr" v-if="demand.custom_features['property']">
                                            <div class="title">
                                                <i class="icon icon-home"></i>
                                                <h1>Atributos desejados do imóvel</h1>
                                            </div>
                                            <div class="list">
                                                <p class="items" :key="feat" v-for="feat in demand.custom_features['property']"><span class="icon icon-dot"></span>{{feat}}</p>
                                            </div>
                                        </div>
                                        <div class="attr" v-if="demand.custom_features['condominium']">
                                            <div class="title">
                                                <i class="icon icon-condominio"></i>
                                                <h1>Atributos desejados do condomínio</h1>
                                            </div>
                                            <div class="list">
                                                <p class="items" :key="feat" v-for="feat in demand.custom_features['condominium']"><span class="icon icon-dot"></span>{{feat}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="fourth-row">
                                <div v-if="demand.offered_exchange.length > 0" class="details-body">
                                    <div class="first-column">
                                        <p class="name">{{demand.firstName()}} oferece permuta de</p>
                                        <template v-for="exchange in demand.offered_exchange">
                                            <div :key="exchange.id" v-if="exchange.exchange_type === 'vehicle'">
                                                <h1>{{exchange.vehicle_make}} {{exchange.vehicle_model}}</h1>
                                                <div class="demand-card-values">
                                                    <div class="value mr-8">
                                                        <span>Valor Aproximado</span>
                                                        <div class="currency-display">
                                                            <p class="currency-type">R$</p>
                                                            <p class="currency-value">{{exchange.value | currency}}</p>
                                                            <p class="currency-cents">,00</p>
                                                        </div>
                                                    </div>
                                                    <div class="value">
                                                        <span>Ano</span>
                                                        <div class="currency-display">
                                                            <p class="currency-value">{{exchange.year}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div :key="exchange.id" v-if="exchange.exchange_type === 'property'">
                                                <h1>{{exchange.property_type}} em {{exchange.neighborhood}}</h1>
                                                <div class="demand-card-values">
                                                    <div class="value mr-8">
                                                        <span>Valor Aproximado</span>
                                                        <div class="currency-display">
                                                            <p class="currency-type">R$</p>
                                                            <p class="currency-value">{{exchange.value | currency}}</p>
                                                            <p class="currency-cents">,00</p>
                                                        </div>
                                                    </div>
                                                    <div class="value">
                                                        <span>Área</span>
                                                        <div class="currency-display">
                                                            <p class="currency-value">{{exchange.area}}m²</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <button class="secondary-button" @click="changeStatusDemand(demand.id, 'answered')">Marcar como Atendido</button>
                                <button class="secondary-button" @click="changeStatusDemand(demand.id, 'finished')">Marcar como Finalizado</button>
                                <button class="secondary-button" @click="changeStatusDemand(demand.id, 'published')">Publicar Demanda</button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="inner-modal">
                        <div class="modal-dashboard-header" :style="{background: `url(${match.property.getFeaturedImages()[0].path})`}">
                            <div class="shadow"></div>
                            <div class="close-modal" ref="closeModalButton" data-toggle="modal" :data-target="`#modal-dashboard-${getId}`">
                                <div class="icon icon-close white"></div>
                            </div>
                        </div>
                        <div class="modal-dashboard-body">
                            <div class="border-b border-lighter-gray px-4">
                                <div class="details-footer bg-transparent px-0">
                                    <div class="dashboard-card-footer mt-0">
                                        <p class="dashboard-status">{{getStatusMessage}}</p>
                                        <div class="icon icon-clock light-gray"></div>
                                        <p class="dashboard-paragraph text-dark-gray">Gerado <strong>Hoje</strong></p>
                                    </div>
                                </div>
                                <h1>{{getPropertyType | toSentence('ou')}} . {{getMinMaxArea}} . R${{match.property.sell_value | currency}}</h1>
                                <h2>{{match.property.fullAddress()}}</h2>
                                <p class="dashboard-paragraph" v-if="!demands">Ref.: {{match.property.reference}}</p>
                                <div class="py-4">
                                    <Relevance :dashboard="true" :relevance="String(Math.round(match.relevance))"/>
                                </div>
                            </div>
                            <div class="border-b border-lighter-gray pt-8 px-4 pb-6">
                                <p v-if="demands" class="dashboard-paragraph mb-1">Cliente</p>
                                <p v-else class="dashboard-paragraph mb-1">Cliente Comprador</p>
                                <h1>{{getClientDetails.name}}</h1>
                                <p class="dashboard-paragraph text-dark-gray mb-4">{{getClientDetails.phone_number}} . {{getClientDetails.email}}</p>
                                <p class="dashboard-paragraph mb-1">Cliente Vendedor</p>
                                <h1 v-if="match.property.client">{{match.property.client.name}}</h1>
                                <h1 v-else>Não especificado</h1>
                                <p v-if="match.property.client" class="dashboard-paragraph text-dark-gray">{{match.property.client.phone_number}} . {{match.property.client.email}}</p>
                            </div>
                            <div class="details-body">
                                <div class="first-column">
                                    <p class="name" v-if="match.offered_exchange.length > 0">{{match.firstName()}} oferece permuta de</p>
                                    <template v-for="exchange in match.offered_exchange">
                                        <div class="mb-2" :key="exchange.id" v-if="exchange.exchange_type === 'vehicle'">
                                            <h1>{{exchange.vehicle_make}} {{exchange.vehicle_model}}</h1>
                                            <div class="demand-card-values">
                                                <div class="value mr-8">
                                                    <span>Valor Aproximado</span>
                                                    <div class="currency-display">
                                                        <p class="currency-type">R$</p>
                                                        <p class="currency-value">{{exchange.value | currency}}</p>
                                                        <p class="currency-cents">,00</p>
                                                    </div>
                                                </div>
                                                <div class="value">
                                                    <span>Ano</span>
                                                    <div class="currency-display">
                                                        <p class="currency-value">{{exchange.year}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-2" :key="exchange.id" v-if="exchange.exchange_type === 'property'">
                                            <h1>{{exchange.property_type}} em {{exchange.neighborhood}}</h1>
                                            <div class="demand-card-values">
                                                <div class="value mr-8">
                                                    <span>Valor Aproximado</span>
                                                    <div class="currency-display">
                                                        <p class="currency-type">R$</p>
                                                        <p class="currency-value">{{exchange.value | currency}}</p>
                                                        <p class="currency-cents">,00</p>
                                                    </div>
                                                </div>
                                                <div class="value">
                                                    <span>Área</span>
                                                    <div class="currency-display">
                                                        <p class="currency-value">{{exchange.area}}m²</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div class="second-column">
                                    <p class="name" v-if="match.property.exchange.length > 0">{{match.property.firstName()}} estuda permuta de</p>
                                    <template v-for="exchange in match.property.exchange">
                                        <div class="mb-2" :key="exchange.id" v-if="exchange.exchange_type === 'vehicle'">
                                            <h1>{{exchange.vehicle_type}}</h1>
                                            <div class="demand-card-values pb-2">
                                                <div class="value mr-8">
                                                    <span>Valor Mínimo</span>
                                                    <div class="currency-display">
                                                        <p class="currency-type">R$</p>
                                                        <p class="currency-value">{{exchange.min_value | currency}}</p>
                                                        <p class="currency-cents">,00</p>
                                                    </div>
                                                </div>
                                                <div class="value mr-8">
                                                    <span>Valor Máximo</span>
                                                    <div class="currency-display">
                                                        <p class="currency-type">R$</p>
                                                        <p class="currency-value">{{exchange.max_value | currency}}</p>
                                                        <p class="currency-cents">,00</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="demand-card-values">
                                                <div class="value">
                                                    <span>Ano Mínimo</span>
                                                    <div class="currency-display">
                                                        <p class="currency-value">{{exchange.min_year}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-2" :key="exchange.id" v-if="exchange.exchange_type === 'property'">
                                            <h1>{{exchange.property_type | toSentence('ou')}} em {{exchange.neighborhood.map(neighborhood => neighborhood.label) | toSentence('ou')}}</h1>
                                            <div class="demand-card-values pb-2">
                                                <div class="value mr-8">
                                                    <span>Valor Mínimo</span>
                                                    <div class="currency-display">
                                                        <p class="currency-type">R$</p>
                                                        <p class="currency-value">{{exchange.min_value | currency}}</p>
                                                        <p class="currency-cents">,00</p>
                                                    </div>
                                                </div>
                                                <div class="value">
                                                    <span>Valor Máximo</span>
                                                    <div class="currency-display">
                                                        <p class="currency-type">R$</p>
                                                        <p class="currency-value">{{exchange.min_value | currency}}</p>
                                                        <p class="currency-cents">,00</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="demand-card-values">
                                                <div class="value mr-24">
                                                    <span>Área Mínima</span>
                                                    <div class="currency-display">
                                                        <p class="currency-value">{{exchange.min_area}}m²</p>
                                                    </div>
                                                </div>

                                                <div class="value">
                                                    <span>Área Máxima</span>
                                                    <div class="currency-display">
                                                        <p class="currency-value">{{exchange.max_area}}m²</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <button class="secondary-button" @click="shareWhatsapp(match)">Compartilhar Whatsapp</button>
                            <button class="secondary-button" @click="changeStatusMatch(match.id, 'answered')">Marcar como Atendido</button>
                            <button class="secondary-button" @click="changeStatusMatch(match.id, 'finished')">Marcar como Finalizado</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Relevance from "./Relevance";
    import GoogleMapContainer from "./GoogleMapContainer";
    import DemandService from "../js/api/DemandService";
    import MatchService from "../js/api/MatchService";

    export default {
        name: 'DashboardModal',
        components: { Relevance, GoogleMapContainer },
        props: {
            demands: {
                type: Boolean,
                required: false,
                default: false
            },
            demand: {
                type: Object,
                required: false
            },
            match: {
                type: Object,
                required: false
            }
        },
        created() {
        },

        data() {
            return {
                demandStatusColor: {pending: "red", answered: "yellow", published: "blue", finished: "green"},
                demandStatusMessage: {pending: "Não Atendido", answered: "Atendido", published: "Publicado", finished: "Finalizado"}
            }
        },
        computed: {
            elapsedTimeString(){
                const date1 = this.demands ? new Date(this.demand.inserted_at) : new Date(this.match.inserted_at);
                const date2 = new Date(Date.now());
                const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
                const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
                const diffDays = Math.abs((utc2 - utc1) / (1000 * 60 * 60 * 24));
                let demandDateString = "Hoje";
                if(diffDays === 1) {
                    demandDateString = "Ontem";
                } else if (diffDays > 1) {
                    demandDateString = `Há ${diffDays} dias`;
                }
                return demandDateString;
            },
            getStatusColor(){
                if(this.demands){
                    return this.demandStatusColor[this.demand.status];
                } else {
                    return this.demandStatusColor[this.match.status];
                }
            },

            getStatusMessage(){
                if(this.demands){
                    return this.demandStatusMessage[this.demand.status];
                } else {
                    return this.demandStatusMessage[this.match.status];
                }
            },

            getClientDetails(){
                if(this.demands){
                    return this.demand.client;
                } else {
                    return this.match.client;
                }
            },

            getId(){
                if(this.demands){
                    return this.demand.id;
                } else {
                    return this.match.id;
                }
            },

            getPropertyType(){
                if (this.demands){
                    return this.demand.property_type;
                } else {
                    return this.match.property.property_type;
                }
            },

            getAddress(){
                if(this.demands){
                    return this.demand.address;
                } else {
                    return this.match.property.address;
                }
            },

            getFullAddress(){
                if(this.demands){
                    return this.demand.fullAddress();
                } else {
                    return this.match.property.fullAddress();
                }
            },

            getMinMaxArea(){
                if(this.demands){
                    return this.demand.minMaxArea();
                } else {
                    return Math.floor(this.match.property.total_area) + "m²";
                }
            }
        },
        methods: {
            async changeStatusDemand(demandId, status){
                try {
                    await DemandService.changeStatus(demandId, status);
                    this.$emit('updateInfo');
                    this.$refs.closeModalButton.click();
                } catch (err) {
                    // console.log(err);
                }
            },

            async changeStatusMatch(matchId, status){
                try {
                    await MatchService.changeStatus(matchId, status);
                    this.$emit('updateInfo');
                    this.$refs.closeModalButton.click();
                } catch (err) {
                    // console.log(err);
                }
            },
            shareWhatsapp(match){
                let message = `%2ACliente Comprador:%2A %0A
                Email: ${match.client.email} %0A
                Nome: ${match.client.name} %0A
                Telefone: ${match.client.phone_number} %0A
                %0A
                %2ACliente Vendedor:%2A %0A
                Email: ${match.property.client.email} %0A
                Nome: ${match.property.client.name} %0A
                Telefone: ${match.property.client.phone_number} %0A
                %0A
                %2AImóvel:%2A %0A
                Tipo do Imóvel: ${match.property.property_type} %0A
                Endereço: ${match.property.fullAddress()} %0A
                Valor: R$ ${match.property.sell_value} %0A
                Área: ${match.property.total_area} %0A
                Ref: ${match.property.reference} %0A
                `
                let linkWhatsapp = `https://api.whatsapp.com/send?text=${message}`
                window.open(linkWhatsapp, '_blank');
            }
        }
    }
</script>
