import Api from '../api/Api.js'

const apiPath = 'properties';
export default {
    get() {
        return Api.get(apiPath);
    },
    getByReference(reference){
        return Api.get(apiPath+`/${reference}`);
    },

    postPendingProperty(payload){
        return Api.post('pending_properties', {pending_property: payload});
    }
};
