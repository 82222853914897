<template>
  <div id="map-single"></div>
</template>
<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "OpenStreetContainer",
  props: ["centerPosition", "radius"],
  data() {
    return {
      tileLayer: "https://mapa.apolar.com.br/tiles/{z}/{x}/{y}.png",
      map: null,
    };
  },
  mounted() {
    this.createMap().then(() => {
      this.triggersMap();
    });
  },
  methods: {
    createMap() {
      return new Promise((resolve) => {
        this.map = L.map("map-single").setView(this.centerPosition, 16);
        L.tileLayer(this.tileLayer, {
          attribution: " Apolar 2021 ",
          edgeBufferTiles: 2,
        }).addTo(this.map); //will be our basemap.
        this.map.options.minZoom = 8;
        this.map.options.maxZoom = 16;
        console.log(this.map);
        setTimeout(() => {
          resolve();
        }, 2000);
      });
    },
    triggersMap() {
    

      L.circle([this.centerPosition.lat, this.centerPosition.lng], 500, {
        color: "#ffcb00",
        fillColor: "#ffcb00",
        fillOpacity: 0.2,
      }).addTo(this.map);

      L.circle([this.centerPosition.lat, this.centerPosition.lng], 10, {
        color: "#ffcb00",
        fillColor: "#ffcb00",
        fillOpacity: 5,
      }).addTo(this.map);

      L.circle([this.centerPosition.lat, this.centerPosition.lng], 5, {
        color: "#fff",
        fillColor: "#fff",
        fillOpacity: 5,
      }).addTo(this.map);
    },
  },
};
</script>