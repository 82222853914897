import TypeService from "../api/TypeService";

export default {
    data() {
        return {
            vehicleTypes: []
        }
    },
    created() {
        this.fetchVehicleTypes()
    },

    methods: {
        async fetchVehicleTypes() {
            try {
                const {data} = await TypeService.getVehicletypes();
                this.vehicleTypes = data['data'];
            } catch (err) {
                // console.log(err);
            }
        },
    }
};
