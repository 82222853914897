<template> 
    <StatusPage 
        img="property-unavailable.svg"
        title="O imóvel que você busca já foi negociado"
        description="Lorem ipsum busque por imóveis lorem ipsum ou entre em contato lorem ipsum."
    />
</template>

<script>
    import StatusPage from '../../components/StatusPage.vue'

    export default {
        name: 'Sold',
        components: {
            StatusPage,
        }    
    }
</script>
