<template>
    <div class="page">
        <div class="main-container container">
            <img :src="require('@/images/' + img)" class="not-found-banner">
            <div class="text-center">
                <h1> {{ title }} </h1>
                <p> {{ description }} </p>
                <RouterLink :to="{name: 'home'}" class="primary-button">Voltar para a Home</RouterLink>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatusPage',
        props: ['img', 'title', 'description']
    }
</script>
