<template>
    <div class="page">
        <div class="page-header">
            <div class="container">
                <div class="main-icons">
                    <router-link to="/" class="return-button" href="/#">
                        <i class="icon icon-arrow-link-left dark-gray"></i>
                    </router-link>
                </div>
                <h1>Matches</h1>
                <div class="dashboard-label-wrapper">
                    <!-- <div class="dashboard-label">
                        <label class="checkbox-container">
                            <input value="answered" v-model="statusFilters" type="checkbox">
                            <span class="checkmark yellow"></span>
                            <strong class="md:hidden">{{getMatchesByStatus['answered'].length}}</strong>
                            <p class="hidden md:inline"><strong>{{getMatchesByStatus['answered'].length}}</strong> Atendidos </p>
                        </label>
                        <p class="md:hidden">Atendidos</p>
                    </div> -->
                    <!-- <div class="dashboard-label">
                        <label class="checkbox-container">
                            <input value="pending" v-model="statusFilters" type="checkbox">
                            <span class="checkmark red"></span>
                            <strong class="md:hidden">{{getMatchesByStatus['pending'].length}}</strong>
                            <p class="hidden md:inline"><strong>{{getMatchesByStatus['pending'].length}}</strong> Não Atendidos</p>
                        </label>
                        <p class="md:hidden">Não Atendidos</p>
                    </div> -->
                    <div class="dashboard-label">
                        <label class="checkbox-container">
                            <input value="finished" v-model="statusFilters" type="checkbox">
                            <span class="checkmark green"></span>
                            <strong class="md:hidden">{{getMatchesByStatus['finished'].length}}</strong>
                            <p class="hidden md:inline"><strong>{{getMatchesByStatus['finished'].length}}</strong> Finalizados</p>
                        </label>
                        <p class="md:hidden">Finalizados</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">

                <div class="card-label-wrapper row m-0">
                    <div class="col-3">Cliente Comprador</div>
                    <div class="col-3">Cliente Vendedor</div>
                    <div class="col-4">Imóvel Anunciado</div>
                    <div class="col-2 flex items-center" data-toggle="tooltip" data-placement="top" title="Relevância do imóvel com a busca feita pelo comprador">
                        Relevância
                        <div class="icon icon-info"></div>
                    </div>
                </div>
                <div v-if="loading" style="margin-left:50%" class="spinner-border text-success-darker" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <DashboardCard v-else @updateInfo="updateMatches" v-for="match in getPaginatedMatches" :key="match.id" :match="match" />

                <Pagination :offset.sync="offset" :instances-per-page="instancesPerPage" :total-count="getFilteredMatches.length"></Pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardCard from "../../components/property/DashboardCard";
    import Pagination from "../../components/filters/Pagination";
    import fetchMatches from "../../js/mixins/fetchMatches";
    
    export default {
        name: 'Dashboard',
        mixins: [fetchMatches],
        components: {
            DashboardCard,
            Pagination
        },

        created() {
            if(!this.$store.state.user){
                this.$router.push({name: "login"});
            } else if(this.$store.state.user.role !== "Corretor") { // TODO editar para !== depois de teste
                this.$router.push({name: "home"});
            }
        },

        data() {
            return {
                matchList: {pending:[], answered: [], finished: []},
                matchListColor: {pending: "red", answered: "yellow", finished: "green"},
                statusFilters: ["pending", "answered", "finished"],
                instancesPerPage: 10,
                offset: 0
            }
        },

        computed: {
            getMatchesByStatus() {
                let matchList = {pending:[], answered: [], finished: []};
                this.matches.forEach(match => {
                    if(!matchList[match.status]){
                        matchList[match.status] = [];
                    }
                    matchList[match.status].push(match);
                });
                return matchList;
            },

            getFilteredMatches(){
                let filteredMatches = this.matches.filter(match => this.statusFilters.includes(match.status));

                return filteredMatches.sort((a,b) => {return b.id - a.id});
            },


            getPaginatedMatches() {
                return this.getFilteredMatches.slice(this.offset, this.offset + this.instancesPerPage)
            }
        },

        methods: {
            updateMatches(){
                this.fetchMatches();
            }
        }
    }
</script>
