import UserClass from "../classes/user";
import UserService from "../api/UserService";

export default {
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async updateUser(token) {
            this.loading = true;
            try {
                const {data} = await UserService.getData(token);
                this.user = new UserClass(data['data']);
                this.$store.commit(`setUser`, this.user);
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        }
    }
};
