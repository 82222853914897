<template>
    <div class="page">
        <div class="modal-header">
            <RouterLink class="apolar-logo" :to="{ name: 'demands-page' }">
                <img src="../../images/logo.svg" width="89">
            </RouterLink>
            <a href="#/login">
                <div class="close-button">
                    <i class="icon icon-close dark-gray"></i>
                </div>
            </a>
        </div>
        <div class="content-wrapper row">
             <div class="content col-md">
                <img src="@/images/message-sent.svg">
            </div>
            <div class="form col-md">
                <h1>Enviado!</h1>
                <p class="form-description">O link para a criação de uma nova senha já foi enviado para seu e-mail.</p>
                <button :class="{valid}" @click="$router.push('/login')">Voltar à página de login</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Confirmation",
        data() {
            return {
                valid: true
            }
        },
    };
</script>
