<template>
    <div class="multi-select">
        <h5> {{ title }} </h5>
        <div class="items-wrapper-container relative">
            <div class="items-wrapper" ref="itemsScroll" v-on:scroll="scrollEvent" v-on:touchstart="startDrag" v-on:touchend="endDrag" v-on:touchmove="moveDrag">
                <div :class="{active: isIndiferent}" class="multi-select-item check-item-indiferent first"
                     v-if="register == `false`">Indiferente
                </div>
                <div class="check-item multi-select-item" v-if="hasZero == `true`" :class="{active: withinRange(0)}" data-item="0" >0</div>
                <div :class="{active: withinRange(1)}" data-item="1"   class="check-item multi-select-item">1</div>
                <div :class="{active: withinRange(2)}" data-item="2"   class="check-item multi-select-item">2</div>
                <div :class="{active: withinRange(3)}" data-item="3"   class="check-item multi-select-item">3</div>
                <div :class="{active: withinRange(4)}" data-item="4"   class="check-item multi-select-item">4</div>
                <div :class="{active: withinRange(5)}" data-item="5"   class="check-item multi-select-item">5</div>
                <div :class="{active: withinRange(6)}" data-item="6"   class="check-item multi-select-item">6</div>
                <div :class="{active: withinRange(7)}" data-item="7"   class="check-item multi-select-item">7</div>
                <div :class="{active: withinRange(8)}" data-item="8"   class="check-item multi-select-item">8</div>
                <div :class="{active: withinRange(9)}" data-item="9"   class="check-item multi-select-item">9</div>
                <div :class="{active: withinRange(10)}" data-item="10" class="check-item multi-select-item">10+
                </div>
            </div>
            <div @click="scrollTo(`right`)" class="multi-select-arrow multi-select-arrow-right"
                 v-if="register == `false`"
                 v-show="scrollNeeded === `right`">
                <i class="icon icon-arrow-right highlight"></i>
            </div>
            <div @click="scrollTo(`left`)" class="multi-select-arrow multi-select-arrow-left"
                 v-if="register == `false`"
                 v-show="scrollNeeded === `left`">
                <i class="icon icon-arrow-left highlight"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from "jquery";
    export default {
        name: 'MultiSelect',
        props: ['title', 'register', 'hasZero', 'label', 'initialValue', 'singleSelect'],
        data() {
            return {
                minValue: this.initialValue["min"] || 0,
                maxValue: this.initialValue["max"] || 0,
                isIndiferent: true,
                scrollNeeded: "",
                dragging: false,
                hasStartedDrag: false,
                startX: 0,
                dragSpeed: -0.3
            }
        },
        computed: {
            selectedValues() {
                return {min: this.minValue, max: this.maxValue};
            }
        },
        created() {
            if(this.minValue !== 0 && this.maxValue !== 0){
                this.isIndiferent = false;
            }
        },
        watch: {
            initialValue: function() {
                this.minValue = this.initialValue["min"]
                this.maxValue = this.initialValue["max"]
                
                if(this.minValue !== 0 && this.maxValue !== 0){
                    this.isIndiferent = false;
                }
            }
        },
        mounted() {

            jQuery(this.$refs.itemsScroll).children('.check-item').on('click touchend', (el)=>{
                jQuery(".forceBlurFields").blur()
                let elmObj = jQuery(el.target)
                let itemData = parseInt(elmObj.attr('data-item'))
                this.selectValue(itemData)
            })

            jQuery(this.$refs.itemsScroll).children('.check-item-indiferent').on('click touchend', ()=>{
                jQuery(".forceBlurFields").blur()
                this.selectIndiferent()
            })

            this.scrollEvent();
        },
        methods: {
            updateValues() {
                this.$emit('updateMultiSelectValues', {[this.label]: this.selectedValues});
            },

            selectIndiferent() {
                this.minValue = 0;
                this.maxValue = 0;
                this.isIndiferent = true;
                this.updateValues();
            },

            selectValueTouch(val){
                jQuery(".forceBlurFields").blur()
                this.$refs.itemsScroll.focus()
                if(!this.hasStartedDrag){
                    this.selectValue(val)
                }
            },

            selectValue(val) {
                if(this.singleSelect){
                    this.minValue = val;
                    this.maxValue = val;
                    this.updateValues();
                    return;
                }


                this.isIndiferent = false;
                const currentMin = this.minValue;
                const currentMax = this.maxValue;

                if (val === 10) {
                    this.minValue = val;
                    this.maxValue = val;
                    this.updateValues();
                    return;
                }

                if (currentMin === 0 && currentMax === 0) { // initial value
                    this.minValue = val;
                    this.maxValue = val;
                    this.updateValues();
                    return;
                }

                if (currentMin !== currentMax) {
                    this.minValue = val;
                    this.maxValue = val;
                    this.updateValues();
                    return;
                }

                if (currentMin === 10 || currentMax === 10) {
                    this.minValue = val;
                    this.maxValue = val;
                    this.updateValues();
                    return;
                }

                if (val > currentMax) {
                    this.maxValue = val;
                    this.updateValues();
                    return;
                }

                if (val < currentMin) {
                    this.minValue = val;
                    this.updateValues();
                    return;
                }
            },

            withinRange(val) {
                return val <= this.maxValue && val >= this.minValue;
            },

            scrollTo(dir) {
                switch (dir) {
                    case "right":
                        this.$refs.itemsScroll.scrollLeft = 9999;
                        break;
                    case "left":
                        this.$refs.itemsScroll.scrollLeft = 0;
                        break;
                }
            },

            scrollEvent() {
                if (this.$refs.itemsScroll.scrollWidth > this.$refs.itemsScroll.clientWidth) { // needs scroll!
                    if (this.$refs.itemsScroll.scrollLeft === 0) { // find direction of scroll
                        this.scrollNeeded = "right";
                    } else {
                        this.scrollNeeded = "left";
                    }
                }
            },

            startDrag(e){
                e.preventDefault();
                this.dragging = true;
                this.startX = e.touches[0].pageX - this.$refs.itemsScroll.offsetLeft;
            },

            moveDrag(e){
                e.preventDefault();
                this.hasStartedDrag = true;
                if(this.dragging) {
                    const currX = e.touches[0].pageX - this.$refs.itemsScroll.offsetLeft;
                    this.$refs.itemsScroll.scrollLeft = this.$refs.itemsScroll.scrollLeft + (currX - this.startX) * this.dragSpeed;
                }
            },

            endDrag(e){
                e.preventDefault();
                this.dragging = false;
                this.hasStartedDrag = false;
            }
        }
    }
</script>
