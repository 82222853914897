<template>
  <div class="property-comparation" v-if="isLoaded">
    <div class="property-comparation-item">
      <p class="property-comparation-item-label">Imóvel</p>
      <div class="property-comparation-item-image">
        <div class="text-center mr-5 md:mr-0">
          <div class="img">
            <img :src="property.getFeaturedImages()[0].path" />
            <div @click="removeProperty" class="remove-button">
              <i class="icon icon-close white"></i>
              Remover da Comparação
            </div>
          </div>
          <button @click="removeProperty" class="md:hidden">Remover</button>
        </div>
        <div>
          <h3>{{property.property_type}}</h3>
          <p>{{property.fullAddress()}}</p>
        </div>
      </div>
    </div>
    <div class="property-comparation-item">
      <p
        v-if="property.deal_type === 'Vendas'"
        class="property-comparation-item-label"
      >Valor de Venda</p>
      <p v-else class="property-comparation-item-label">Valor do Aluguel</p>
      <div>
        <div class="currency-display">
          <p class="currency-type">R$</p>
          <p class="currency-value">{{property.sell_value | currencyWithComma}}</p>
        </div>
      </div>
    </div>
    <div class="property-comparation-item">
      <p class="property-comparation-item-label">Condomínio*</p>
      <div>
        <div class="currency-display" v-if="property.condominium">
          <p class="currency-type">R$</p>
          <p class="currency-value">{{property.condominium | currencyWithComma}}</p>
        </div>
        <div class="currency-display" v-else>
          <p class="currency-type"></p>
          <p class="currency-value">--</p>
          <div class="currency-cents"></div>
        </div>
      </div>
    </div>
    <div class="property-comparation-item">
      <p class="property-comparation-item-label">Área Útil</p>
      <div class="area">
        <p class="value">{{property.useful_area | currency}}m²</p>
      </div>
    </div>
    <div class="property-comparation-item">
      <p class="property-comparation-item-label">Área Total</p>
      <div class="area">
        <p class="value">{{property.total_area | currency}}m²</p>
      </div>
    </div>
    <div class="property-comparation-item">
      <p class="property-comparation-item-label">Dormitórios</p>
      <div class="feature">
        <p>{{ property.bedrooms }}</p>
        <i class="icon icon-bed" :class="{'lighter-gray': property.bedrooms == 0}"></i>
      </div>
    </div>
    <div class="property-comparation-item">
      <p class="property-comparation-item-label">Banheiros</p>
      <div class="feature">
        <p>{{ property.bathrooms }}</p>
        <i class="icon icon-toilet" :class="{'lighter-gray': property.bathrooms == 0}"></i>
      </div>
    </div>
    <div class="property-comparation-item">
      <p class="property-comparation-item-label">Suítes</p>
      <div class="feature no-feature">
        <p>{{ property.suites }}</p>
        <i class="icon icon-suite" :class="{'lighter-gray': property.suites == 0}"></i>
      </div>
    </div>
    <div class="property-comparation-item">
      <p class="property-comparation-item-label">Vagas</p>
      <div class="feature">
        <p>{{ property.garages }}</p>
        <i class="icon icon-car" :class="{'lighter-gray': property.garages == 0}"></i>
      </div>
    </div>
    <div class="property-comparation-item property-comparation-item-limit">
      <p class="property-comparation-item-label">Atributos do Imóvel</p>
      <div>
        <p
          class="description"
          v-if="property.custom_features['property']"
        >{{property.custom_features["property"] | toSentence | limitString(110)}}</p>
        <p class="description" v-else>--</p>
      </div>
    </div>
    <div class="property-comparation-item property-comparation-item-limit">
      <p class="property-comparation-item-label">Atributos do Condomínio</p>
      <div>
        <p
          class="description"
          v-if="property.custom_features['condominium']"
        >{{property.custom_features["condominium"] | toSentence | limitString(110)}}</p>
        <p class="description" v-else>--</p>
      </div>
    </div>
    <div class="property-comparation-item comment-item" v-if="comment">
      <div class="comment">
        <div class="comment-info">
          <p>{{$store.state.user.name}}</p>
          <span class="hidden md:block">{{new Date(comment.date).toLocaleDateString('en-GB')}},</span>
          <span
            class="hidden md:block"
          >{{new Date(comment.date).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })}}</span>
          <a
            class="comment-button md:hidden"
            data-toggle="modal"
            :data-target="`#commentModal_${property.id}`"
          >
            <i class="icon icon-edit highlight"></i>
          </a>
        </div>
        <div class="comment-data">
          <div>"{{comment.comment}}"</div>
        </div>
        <a
          class="comment-button hidden md:flex"
          data-toggle="modal"
          :data-target="`#commentModal_${property.id}`"
        >
          <i class="icon icon-edit highlight"></i>
          Editar Comentário
        </a>
      </div>
    </div>
    <div class="property-comparation-item comment-item" v-else-if="isFavorite">
      <div class="comment">
        <img src="../../images/favorite-comment.svg" class="mx-auto" />
        <h2 class="text-dark-gray font-bold text-center mb-3">Adicione um comentário</h2>
        <div class="flex justify-content-center">
          <a
            class="comment-button"
            data-toggle="modal"
            :data-target="`#commentModal_${property.id}`"
          >
            <i class="icon icon-edit highlight"></i>
            Adicionar Comentário
          </a>
        </div>
      </div>
    </div>
    <div class="property-comparation-item comment-item" v-else>
      <div class="comment">
        <img src="../../images/favorite-comment.svg" class="mx-auto" />
        <h2>Favorite este imóvel para comentá-lo</h2>
        <div class="flex justify-content-center">
          <a
            class="comment-button"
            data-toggle="modal"
            :data-target="`#favoriteModal_${property.id}`"
          >
            <i class="icon icon-favorite highlight"></i>
            Favoritar Imóvel
          </a>
        </div>
      </div>
    </div>
    <div class="property-comparation-item action">
      <div>
        <a class="secondary-button" @click.stop="goToProperty">Ver anúncio</a>
        <a class="primary-button" @click.stop="goToProperty">Ver anúncio</a>
      </div>
    </div>
    <SearchModal
      @updateFavorite="updateFavorite"
      :id="`favoriteModal_${property.id}`"
      class="favoriteModal"
      :relevance="0"
      :property_id="property.id"
      type="favorite"
    />
    <CommentModal
      @updateComment="updateComment"
      :id="`commentModal_${property.id}`"
      :original_comment="comment"
      class="commentModal"
      :property_id="property.id"
    />
  </div>
</template>
<script>
import PropertyClass from "../../js/classes/property";
import PropertyService from "../../js/api/PropertyService";
import SearchModal from "../SearchModal";
import CommentModal from "../CommentModal";
import fetchUser from "../../js/mixins/fetchUser";
import CompareService from "../../js/api/CompareService";

export default {
  name: "PropertyComparation",
  mixins: [fetchUser],
  components: {
    SearchModal,
    CommentModal
  },
  props: {
    property_reference: null,
    property_obj: null
  },
  data() {
    return {
      property: PropertyClass,
      isLoaded: false,
      isFavorite: false,
      comment: null
    };
  },
  created() {
    this.getByReference(encodeURIComponent(this.property_reference));
  },
  methods: {
    async getByReference(property_reference) {
      try {
        const { data } = await PropertyService.getByReference(
          property_reference
        );
        this.property = new PropertyClass(data["data"]);
        this.isLoaded = true;
        if (
          this.$store.state.user &&
          this.$store.state.user.favorite.find(
            fav => fav.property_id === this.property.id
          )
        ) {
          this.isFavorite = true;
        }
        if (
          this.$store.state.user &&
          this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          )
        ) {
          this.comment = this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          );
        }
      } catch (err) {
        // console.log(err);
      }
    },

    async removeProperty() {
      const comparison = this.$store.state.user.comparison.find(
        comparison => comparison.property_id === this.property.id
      );
      try {
        await CompareService.delete(comparison.id, this.$store.state.userToken);
        await this.updateUser(this.$store.state.userToken);
        this.$emit("deleted");
      } catch (err) {
        // console.log(err.response);
      }
    },

    goToProperty() {
      this.$router.push({
        name: "property",
        params: { reference: this.property_reference }
      });
    },

    updateFavorite(status) {
      this.isFavorite = status;
      this.updateUser(this.$store.state.userToken).then(() => {
        if (
          this.$store.state.user &&
          this.$store.state.user.favorite.find(
            fav => fav.property_id === this.property.id
          )
        ) {
          this.isFavorite = true;
        }
        if (
          this.$store.state.user &&
          this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          )
        ) {
          this.comment = this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          );
        }
      });
    },

    updateComment() {
      this.updateUser(this.$store.state.userToken).then(() => {
        if (
          this.$store.state.user &&
          this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          )
        ) {
          this.comment = this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          );
        }
      });
    }
  }
};
</script>
