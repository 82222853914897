<template>
  <div class="modal" tabindex="-1">
    <div
      class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
      role="document"
      style="pointer-events: auto;"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="inner-modal">
            <div
              class="close-modal z-10"
              ref="closeModalFavorite"
              data-toggle="modal"
              :data-target="`#questionModal`"
            >×</div>
            <div class="modal-body-content">
              <div class="row mb-4 ml-1 w-full">
                <h1
                  class="text-darker-gray text-3xl font-bold"
                >Fale com a gente e tire suas dúvidas!</h1>
                <p
                  class="text-base my-3 text-dark-gray"
                >Você pode conversar direto com um corretor Apolar pelo WhatsApp, ou responder nosso formulário que vamos entrar em contato assim que possível.</p>
                <a
                  class="primary-button w-full flex mt-4 z-10"
                  v-bind:href="this.to_whatsapp"
                  target="_blank"
                >Enviar Whatsapp</a>
              </div>
              <div class="row m-0 w-full mt-24">
                <div class="col-md-12 p-0 mt-n5">
                  <div class="input-set mt-n3">
                    <input class="form-input" v-model="$v.fullName.$model" required />
                    <label
                      class="form-label pointer-events-none"
                      :class="{'hide-small': !fullName}"
                    >Nome*</label>
                    <span class="form-error" v-show="sent && !$v.fullName.required">
                      Esse campo é
                      obrigatório
                    </span>
                  </div>
                  <div class="input-set mt-n3">
                    <input class="form-input" v-model="$v.email.$model" required autocapitalize="none" />
                    <label
                      class="form-label pointer-events-none"
                      :class="{'hide-small': !email}"
                    >E-mail*</label>
                    <span class="form-error" v-show="sent && !$v.email.required">
                      Esse campo é
                      obrigatório
                    </span>
                  </div>
                  <div class="input-set mt-n3">
                    <input
                      v-mask="['(##) #### ####', '(##) ##### ####']"
                      class="form-input"
                      v-model="$v.phone.$model"
                      required
                    />
                    <label
                      class="form-label pointer-events-none"
                      :class="{'hide-small': !phone}"
                    >Telefone para Contato*</label>
                    <span class="form-error" v-show="sent && !$v.phone.required">
                      Esse campo é
                      obrigatório
                    </span>
                  </div>
                  <div class="input-set mt-n3">
                    <input class="form-input" v-model="$v.question.$model" required />
                    <label
                      class="form-label pointer-events-none"
                      :class="{'hide-small': !question}"
                    >Mensagem*</label>
                    <span class="form-error" v-show="sent && !$v.question.required">
                      Esse campo é
                      obrigatório
                    </span>
                  </div>
                  <button class="secondary-button" @click="sendForm">
                    <div v-if="loading" class="spinner-border text-success-darker" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span v-else>Enviar Mensagem</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import QuestionService from "../js/api/QuestionService";

export default {
  name: "ScheduleModal",
  directives: { mask },
  props: {
    property_id: { type: Number },
    to_whatsapp: {type: String},
    to_email: {type: String}
  },

  data() {
    return {
      fullName: null,
      phone: null,
      email: null,
      question: null,
      sent: false,
      loading: false
    };
  },

  validations: {
    fullName: {
      required
    },
    question: {
      required
    },
    phone: {
      required
    },
    email: {
      required,
      email
    }
  },

  created() {
    if (this.to_whatsapp){
      this.to_whatsapp = "https://api.whatsapp.com/send?phone=55" + this.to_whatsapp.match(/\d/g).join("");
    }
  },
  methods: {
    async sendForm() {
      this.loading = true;
      this.sent = true;

      if (!this.$v.$invalid) {
        try {
          let payload = {
            message: this.question,
            client: {
              name: this.fullName,
              phone_number: this.phone,
              email: this.email
            },
            property_id: this.property_id,
            to_email: this.to_email
          };
          await QuestionService.post(payload);
          this.$toasted.show("Enviado com sucesso!");
          this.$refs.closeModalFavorite.click();
          this.loading = false;
        } catch (err) {
          // console.log(err)
        }
      } else{
        this.loading = false;
      }
    }
  }
};
</script>
