<template>
  <div class="relevance">
    <div class="relevance-details">
      <div class="relevance-badge" :class="relevanceColor">
        {{relevance}}
        <span>%</span>
      </div>
      <p v-if="dashboard">Relevância para a busca do Comprador</p>
      <p v-else>De relevância conforme a sua busca</p>
    </div>
    <div class="relevance-meter">
      <span class="relevance-meter-progress" :style="`width: ${relevance}%`"></span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Relevance',
    props: {
     relevance: { type: String, required: true },
     dashboard: { type: Boolean, required: false, default: false },
    },
    computed: {
      relevanceColor() {
        let relevanceColor = 'bg-success'

        if ( this.relevance >= 33 && this.relevance < 66 ) {
          relevanceColor = 'bg-orange'
        }

        if ( this.relevance < 33 ) {
          relevanceColor = 'bg-warning'
        }
        
        return relevanceColor
        
      }
    }
  }
</script>
