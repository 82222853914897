import Vue from 'vue'

Vue.directive('toggleSelected', {
    bind(el) {
        Array.from(el.children).forEach((child) => child.addEventListener('click', function () {
            const clickedEl = child;
            const parentEl = clickedEl.parentElement;
            if (clickedEl !== el && parentEl === el) { // apenas cliques nos children
                Array.from(parentEl.children).forEach((el) => el.classList.remove('active'));
                clickedEl.classList.add('active');
            }
        }));
    }
});
