<template>
    <div class="demand-card">
        <div class="demand-card-main"><h1>{{demand.firstName()}} busca {{demand.property_type | toSentence('ou') |
            toLowercase}}, {{demand.minMaxArea()}}</h1>
            <div class="demand-card-local">
                <div class="local">
                    <template v-if="demand.address != null">
                        <div class="local-icon"><p>{{demand.address.radius}}km</p><i class="icon icon-local"></i></div>
                        <p class="complete-address">{{demand.address.radius}}km de {{demand.fullAddress()}}</p>
                        <div class="address"><p>{{demand.fullStreet()}}</p><span>{{demand.fullCityDetails()}}</span>
                        </div>
                    </template>
                    <template v-else-if="demand.neighborhood.length > 0">
                        <div class="local-icon"><i class="icon icon-local"></i></div>
                        <p class="complete-address">{{cityLabel}} - {{demand.neighborhoodNames() | toSentence('ou')}}</p>
                        <div class="address"><p> {{cityLabel}} - {{demand.neighborhoodNames() | toSentence('ou')}}</p>
                        </div>
                    </template>
                </div>
            </div>
            <div class="demand-card-info">
                <div class="demand-card-values">
                    <div class="value"><span>Valor Mínimo</span>
                        <div class="currency-display"><p class="currency-type">R$</p>
                            <p class="currency-value">{{Math.round(demand.min_value) | currency}}</p>
                            <p class="currency-cents">,00</p>
                        </div>
                    </div>
                    <div class="value"><span>Valor Máximo</span>
                        <div class="currency-display"><p class="currency-type">R$</p>
                            <p class="currency-value">{{Math.round(demand.max_value) | currency}}</p>
                            <p class="currency-cents">,00</p>
                        </div>
                    </div>
                </div>
                <div class="demand-features row">
                    <div class="feature col-6 col-xl-3"><i class="icon icon-bed"></i>
                        <div>
                            <p class="feature-value">{{demand.minMaxRooms()}}</p>
                            <p v-if="demand.minMaxRooms() === '1'" class="feature-name">Dormitório</p>
                            <p v-else class="feature-name">Dormitórios</p>
                        </div>
                    </div>
                    <div class="feature col-6 col-xl-3"><i class="icon icon-toilet"></i>
                        <div>
                            <p class="feature-value">{{demand.minMaxBathrooms()}}</p>
                            <p v-if="demand.minMaxBathrooms() === '1'" class="feature-name">Banheiro</p>
                            <p v-else class="feature-name">Banheiros</p>
                        </div>
                    </div>
                    <div class="feature col-6 col-xl-3"><i class="icon icon-suite"></i>
                        <div>
                            <p class="feature-value">{{demand.minMaxSuites()}}</p>
                            <p v-if="demand.minMaxSuites() === '1'" class="feature-name">Suíte</p>
                            <p v-else class="feature-name">Suítes</p>
                        </div>
                    </div>
                    <div class="feature col-6 col-xl-3"><i class="icon icon-car"></i>
                        <div>
                            <p class="feature-value">{{demand.minMaxGarages()}}</p>
                            <p v-if="demand.minMaxGarages() === '1'" class="feature-name">Vaga</p>
                            <p v-else class="feature-name">Vagas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="demand-card-footer">
            <RouterLink :to="{ name:`demand`, params: {reference: demand.id}}">Tenho o imóvel</RouterLink>
        </div>
    </div>
</template>

<script>
    import DemandClass from "../../js/classes/demand";

    export default {
        name: 'DemandCard',
        props: {
            'demand': DemandClass,
            'cities':{
                type: Array,
                default() {
                    return {}
                }
            }
        },
        computed: {
            cityLabel: function() {
                let city = this.cities.filter(city => city['id'] == this.demand['neighborhood'][0]['city_id'])
                return city.length > 0 ? city[0]['label']: ''
            }
        }
    }

</script>
