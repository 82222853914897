class PropertyClass {
    constructor(data) {
        this.id = data.id;
        this.reference = data.reference;
        this.feature = data.feature;
        this.property_type = data.property_type;
        this.deal_type = data.deal_type;
        this.total_area = data.total_area;
        this.useful_area = data.useful_area;
        this.sell_value = data.sell_value;
        this.condominium = data.condominium;
        this.iptu = data.iptu;
        this.fire_insurance = data.fire_insurance;
        this.badge = data.badge;
        this.exchange = data.exchange;
        this.address = data.address;
        this.picture = data.picture;
        this.custom_features = data.custom_features;
        this.bedrooms = data.feature.max_rooms;
        this.bathrooms = data.feature.max_bathrooms;
        this.suites = data.feature.max_suites;
        this.garages = data.feature.min_garages;
        this.client = data.client;
        this.description = data.description;
        this.requirement = data.requirement;
        this.storeemail = data.storeemail;
        this.storetelephone = data.storetelephone;
        this.storecellphone = data.storecellphone;
        this.storecode = data.storecode;
    }

    splitCondominium = () => {
        var arr = String(this.condominium).split('.');

        if (!arr[1]) {
            arr[1] = "00";
        }

        return arr;
    };

    getPropertyAge() {
        if (this.feature.property_age === 0) {
            return `< 1 ano`
        }
        if (this.feature.property_age === 1) {
            return `1 ano`
        }
        return `${this.feature.property_age} anos`
    }

    firstExchange = () => {
        var exchange = this.exchange[0];
        var result;

        if (exchange.exchange_type == 'vehicle') {
            result = exchange.vehicle_type + ' até R$ ' + (Math.round(exchange.max_value)).toLocaleString('pt-BR') + ',00';
        } else if (exchange.exchange_type == 'property') {
            result = 'Propriedade até R$ ' + (Math.round(exchange.max_value)).toLocaleString('pt-BR') + ',00';
        }

        return result;
    };

    otherExchanges = () => {
        var result = '+ ' + String(this.exchange.length - 1);
        return result;
    };

    bedroomsText = () => {
        var arr = ' Dormitório';

        if (this.bedrooms != 1) {
            arr = ' Dormitórios';
        }

        return arr;
    };

    suitesText = () => {
        var arr = ' Suíte';

        if (this.suites != 1) {
            arr = ' Suítes';
        }

        return arr;
    };

    bathroomsText = () => {
        var arr = ' Banheiro';

        if (this.bathrooms != 1) {
            arr = ' Banheiros';
        }

        return arr;
    };

    garagesText = () => {
        var arr = ' Vaga';

        if (this.garages != 1) {
            arr = ' Vagas';
        }

        return arr;
    };


    fullStreet = () => {
        return `${this.address.address}, ${this.address.number}`
    };

    fullCityDetails = () => {
        return `${this.address.neighborhood}, ${this.address.city}`
    };

    fullAddress = () => {
        return `${this.fullStreet()} - ${this.fullCityDetails()}`
    };

    mapPos = () => {
        return {lat: this.address.latitude, lng: this.address.longitude};
    };

    mapRadius = () => {
        return this.address.radius;
    };

    getFeaturedImages() {
        return this.picture.filter((img) => img.featured).sort((a, b) => a.order - b.order)
    }

    getBannerImages() {
        return this.picture.filter((img) => img.banner).sort((a, b) => a.order - b.order)
    }

    getGridImages() {
        return this.picture.filter((img) => img.grid).sort((a, b) => a.order - b.order)
    }
    getImages() {
        return this.picture.sort((a, b) => a.order - b.order)
    }

    firstName = () => {
        var arr = this.client.name.split(" ");

        return arr[0];
    };

}

export default PropertyClass
