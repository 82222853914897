<template>
  <div id="map" style="height: 100%"></div>
</template>

<script>
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import markeIcon from "../images/marker-icon.png";
import markeIconShadow from "../images/marker-shadow.png";
import markeIconRetina from "../images/marker-icon-2x.png";

export default {
  name: "OpenStreetContainerMultiple",
  props: ["properties", "mainAddress"],
  components: {},
  data() {
    return {
      tileLayer: "https://mapa.apolar.com.br/tiles/{z}/{x}/{y}.png",
      map: null,
      imoveis: null,
    };
  },
  mounted() {
    this.createMap().then(() => {
      this.triggersMap();
    });
  },
  created() {
    this.setCenterPosition();
  },
  methods: {
    createMap() {
      return new Promise((resolve) => {
        this.map = L.map("map").setView(this.centerPosition, 12);
        L.tileLayer(this.tileLayer, {
          attribution: " Apolar 2021 ",
          edgeBufferTiles: 2,
        }).addTo(this.map); //will be our basemap.
        this.map.options.minZoom = 8;
        this.map.options.maxZoom = 16;
        console.log(this.map);
        setTimeout(() => {
          resolve();
        }, 2000);
      });
    },
    triggersMap() {
      let _properties = {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
        },
        features: this.properties.map((property) => ({
          type: "Feature",
          properties: {
            property: property,
          },
          geometry: {
            type: "Point",
            coordinates: [
              property.address.longitude,
              property.address.latitude,
            ],
          },
        })),
      };

      var customMarke = L.icon({
        iconUrl: markeIcon,
        shadowUrl: markeIconShadow,
        iconRetinaUrl: markeIconRetina,
      });

      var markers = L.markerClusterGroup();
      var points_rand = L.geoJson(_properties, {
        onEachFeature: (feature, layer) => {
          layer.options.icon = customMarke;
        },
      });
      markers.addLayer(points_rand); // add it to the cluster group
      this.map.addLayer(markers); // add it to the map

      markers.on("click", (a) => {
        var coordinates = a.layer.feature.geometry.coordinates.slice();
        let property = a.layer.feature.properties.property;
        let imgProperty = property.picture
          .filter((img) => img.featured)
          .sort((a, b) => a.order - b.order)[0].path;
        let mainTextProperty = `${property.address.neighborhood} . ${parseInt(
          property.useful_area
        )}m²`;
        let valueProperty = property.sell_value;
        let referenceProperty = property.reference;

        while (Math.abs(a.latlng.lng - coordinates[0]) > 180) {
          coordinates[0] += a.latlng.lng > coordinates[0] ? 360 : -360;
        }

        let popup = L.popup({
          //offset: [-90, 90]
        }).setLatLng(coordinates)
          .setContent(`<div class="info-window-property flex">
                                    <img src="${imgProperty}" alt class="main-image" />
                                    <div class="info-container">
                                    <div class="flex items-center justify-between">
                                        <div>
                                        <h1 class="main-text">${mainTextProperty}</h1>
                                        <div class="flex mt-1 ml-3">
                                            <p class="info-sign">R$</p>
                                            <p class="info-value">${valueProperty.toLocaleString(
                                              "pt-BR"
                                            )}</p>
                                            <p class="info-cents">,00</p>
                                        </div>
                                        </div>
                                        <i
                                        class="icon icon-arrow-right highlight cursor-pointer"
                                        onclick="window.open('https://procura.apolar.com.br/#/property/${referenceProperty}', '_blank');"
                                        style="right: 10px;"
                                        ></i>
                                    </div>
                                    <div class="relevance-meter">
                                        <span class="relevance-meter-progress" style="width:80%"></span>
                                    </div>
                                    </div>
                                </div>`);

        a.layer.bindPopup(popup, { maxWidth: "430px" }).openPopup();
      });
    },
    setCenterPosition() {
      if (this.mainAddress && this.mainAddress.latitude) {
        this.centerPosition = {
          lat: this.mainAddress.latitude,
          lng: this.mainAddress.longitude,
        };
      } else {
        this.centerPosition = {
          lat: this.properties[0].address.latitude,
          lng: this.properties[0].address.longitude,
        };
      }
    },
  },
};
</script>
<style>
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  top: -5px;
}

.gm-style-pbc {
  z-index: 5 !important;
  pointer-events: none;
}
.info-window-property {
  width: 90% !important;
  padding: 5px;
}
.leaflet-popup {
  width: 220px;
}
.leaflet-popup-content {
  position: relative;
  background: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  margin: 0px;
}

.info-window-property .main-image {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

.info-window-property .info-container {
  @apply w-full;
}

.info-window-property .main-text {
  font-size: 14px;
  color: #333343;
  font-weight: bold;
  line-height: 17px;
  margin-left: 11px;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-window-property .info-sign {
  font-size: 10px;
  color: #666676;
  margin-right: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.info-window-property .info-value {
  font-size: 18px;
  color: #666676;
  margin-right: 2px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
}

.info-window-property .info-cents {
  font-size: 10px;
  color: #666676;
  margin-top: 0px;
  margin-bottom: 0px;
}

.info-window-property .relevance-meter {
  background: #f0f0f1;
  margin-top: 4px;
  margin-left: 5px;
}

.info-window-property .relevance-meter .relevance-meter-progress {
  display: block;
  background: linear-gradient(to right, #ffcb00, #38ca51) no-repeat;
  height: 3px;
}
</style>
