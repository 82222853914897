import AddressService from "../api/AddressService";
//import CityClass from "../classes/city";

export default {
    data() {
        return {
            addresses: []
        }
    },
    created() {
        this.fetchAddresses()
    },

    methods: {
        async fetchAddresses() {
            try {
                const {data} = await AddressService.get();
                this.addresses = data['data'];
                //this.address = this.cities.map((v) => new CityClass(v));
            } catch (err) {
                // console.log(err);
            }
        },
    }
};
