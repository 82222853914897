import Vue from "vue";
import App from "./App.vue";
import Toasted from "vue-toasted";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster"; // replace src with dist if you have Babel issues
Vue.component("GmapCluster", GmapCluster);
import Vuelidate from "vuelidate";
import router from "./router";
import "./js/filters";
import "./js/directives";
import jQuery from "jquery";
window.jQuery = window.$ = jQuery;
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./css/tailwind.css";
import VueInitialsImg from "vue-initials-img";
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueInitialsImg);
Vue.use(VueObserveVisibility);
Vue.use(Toasted, { position: "bottom-center", duration: 5000 });

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
//import VueMapbox from "@studiometa/vue-mapbox-gl";
//import "mapbox-gl/dist/mapbox-gl.css";

import store from "./store/store";

//Vue.use(VueMapbox);


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDKjNYat_n826b2hFBnytJkR1WWiZ4kCi0",
    libraries: "places"
  }
});

Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
