<template>
  <div class="modal" tabindex="-1">
    <div
      class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
      role="document"
      style="pointer-events: auto;"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="inner-modal">
            <div
              class="close-modal"
              ref="closeModalMenu"
              data-toggle="modal"
              :data-target="`#menuModal`"
            >
              <i class="icon icon-arrow-down dark-gray"></i>
            </div>
            <div class="modal-body-content">
              <a @click="closeModal('#/')">Página Inicial</a>
              <a @click="closeModal('#/profile')">Meu Perfil</a>
              <a @click="closeModal('#/filters')">Cadastre sua procura</a>
              <a @click="closeModal('#/register-property')">Tenho um imóvel</a>
              <a @click="closeModal('#/favorites')">Lista de Favoritos</a>
              <hr />
              <a @click="closeModal('#/sair')">Sair</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jQuery from "jquery";
export default {
  name: "MenuModal",
  computed: {},
  methods: {
    closeModal(href) {
      if (href === "#/sair") {
        this.$store.commit(`logoutUser`);
        if (this.$route.name !== `home`) {
          jQuery(".close-modal").click();
          jQuery(".modal-backdrop").remove();
          window.open('#/', "_self");
        }
      } else {
        jQuery(".close-modal").click();
        jQuery(".modal-backdrop").remove();
        window.open(href, "_self");
      }
    }
  }
};
</script>
