import Api from '../api/Api.js'

const apiPath = 'types';
export default {

    getPropertyTypes() {
        return Api.get(apiPath + "/property_types");
    },

    getCustomFeatures(){
        return Api.get(apiPath + "/custom_features");
    },

    getDealTypes(){
        return Api.get(apiPath + "/deal_types");
    },

    getVehicletypes(){
        return Api.get(apiPath + "/vehicle_types");
    }
};
