<template>
  <div class="page">
    <div class="container">
      <div class="content">
        <img src="@/images/register.png" />
        <h1>Criar Conta</h1>
        <p>
          Favorite os imóveis que mais gostar, compare seus perfis e encontre o
          imóvel que melhor atende a suas necessidades.
        </p>
      </div>
      <div class="form">
        <form>
          <div class="input-set">
            <input
              class="form-input"
              id="username"
              v-model="$v.fullName.$model"
              required
            />
            <label
              class="form-label"
              for="username"
              :class="{ 'hide-small': !fullName }"
              >Nome completo</label
            >
            <p class="form-error" v-show="sent && !$v.fullName.required">
              Esse campo é obrigatório
            </p>
          </div>
          <div class="input-set">
            <input
              class="form-input"
              v-model="$v.phone.$model"
              type="tel"
              v-mask="['(##) #### ####', '(##) ##### ####']"
              required
            />
            <label
              class="form-label pointer-events-none"
              for="phone"
              :class="{ 'hide-small': !phone }"
              >Telefone</label
            >
            <p class="form-error" v-show="sent && $v.phone.$invalid">
              Esse campo é obrigatório
            </p>
          </div>
          <div class="input-set">
            <input
              class="form-input"
              id="email"
              v-model="$v.email.$model"
              required
              autocapitalize="none"
            />
            <label
              class="form-label"
              for="email"
              :class="{ 'hide-small': !email }"
              >E-mail</label
            >
            <p class="form-error" v-show="sent && this.emailTaken">
              Já existe uma conta com esse e-mail
            </p>
            <p class="form-error" v-show="sent && !$v.email.required">
              Esse campo é obrigatório
            </p>
            <p class="form-error" v-show="sent && !$v.email.email">
              Insira um e-mail válido
            </p>
          </div>
          <div class="input-set">
            <input
              class="form-input"
              :type="showPasswordType"
              id="password"
              v-model="$v.password.$model"
              required
            />
            <label
              class="form-label"
              for="password"
              :class="{ 'hide-small': !password }"
              >Senha</label
            >
            <span class="reveal-password" v-on:click="togglePassword">{{
              showPasswordText
            }}</span>
            <p class="form-hint" v-show="$v.password.$dirty && !sent">
              Use entre 6 e 20 caracteres
            </p>
            <p class="form-error" v-show="sent && !$v.password.required">
              Esse campo é obrigatório
            </p>
            <p
              class="form-error"
              v-show="
                sent && (!$v.password.minLength || !$v.password.maxLength)
              "
            >
              Use entre 6 e 20 caracteres
            </p>
          </div>
          <p class="terms-reminder">
            Ao cadastrar-me, aceito as
            <a>Políticas de privacidade</a>e os <a>Termos e condições</a> da
            Apolar.
          </p>
          <button
            :class="{ valid: !$v.$invalid }"
            v-on:click.prevent="sendForm"
          >
            <span v-if="!loading">Criar conta</span>
            <div
              v-if="loading"
              class="spinner-border text-success-darker"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
          <div class="dont-have-a-account">
            <span>Já possui conta na Apolar?</span>
            <a href="#/login">Faça seu login</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LoginService from "../../js/api/LoginService";
import togglePasswordMixin from "../../js/mixins/togglePasswordMixin";
import {
  email,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
export default {
  name: "Register",
  mixins: [togglePasswordMixin],
  directives: { mask },
  data() {
    return {
      email: null,
      password: null,
      fullName: null,
      sent: false,
      emailTaken: false,
      loading: false,
      phone: null,
    };
  },
  validations: {
    fullName: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    phone: {
      required,
    },
  },
  methods: {
    async register(user) {
      try {
        const { data } = await LoginService.register(user);
        localStorage.token = data.token;
        this.$toasted.show("Conta criada com sucesso! Faça seu login.");
        await this.$router.push({ name: `login` });
      } catch (err) {
        this.loading = false;
        if (
          err.response.data.errors["email"] &&
          err.response.data.errors["email"][0] === "has already been taken"
        ) {
          this.emailTaken = true;
        }
      }
    },
    sendForm() {
      this.loading = true;
      this.sent = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }

      var user = {
        name: this.fullName,
        email: this.email,
        password: this.password,
        phone: this.phone
      };

      this.register(user);
    },
  },
};
</script>
