import Api from '../api/Api.js'

const apiPath = 'compare_property';
export default {
    post(payload, token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        return Api.post(apiPath, {comparison: payload}, config);
    },

    delete(comparison_id, token){
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        return Api.delete(apiPath + "/" + comparison_id, config);
    }
};
