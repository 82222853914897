<template>
  <div class="page">
    <div class="main">
      <div class="main-icons">
        <a class="icon" href="#/" style="background-color: #C2E3EA;">
          <i class="icon icon-arrow-link-left white"></i>
        </a>
      </div>
      <div class="container">
        <div class="main-line">
          <h1>Apolar Procura</h1>
          <p>
            Nós da Apolar estamos procurando por imóveis para nossos clientes.
            Tem algum deles? Entre em contato!
          </p>

          <RouterLink :to="{ name: 'new-home' }" class="main-desktop-button">
            Voltar à home
          </RouterLink>

        </div>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div style="display:none" class="switch">
          <div
            :class="{ active: searchType === 'Vendas' }"
            @click="searchType = 'Vendas'"
          >
            Para Comprar
            <i></i>
          </div>
          <div
            :class="{ active: searchType === 'Locação' }"
            @click="searchType = 'Locação'"
          >
            Para Alugar
            <i></i>
          </div>
        </div>
        <section class="row justify-center">
          <div class="feed-container col-md-7">
            <DemandCard
              v-for="(demand, index) in getPaginatedDemands"
              :key="index"
              v-bind:demand="demand"
            />
            <div style="display:none;" class="control-sm-property">
              <div
                class="dream-property-container"
              >
                <div
                  class="flex flex-col items-center md:items-start pb-6 md:pb-0 text-center md:text-left md:w-2/3"
                >
                  <img src="../../images/dream-property.svg" />
                  <h1>Ainda não encontrou o imóvel perfeito?</h1>
                  <h1>Nós buscamos para você!</h1>
                </div>

                <hr />
                <div
                  class="mt-5 flex flex-col md:flex-row items-center justify-between px-4 md:px-0"
                >
                  <button
                    class="secondary-button md:hidden mb-4"
                    v-on:click.prevent="registerSearch"
                  >
                    Cadastre sua procura
                  </button>
                  <button
                    class="secondary-button hidden md:flex"
                    v-on:click.prevent="registerSearch"
                  >
                    Cadastre sua procura
                  </button>
                </div>
              </div>
            </div>
            <DemandCardRegisterProperty v-bind:demand="demand" />
            <Pagination
              :offset.sync="offset"
              :instances-per-page="instancesPerPage"
              :total-count="getFilteredDemands.length"
            ></Pagination>
            <div style="clear:both;"></div>
          </div>
          <div style="display:none;" class="control col-md-5">
            <div class="dream-property-container">
              <div
                class="flex flex-col items-center md:items-start pb-6 md:pb-0 text-center md:text-left md:w-2/3"
              >
                <img src="../../images/dream-property.svg" />
                <h1>Ainda não encontrou o imóvel perfeito?</h1>
                <h1>Nós buscamos para você!</h1>
              </div>

              <hr />
              <div
                class="mt-5 flex flex-col md:flex-row items-center justify-between px-4 md:px-0"
              >
                <button
                  class="secondary-button md:hidden mb-4"
                  v-on:click.prevent="registerSearch"
                >
                  Cadastre sua procura
                </button>
                <button
                  class="secondary-button hidden md:flex"
                  v-on:click.prevent="registerSearch"
                >
                  Cadastre sua procura
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div style="clear:both;"></div>
  </div>
  
</template>

<script>
import DemandCard from "../../components/demands/DemandCard";
import DemandCardRegisterProperty from "../../components/demands/DemandCardRegisterProperty";
import FetchDemands from "../../js/mixins/fetchDemands";
import FetchDealTypes from "../../js/mixins/fetchDealTypes";
import FetchPropertyTypes from "../../js/mixins/fetchPropertyTypes";
import Pagination from "../../components/filters/Pagination";
import { VMoney } from "v-money";

export default {
  name: "demands",
  mixins: [FetchDealTypes, FetchDemands, FetchPropertyTypes],
  components: {
    DemandCard,
    Pagination,
    DemandCardRegisterProperty,
  },
  directives: { money: VMoney },
  watch: {
    minArea() {
      this.checkArea();
    },
    maxArea() {
      this.checkArea();
    },
  },
  data() {
    return {
      inputBoxValues: {},
      demands: [],
      minArea: null,
      maxArea: null,
      instancesPerPage: 5,
      offset: 0,
      searchType: null,
      clickedType: false,
      area: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "m²",
        precision: 0,
        masked: true /* doesn't work with directive */,
      },
    };
  },
  computed: {
    getFilteredDemands() {
      let filteredDemands = this.demands.filter(
        (demand) => demand.status === "published"
      );
      if (
        this.inputBoxValues["dealType"] &&
        this.inputBoxValues["dealType"].length > 0
      ) {
        filteredDemands = filteredDemands.filter((demand) =>
          this.inputBoxValues["dealType"].includes(demand.deal_type)
        );
      } else if (this.searchType) {
        filteredDemands = filteredDemands.filter(
          (demand) => this.searchType === demand.deal_type
        );
      }

      if (
        this.inputBoxValues["propertyType"] &&
        this.inputBoxValues["propertyType"].length > 0
      ) {
        filteredDemands = filteredDemands.filter((demand) =>
          this.inputBoxValues["propertyType"].some((val) =>
            demand.property_type.includes(val)
          )
        );
      }

      if (filteredDemands.length > 0) {
        document.querySelector('.demands-page').style.height = 'initial';
      }

      return filteredDemands.sort((a, b) => {
        return b.id - a.id;
      });
    },

    getPaginatedDemands() {
      return this.getFilteredDemands.slice(
        this.offset,
        this.offset + this.instancesPerPage
      );
    },
  },

  methods: {
    async registerSearch() {
      await this.$router.push({ name: "search-filters" });
    },
    updateCheckedValues(value) {
      const label = Object.keys(value)[0];
      this.inputBoxValues[label] = value[label];
    },
    checkArea() {
      if (this.minArea && !this.maxArea) {
        this.maxArea = this.minArea;
      }
      if (this.minArea && this.maxArea) {
        const min = this.removeAreaFormatting(this.minArea);
        const max = this.removeAreaFormatting(this.maxArea);
        if (min > max) {
          this.maxArea = this.minArea;
        }
      }
    },
    removeAreaFormatting(value) {
      let newValue = value.replace("m²", "").replace(".", "");
      return parseInt(newValue);
    },

    triggerFilter() {
      this.demands = this.demands.slice();
    },

    setSearchType(type) {
      this.searchType = type;
      this.triggerFilter();
    },
  },
};
</script>
