<template>
    <div class="page">
        <div class="show-map">
            <div class="result-bar" style="background-color: #C2E3EA; border-color: #C2E3EA;">
                <div class="container">
                    <div class="bar overflow-hidden flex-wrap" style="height: 60px;">
                        <div class="bar-property" v-for="message in $store.getters.getFilterMessages" :key="message">
                            <div class="button-bar cursor-default whitespace-no-wrap">
                                <p>{{message}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="filter-priorities">
                        <a href="" @click.prevent="redirectFilters" v-show="hasProperties">
                            <i class="icon icon-filter dark-gray" style="filter: invert(1);"></i>
                            <p style="color:#382c7a;">Filtros</p>
                        </a>
                        <a href="" @click.prevent="redirectOrder" v-show="hasProperties">
                            <i class="icon icon-order dark-gray" style="filter: invert(1);"></i>
                            <p style="color:#382c7a;">Reordenar Prioridades</p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="page-title" v-if="hasProperties">
                <h3>{{this.getOrderedSearch.length |
                    pluralString("Indicamos este imóvel para você",
                    "Indicamos estes %VAL% imóveis para você")}}</h3>
            </div>
            <div class="content">
                <div class="container">
                    <div class="row" v-if="hasProperties">
                        <div class="map-column col-xl-5">
                            <div class="map-wrapper">
                                <OpenStreetContainerMultiple :mainAddress="$store.getters.getAddress"
                                                            :properties="getSearchResults"/>
                            </div>
                        </div>
                        <div class="cards-column col-xl-7">
                            <div class="cards-header hidden lg:flex">
                                <h1>{{this.getOrderedSearch.length | pluralString("Indicamos este imóvel para você",
                                    "Indicamos estes %VAL% imóveis para você")}}</h1>
                                <div class="dropdown-wrapper">
                                    <p>Ordenar por</p>
                                    <div class="dropdown">
                                        <button aria-expanded="false" aria-haspopup="true" data-toggle="dropdown"
                                                id="dropdownMenuButton" type="button">
                                            {{orderType}}
                                            <i class="icon icon-arrow-down highlight"></i>
                                        </button>
                                        <div aria-labelledby="dropdownMenuButton"
                                             class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href @click.prevent="orderType = 'Relevância'">Relevância</a>
                                            <a class="dropdown-item" href @click.prevent="orderType = 'Menor Preço'">Menor
                                                Preço</a>
                                            <a class="dropdown-item" href @click.prevent="orderType = 'Maior Preço'">Maior
                                                Preço</a>
                                            <a class="dropdown-item" href @click.prevent="orderType = 'Maior Área'">Maior
                                                Área</a>
                                            <a class="dropdown-item" href @click.prevent="orderType = 'Menor Área'">Menor
                                                Área</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="feed">
                                <div :key="property.id" v-for="property in this.getPaginatedSearch"
                                     class="property-card-wrapper">
                                    <PropertyCard
                                            :favorites_page="true"
                                            :match="property.bingo"
                                            :property_reference="property.reference"
                                            :relevance="property.relevance"
                                            :search_page="true"
                                            :property_obj="property"
                                    />
                                </div>

                                <div class="dream-property-container" v-if="hasDreamProperty">
                                    <div class="flex flex-col items-center md:items-start pb-6 md:pb-0 text-center md:text-left md:w-2/3">
                                        <img src="../images/dream-property.svg">
                                        <h1>Ainda não encontrou o imóvel perfeito?</h1>
                                        <h1>Nós buscamos para você!</h1>
                                    </div>

                                    <hr/>

                                    <p>{{createDemandSentence()}}</p>
                                    <div class="flex flex-col md:flex-row items-center justify-between px-4 md:px-0">
                                        <button class="secondary-button md:hidden mb-4" data-toggle="modal" data-target="#matchModal">Procure meu imóvel por mim</button>
                                        <button class="secondary-button hidden md:flex" data-toggle="modal" data-target="#matchModal">Procure meu imóvel por mim
                                        </button>
                                    </div>
                                    <MatchModal id="matchModal"/>
                                </div>

                                <div class="flex justify-center md:hidden">
                                    <Pagination :offset.sync="offset" :instances-per-page="instancesPerPage"
                                                :total-count="getOrderedSearch.length" :limit="true"></Pagination>
                                    <ul class="pagination">
                                        <li @click="openCloseModal = true" class="page-item filter-button"><a
                                                class="page-link">
                                            <div class="icon icon-filter white"></div>
                                        </a></li>
                                    </ul>
                                </div>
                                <div class="hidden md:flex">
                                    <Pagination class="mx-auto" :offset.sync="offset"
                                                :instances-per-page="instancesPerPage"
                                                :total-count="getOrderedSearch.length"></Pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <img class="mx-auto" src="../images/nothing-found.svg">
                        <h1>Não encontramos imóveis para a sua busca</h1>
                        <p>Ajuste seus filtros e tente novamente.</p>
                        <button class="primary-button" @click="redirectFilters">Ajustar meus filtros</button>

                        <div class="dream-property-container" v-if="hasDreamProperty">
                            <div class="flex flex-col items-center md:items-start pb-6 md:pb-0 text-center md:text-left md:w-2/3">
                                <img src="../images/dream-property.svg">
                                <h1>Ainda não encontrou o imóvel perfeito?</h1>
                                <h1>Nós buscamos para você!</h1>
                            </div>

                            <hr/>

                            <p>{{createDemandSentence()}}</p>
                            <div class="flex flex-col md:flex-row items-center justify-between px-4 md:px-0">
                                <button class="secondary-button md:hidden mb-4" data-toggle="modal" data-target="#matchModal">Procure meu imóvel por mim</button>
                                <button class="secondary-button hidden md:flex" data-toggle="modal" data-target="#matchModal">Procure meu imóvel por mim
                                </button>
                            </div>
                            <MatchModal id="matchModal"/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="input-box-order-wrapper md:hidden">
                <InputBox
                        :form-data="orderTypes"
                        :selected-data="[orderType]"
                        @updateCheckedValues="updateCheckedValues"
                        description="Ordenar busca"
                        label="orderType"
                        title="Ordenar busca"
                        :allow-multiple="false"
                        id="inputBoxOrder"
                />
            </div>
        </div>
        <div class="filter-modal" v-show="openCloseModal">
            <div @click="redirectFilters" class="filter-modal-item mt-6"><i class="icon icon-filter highlight"></i>
                <p>Filtros</p></div>
            <div @click="openInputBoxOrder" class="filter-modal-item mt-4"><i class="icon icon-order highlight"></i>
                <p>Ordenar Resultados</p></div>

            <div class="close-modal" @click="openCloseModal = false">
                <div class="icon icon-close dark-gray"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from "../js/api/UserService";
    import UserClass from "../js/classes/user";
    import PropertyCard from "../components/property/PropertyCard.vue";
    import OpenStreetContainerMultiple from "../components/OpenStreetContainerMultiple";
    import {mapGetters} from "vuex";
    import Pagination from "../components/filters/Pagination";
    import InputBox from "../components/filters/InputBox";
    import jQuery from "jquery"
    import fetchUser from "../js/mixins/fetchUser";
    import MatchModal from "../components/MatchModal";

    export default {
        name: "Search",
        components: {
            PropertyCard,
            OpenStreetContainerMultiple,
            Pagination,
            InputBox,
            MatchModal
        },
        mixins: [fetchUser],
        data() {
            return {
                isLoaded: false,
                inputBoxValues: {},
                hiddenFooter: false,
                hasProperties: false,
                instancesPerPage: 10,
                offset: 0,
                openCloseModal: false,
                orderType: 'Relevância',
                hasDreamProperty: false, // has enough filters to create demand
                orderTypes: [
                    {label: "Relevância"},
                    {label: "Menor Preço"},
                    {label: "Maior Preço"},
                    {label: "Maior Área"},
                    {label: "Menor Área"}]
            }
        },
        created() {
            this.updateUser(this.$store.state.userToken);
            if (this.getSearchResults.length > 0) {
                this.hasProperties = true;
            }
            if(this.createDemandSentence()){
                this.hasDreamProperty = true;
            }
        },
        computed: {
            ...mapGetters([
                'getSearchResults',
                'getDiscardedProperties'
            ]),

            getOrderedSearch() {
                let orderedSearch;

                if (this.orderType === "Relevância") {
                    orderedSearch = this.getSearchResults.slice().sort((a, b) => b.relevance - a.relevance);
                } else if (this.orderType === "Menor Preço") {
                    orderedSearch = this.getSearchResults.slice().sort((a, b) => a.sell_value - b.sell_value);
                } else if (this.orderType === "Maior Preço") {
                    orderedSearch = this.getSearchResults.slice().sort((a, b) => b.sell_value - a.sell_value);
                } else if (this.orderType === "Maior Área") {
                    orderedSearch = this.getSearchResults.slice().sort((a, b) => b.useful_area - a.useful_area);
                } else if (this.orderType === "Menor Área") {
                    orderedSearch = this.getSearchResults.slice().sort((a, b) => a.useful_area - b.useful_area);
                } else {
                    orderedSearch = this.getSearchResults;
                }


                orderedSearch = orderedSearch.filter((property) => !this.getDiscardedProperties.includes(property.id));
                return orderedSearch;
            },

            getPaginatedSearch() {
                return this.getOrderedSearch.slice(this.offset, this.offset + this.instancesPerPage)
            }

        },

        methods: {

            updateCheckedValues(value) {
                const label = Object.keys(value)[0];
                if (value[label][0]) {
                    this.orderType = value[label][0];
                } else {
                    this.orderType = "Relevância"; // default
                }
                this.inputBoxValues[label] = value[label];
            },

            async getData(token) {
                try {
                    const {data} = await UserService.getData(token);
                    this.user = new UserClass(data['data']);
                    this.isLoaded = true;
                } catch (err) {
                    // console.log(err);
                }
            },

            redirectFilters() {
                this.$router.push({
                    name: "search-filters"
                });
            },

            redirectOrder() {
                this.$router.push({
                    name: "search-order"
                });
            },

            openInputBoxOrder() {
                jQuery("#inputBoxOrder > details").click()
            },

            createDemandSentence() {
                const filters = this.$store.getters.getFilterOrder;
                const searchData = this.$store.getters.getSearchData;
                // property type
                // area
                // value
                //address or neighborhood
                const validAddress = filters.includes("neighborhood_filter") || filters.includes("within_radius_filter") || filters.includes("on_address_filter");

                if (filters.includes("property_type_filter") && filters.includes("area_filter") && filters.includes("price_range_filter") && validAddress) {
                    let dealTypeSentence = searchData.dealType === "Vendas" ? "à venda" : "para alugar";
                    let propertyTypeSentence = this.$options.filters.toSentence(searchData.propertyTypes, "ou");
                    let addressSentence;
                    if (filters.includes("neighborhood_filter")) {
                        addressSentence = "em " + this.$options.filters.toSentence(searchData.neighborhoods, "ou")
                    } else if (filters.includes("within_radius_filter")){
                        addressSentence = `dentro de um raio de ${this.$options.filters.metersToKm(searchData.address.radius)} de ${searchData.address.address}`
                    } else {
                        addressSentence = `em ${searchData.address.address}`
                    }

                    let fullSentence = `${propertyTypeSentence} ${dealTypeSentence}, ${addressSentence}`;

                    return fullSentence;
                }

                return false;

            }
        },
        toggleHiddenFooter() {
            this.hiddenFooter = !this.hiddenFooter
        }
    };
</script>
