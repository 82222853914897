<template>
    <div class="page">
        <div class="main">
            <div class="botton-div"></div>
            <div class="main-msg">Clique nos campos abaixo para prosseguir.</div>
        </div>
        <div  class="content">
            <div class="container  container-content">
                <div id="buttons_procura_home" class="wrapper-buttons descubra">

                    <RouterLink :to="{ name: 'search-filters' }"  class="apolar-logo">
                        <span class="highlith"> <font-awesome-icon  icon="home" /> Descubra</span> o imóvel perfeito para você!
                    </RouterLink>

                </div>

                <div class="wrapper-buttons heart"> 
                    <font-awesome-icon  icon="heart" />
                 </div>

                <div class="wrapper-buttons encontre">
                    <RouterLink :to="{ name: 'register-property' }" class="apolar-logo">
                        <span class="highlith"> <font-awesome-icon  icon="user" /> Encontre</span> o cliente perfeito para seu imóvel!
                    </RouterLink>
                </div>
            </div>

            <section class="row justify-center">
                <div class="feed-container col-md-7">

                    <h2 v-if="getFilteredDemands.length > 0" class="confira">
                        Confira o que os nossos clientes estão procurando
                    </h2>

                    <DemandCard
                    v-for="(demand, index) in getPaginatedDemands"
                    :key="index"
                    v-bind:demand="demand"
                    :cities="cities"
                    />
                    <div style="display:none;" class="control-sm-property">
                    </div>
                    <Pagination
                    :offset.sync="offset"
                    :instances-per-page="instancesPerPage"
                    :total-count="getFilteredDemands.length"
                    ></Pagination>
                    <div style="clear:both;"></div>
                </div>
                </section>

            <div class="container">
                <span class="footer-msg">
                    Nós valorizamos a sua privacidade! Trabalhamos para que sua busca seja segura, todos os anúncios são verificados por nossa equipe.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from "jquery";

    import DemandCard from "../components/demands/DemandCard";
    import FetchDemands from "../js/mixins/fetchDemands";
    import Pagination from "../components/filters/Pagination";

    import {required} from "vuelidate/lib/validators";
    import FetchCities from "../js/mixins/fetchCities"
    import PropertyService from "../js/api/PropertyService";

    export default {
        name: 'new-home',
        components: {
            DemandCard,Pagination
        },
        mixins: [FetchCities, FetchDemands],
        data() {
            return {
                city: null,
                demands: [],
                ref: null,
                sent: false,
                dealType: "Vendas",
                instancesPerPage: 5,
                offset: 0,
                search: true,
                propertyNotFound: false
            }
        },
        created() {
            this.fetchCities();
        },
        computed: {
            getFilteredDemands() {
                let filteredDemands = this.demands.filter(
                    (demand) => demand.status === "published"
                );
                

                if (filteredDemands.length > 0) {
                    document.querySelector('.page-main-footer').style.position = 'relative';
                    document.querySelector('.page-main-footer').style.bottom = '0px';
                }

                return filteredDemands.sort((a, b) => {
                    return b.id - a.id;
                });
            },

            getPaginatedDemands() {
                return this.getFilteredDemands.slice(
                    this.offset,
                    this.offset + this.instancesPerPage
                );
            },
        },
        validations: {
            city: {
                required
            },
            ref: {}
        },

        methods: {
            sendForm() {
                this.sent = true;
                this.$v.$touch();

                if (this.$v.$invalid) {
                    // console.log(`form is invalid!`);
                    return false;
                }
                if (this.search) {
                    this.$store.commit("clearSearchData");
                    this.$store.commit("assignSearchCity", {city: this.city, dealType: this.dealType});
                    this.$router.push({
                        name: "search-filters",
                        params: {initial_city: this.city, initial_dealType: this.dealType}
                    });
                } else {
                    this.$router.push({
                        name: "register-property",
                        params: {initial_city: this.city, initial_dealType: this.dealType}
                    });
                }
            },

            onSelectCity(result, searchString) {
                if (searchString.toString().toLowerCase() === result[0].toString().toLowerCase()) {
                    this.city = this.cities.find(city => city.label === result[0]);
                } else {
                    this.city = null;
                }
            },

            searchProperty(){
                this.fetchProperty(encodeURIComponent(this.ref));
            },

            async fetchProperty(reference) {
                try {
                    const {data} = await PropertyService.getByReference(reference);
                    if(data['data'].reference){
                        this.$refs.closeModal.click();
                        await this.$router.push({
                            name: 'property',
                            params: {reference: data['data'].reference}
                        });
                    }
                } catch (err) {
                    this.propertyNotFound = true;
                }
            }
        },

        mounted() {

            if ( window.location.href.indexOf("?demands") != -1 ) {
                setTimeout(()=>{
                    jQuery("html, body").animate({ scrollTop: (jQuery("#buttons_procura_home").position().top -100) }, "fast");
                },1000)
            }

            jQuery(document).on('scroll', ()=>{
                if ( window.top.scrollY > 0 ) {
                    jQuery("header.master-header").addClass('master-header-white-bg')
                } else {
                    jQuery("header.master-header").removeClass('master-header-white-bg')
                }
            })
        }
    }
</script>
