class DemandClass  {
    constructor(data){
        this.id = data.id;
        this.reference = data.reference;
        this.client = data.client;
        this.property_type = data.property_type;
        this.min_area = data.min_area;
        this.max_area = data.max_area;
        this.min_value = data.min_value;
        this.max_value = data.max_value;
        this.features = data.features;
        this.address = data.address;
        this.custom_features = data.custom_features;
        this.neighborhood = data.neighborhood;
        this.status = data.status;
        this.inserted_at = data.inserted_at;
        this.deal_type = data.deal_type;
        this.offered_exchange = data.offered_exchange;
    }

    neighborhoodNames = () => {
        return this.neighborhood.map(neighborhood => neighborhood.label);
    };

    minMaxArea = () => {
        if (this.min_area == this.max_area) {
            return `com ${this.min_area}m²`
        }
        else {
            return `de ${this.min_area}m² a ${this.max_area}m²`
        }
    };

    minMaxRooms = () => {
        if(this.features.min_rooms === -1) {
            return "Indiferente";
        }

        if (this.features.min_rooms >= 10) {
            return '10+';
        }
        else if (this.features.min_rooms == this.features.max_rooms) {
            return `${this.features.min_rooms}`;
        }
        else {
            return `${this.features.min_rooms} a ${this.features.max_rooms}`
        }
    };

    minMaxBathrooms = () => {

        if(this.features.min_bathrooms === -1) {
            return "Indiferente";
        }

        if (this.features.min_bathrooms >= 10) {
            return '10+';
        }
        else if (this.features.min_bathrooms == this.features.max_bathrooms) {
            return `${this.features.min_bathrooms}`;
        }
        else {
            return `${this.features.min_bathrooms} a ${this.features.max_bathrooms}`
        }
    };

    minMaxSuites = () => {

        if(this.features.min_suites === -1) {
            return "Indiferente";
        }

        if (this.features.min_suites >= 10) {
            return '10+';
        }
        else if (this.features.min_suites == this.features.max_suites) {
            return `${this.features.min_suites}`;
        }
        else {
            return `${this.features.min_suites} a ${this.features.max_suites}`
        }
    };

    minMaxGarages = () => {

        if(this.features.min_garages === -1) {
            return "Indiferente";
        }

        if (this.features.min_garages >= 10) {
            return '10+';
        }
        else if (this.features.min_garages == this.features.max_garages) {
            return `${this.features.min_garages}`;
        }
        else {
            return `${this.features.min_garages} a ${this.features.max_garages}`
        }
    };

    fullStreet = () => {
        return `${this.address.address}, ${this.address.number}`
    };

    fullCityDetails = () => {
        return `${this.address.neighborhood}, ${this.address.city}`
    };

    fullAddress = () => {
        return `${this.fullStreet()} - ${this.fullCityDetails()}`
    };

    mapPos = () => {
        return {lat: this.address.latitude, lng: this.address.longitude};
    };

    mapRadius = () => {
        return this.address.radius;
    };

    firstName = () => {
        var arr = this.client.name.split(" ");

        return arr[0];
    };
}

export default DemandClass
