<template>
    <div class="modal" tabindex="-1">
        <div class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
             role="document"
             style="pointer-events: auto;">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="inner-modal">
                        <div class="modal-header">
                            <div @click="closeModal">
                                <a class="close-button">
                                    <i style="height: 40px; width: 40px; margin-left: 0px;"
                                       class="icon icon-close gray cursor-pointer"></i>
                                </a>
                            </div>

                            <a class="hidden" ref="closeMatchModal" data-toggle="modal" data-target="#matchModal">

                            </a>
                        </div>
                        <div v-if="step == 1" class="modal-body-content step-1">
                            <div class="row w-full m-0">
                                <div class="col-md-6 justify-end hidden md:block">
                                    <img class="mx-auto" src="../images/exchange@2x.png">
                                </div>
                                <div class="col-md-6 p-0">
                                    <h3 class="md:hidden">Confirme o que quer oferecer como permuta</h3>
                                    <h3 class="hidden md:block">Confirme o que quer oferecer como parte de
                                        pagamento</h3>
                                    <div class="property-details">
                                        <h4 v-if="$store.getters.getSearchExchange[0]['exchange_type'] === 'property'">
                                            {{$store.getters.getSearchExchange[0]['property_type_label']}} no
                                            {{$store.getters.getSearchExchange[0]['neighborhood_label']}}</h4>
                                        <h4 v-else>{{$store.getters.getSearchExchange[0]['vehicle_make']}}
                                            {{$store.getters.getSearchExchange[0]['vehicle_model']}}</h4>
                                        <div class="demand-card-values">
                                            <div class="value mr-8">
                                                <span>Valor Aproximado</span>
                                                <div class="currency-display">
                                                    <p class="currency-type">R$</p>
                                                    <p class="currency-value">
                                                        {{$store.getters.getSearchExchange[0]['value'] |
                                                        currency}}</p>
                                                    <p class="currency-cents ml-1 mr-0">,00</p>
                                                </div>
                                            </div>
                                            <div class="value"
                                                 v-if="$store.getters.getSearchExchange[0]['exchange_type'] === 'property'">
                                                <span>Área Útil</span>
                                                <div class="area-display">
                                                    <p class="area-value">
                                                        {{$store.getters.getSearchExchange[0]['area']}}<span
                                                            class="area-type ml-1">m²</span></p>
                                                </div>
                                            </div>
                                            <div class="value" v-else>
                                                <span>Ano</span>
                                                <div class="area-display">
                                                    <p class="area-value">
                                                        {{$store.getters.getSearchExchange[0]['year']}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="primary-button" @click="nextStep();">Confirmar</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="step == 2" class="modal-body-content step-2">
                            <div class="col-md-6 justify-end hidden md:block">
                                <img class="mx-auto" src="../images/identification.svg">
                            </div>
                            <div class="col-md-6">
                                <h3>
                                    Confirme seus Dados Pessoais
                                </h3>
                                <div class="input-set">
                                    <input class="form-input" v-model="$v.fullName.$model" required>
                                    <label class="form-label pointer-events-none" for="fullName"
                                           :class="{'hide-small': !fullName}">Nome
                                        completo*</label>
                                    <span class="form-error" v-show="sent && !$v.fullName.required">Esse campo é
                                        obrigatório</span>
                                </div>
                                <div class="input-set">
                                    <input class="form-input" v-model="$v.phone.$model" type="tel"
                                           v-mask="['(##) #### ####', '(##) ##### ####']" required>
                                    <label class="form-label pointer-events-none" for="phone"
                                           :class="{'hide-small': !phone}">Telefone para
                                        contato*</label>
                                    <span class="form-error" v-show="sent && $v.phone.$invalid">Esse campo é
                                        obrigatório</span>
                                </div>
                                <div class="input-set">
                                    <input class="form-input" v-model="$v.email.$model" required autocapitalize="none">
                                    <label class="form-label pointer-events-none" for="email"
                                           :class="{'hide-small': !email}">E-mail*</label>
                                    <span class="form-error" v-show="sent && $v.email.$invalid">Esse campo é
                                        obrigatório</span>
                                </div>
                                <button class="primary-button" @click="sendForm">Confirmar</button>
                            </div>
                        </div>
                        <div v-if="step == 3" class="modal-body-content step-3">
                            <img class="mx-auto" src="../images/message-sent.svg">
                            <h3>Sua procura foi publicada com sucesso na página Apolar.</h3>
                            <button class="primary-button md:hidden" data-dismiss="modal"
                                    data-target="#matchModal">Confirmar
                            </button>
                            <button class="primary-button hidden md:flex" data-dismiss="modal"
                                    data-target="#matchModal">Voltar para a pesquisa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {required, email} from "vuelidate/lib/validators"
    import {mask} from 'vue-the-mask'
    import DemandService from "../js/api/DemandService";

    export default {
        name: 'MatchModal',
        directives: {mask},
        data() {
            return {
                step: 1,
                fullName: null,
                phone: null,
                email: null,
                sent: false
            }
        },
        created() {

            if (this.$store.getters.getSearchExchange.length === 0) {
                this.step = 2;
            }
        },
        validations: {
            fullName: {
                required
            },
            phone: {
                required
            },
            email: {
                required,
                email
            }
        },
        methods: {
            nextStep() {
                this.step++;
            },
            redirectHome(){
                this.$refs.closeMatchModal.click();
                this.$router.push('#/search');
            },
            async sendForm() {
                this.sent = true;
                if (!this.$v.email.$invalid && !this.$v.fullName.$invalid && !this.$v.phone.$invalid) {
                    try {
                        let client = {
                            name: this.fullName,
                            phone_number: this.phone,
                            email: this.email
                        };
                        let payload = this.$store.getters.getCreateDemandPost;
                        payload = Object.assign(payload, {client: client});

                        payload['role_id'] = 1;

                        console.log("Demand payload: ", JSON.stringify(payload))

                        await DemandService.postDemand(payload);

                        this.step = 3;
                    } catch (err) {
                        // console.log(err.response)
                    }

                }
            },
            closeModal() {
                if (this.$store.getters.getSearchExchange.length === 0) {
                    this.step = 2;
                } else {
                    this.step = 1;
                }
                this.$refs.closeMatchModal.click();
            }
        }
    }
</script>
