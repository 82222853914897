<template>
    <div class="page">
        <div class="page-header">
            <div class="container">
                <h1>Demandas</h1>
                <div class="dashboard-label-wrapper">
                    <!-- <div class="dashboard-label">
                        <label class="checkbox-container">
                            <input value="answered" v-model="statusFilters" type="checkbox">
                            <span class="checkmark yellow"></span>
                            <strong class="md:hidden">{{getDemandsByStatus['answered'].length}}</strong>
                            <p class="hidden md:inline"><strong>{{getDemandsByStatus['answered'].length}}</strong> Atendidos </p>
                        </label>
                        <p class="md:hidden">Atendidos</p>
                    </div> -->
                    <!-- <div class="dashboard-label">
                        <label class="checkbox-container">
                            <input value="pending" v-model="statusFilters" type="checkbox">
                            <span class="checkmark red"></span>
                            <strong class="md:hidden">{{getDemandsByStatus['pending'].length}}</strong>
                            <p class="hidden md:inline"><strong>{{getDemandsByStatus['pending'].length}}</strong> Não Atendidos</p>
                        </label>
                        <p class="md:hidden">Não Atendidos</p>
                    </div> -->
                    <div class="dashboard-label">
                        <label class="checkbox-container">
                            <input value="published" v-model="statusFilters" type="checkbox">
                            <span class="checkmark blue"></span>
                            <strong class="md:hidden">{{getDemandsByStatus['published'].length}}</strong>
                            <p class="hidden md:inline"><strong>{{getDemandsByStatus['published'].length}}</strong> Publicados</p>
                        </label>
                        <p class="md:hidden">Publicados</p>
                    </div>
                    <div class="dashboard-label">
                        <label class="checkbox-container">
                            <input value="finished" v-model="statusFilters" type="checkbox">
                            <span class="checkmark green"></span>
                            <strong class="md:hidden">{{getDemandsByStatus['finished'].length}}</strong>
                            <p class="hidden md:inline"><strong>{{getDemandsByStatus['finished'].length}}</strong> Finalizados</p>
                        </label>
                        <p class="md:hidden">Finalizados</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="card-label-header">
                <div class="container">
                    <div class="switch">
                        <div :class="{active: dealType === 'Vendas'}" @click="dealType = 'Vendas'">
                            Imóveis para Venda
                            <i class="yellow-line"></i>
                        </div>
                        <div style="display:none !important;" :class="{active: dealType === 'Locação'}" @click="dealType = 'Locação'">
                            Imóveis para Aluguel
                            <i class="yellow-line"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="card-label-wrapper row m-0">
                    <div class="col-3">Cliente</div>
                    <div class="col-4">Imóvel Solicitado</div>
                </div>
                <div v-if="loading" style="margin-left:50%" class="spinner-border text-success-darker" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <DashboardCard v-else 
                    @updateInfo="updateDemands" 
                    v-for="demand in getPaginatedDemands" 
                    :key="demand.id" 
                    :demand="demand" 
                    :demands="true"
                    :cities="cities"
                    />

                <Pagination :offset.sync="offset" :instances-per-page="instancesPerPage" :total-count="getFilteredDemands.length"></Pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardCard from "../../components/property/DashboardCard";
    import FetchDemands from "../../js/mixins/fetchDemands"
    import Pagination from "../../components/filters/Pagination";
    import FetchCities from "../../js/mixins/fetchCities"
    
    export default {
        name: 'Dashboard',
        mixins: [FetchDemands, FetchCities],
        components: {
            DashboardCard,
            Pagination
        },
        created() {

            this.fetchCities()

            if(!this.$store.state.user){
                this.$router.push({name: "login"});
            } else if( !this.checkAdminStoreCode(this.$store.state.user.storecode) ) { // TODO editar para !== depois de teste
                this.$router.push({name: "home"});
            }
        },

        data() {
            return {
                demandList: {pending:[], answered: [], published: [], finished: []},
                demandListColor: {pending: "red", answered: "yellow", published: "blue", finished: "green"},
                statusFilters: ["pending", "published", "answered", "finished"],
                instancesPerPage: 10,
                offset: 0,
                dealType: "Vendas"
            }
        },

        computed: {
            getDemandsByStatus() {
                let demandList = {pending:[], answered: [], published: [], finished: []};
                this.demands.forEach(demand => {
                    if(!demandList[demand.status]){
                        demandList[demand.status] = [];
                    }
                    demandList[demand.status].push(demand);
                });
                return demandList;
            },

            getFilteredDemands(){
                let filteredDemands = this.demands.filter(demand => this.statusFilters.includes(demand.status) && this.dealType === demand.deal_type);

                return filteredDemands.sort((a,b) => {return b.id - a.id});
            },

            getPaginatedDemands() {
                return this.getFilteredDemands.slice(this.offset, this.offset + this.instancesPerPage)
            }
        },
        methods: {
            updateDemands(){
                this.fetchDemands();
            },
            checkAdminStoreCode(storecode) {

                storecode = typeof storecode != 'string' ? storecode.toString() : storecode
                
                if ( storecode.split(',').indexOf('1') != -1 ) {
                    return true
                }

                return false

            }
        }
    }
</script>
