import Api from '../api/Api.js'

const apiPath = 'users';
const newPasswordPath = 'change_password';

export default {
	getData(token) {
		const config = {
			headers: { Authorization: `Bearer ${token}` }
		};

		return Api.get(apiPath, config);
	},

	updateUser(id, user, token) {
		const config = {
			headers: { Authorization: `Bearer ${token}` }
		};

		return Api.put(apiPath+`/${id}`, user, config);
	},

	newPassword(password, token) {
		const config = {
			headers: { Authorization: `Bearer ${token}` }
		};

		return Api.post(newPasswordPath, password, config);
	}
};