<template>
    <div class="page-footer">

        <footer class="mobile-footer" v-if="!isCustomFooter">
            <a href="#/" class="block item item-home" :class="{active: this.$route.name === 'new-home'}" id="footer-menu-home" @click.prevent="$router.push({name: `new-home`}).catch(err => {})">
                <div>
                    <i class="icon icon-line-logo"></i>
                    <p>Home</p>
                </div>
            </a>
            <a href="#" class="block item item-comparative" :class="{active: this.$route.name === 'comparation-page'}" id="footer-menu-comparative" @click.prevent="$router.push({name: `comparation-page`}).catch(err => {})">
                <div>
                    <i class="icon icon-compare"></i>
                    <p>Comparativo</p>
                </div>
            </a>
            <a href="#" class="block item item-profile" :class="{active: this.$route.name === 'profile'}" id="footer-menu-profile" @click.prevent="$router.push({name: `profile`}).catch(err => {})">
                <div>
                    <i class="icon icon-user-profile"></i>
                    <p>Perfil</p>
                </div>
            </a>
            <a href="#" class="block item item-menu" data-toggle="modal" data-target="#menuModal">
                <div>
                    <i class="icon icon-hamburguer-menu"></i>
                    <p>Menu</p>
                </div>
            </a>
        </footer>
        <footer class="mobile-footer" v-if="isCustomFooter">
            <a href="#" class="block item item-profile active" id="footer-menu-profile">
                <div>
                    <i class="icon icon-user-profile"></i>
                    <p>Perfil</p>
                </div>
            </a>
            <a href="#/demands" class="block item item-home" id="footer-menu-home" @click.prevent="$router.push({name: `new-home`}).catch(err => {})">
                <div>
                    <i class="icon icon-line-logo"></i>
                    <p>Página Inicial</p>
                </div>
            </a>
            <a href="#" class="block item item-menu" data-toggle="modal" data-target="#menuModal">
                <div>
                    <i class="icon icon-hamburguer-menu"></i>
                    <p>Menu</p>
                </div>
            </a>
        </footer>
        <MenuModal id="menuModal"/>
    </div>
</template>

<script>
    import MenuModal from "./MenuModal";

    const customFooterRoutes = [
        'register',
        'login',
        'forgotpassword',
        'confirmation',
        'resetpassword'
    ];

    export default {
        name: 'Footer',
        components: {
            MenuModal
        },
        computed: {
            isCustomFooter() {
                return customFooterRoutes.includes(this.$route.name);
            }
        }
    }
</script>
