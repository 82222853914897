import Api from '../api/Api.js'

const TabelaReferencia = 'fipe/codigo';
const ConsultarMarcas = 'fipe/marcas';
const ConsultarModelos = 'fipe/modelos';
const ConsultarAnoModelo = 'fipe/ano';
const ConsultarValor = 'fipe/valor';

export default {
	getTabelaReferencia(payload){
		return Api.post(TabelaReferencia, payload);
	},
	getConsultarMarcas(payload){
		return Api.post(ConsultarMarcas, payload);
	},
	getConsultarModelos(payload){
		return Api.post(ConsultarModelos, payload);
	},
	getConsultarAnoModelo(payload){
		return Api.post(ConsultarAnoModelo, payload);
	},
	getValor(payload){
		return Api.post(ConsultarValor, payload);
	}
};
