import DemandService from "../api/DemandService";
import DemandClass from "../classes/demand";

export default {
    data() {
        return {
            demands: [],
            loading: false
        }
    },
    created() {
        this.fetchDemands()
    },

    methods: {
        async fetchDemands() {
            this.loading = true;
            try {
                const {data} = await DemandService.get();
                this.demands = data['data'];
                this.demands = this.demands.map((v) => new DemandClass(v));
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        }
    }
};
