<template>
    <div class="step-counter">
        <span></span>
        <div>
            <i v-for="step in parseInt(stepCount)" :class="{done: step  <= stepsDone, doing: step === parseInt(stepsDone) + 1}" :key="step">
            </i>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StepCounter',
        props: {
          stepCount: {required: true},
          stepsDone: {}
        }
    }
</script>
