<template>
    <StatusPage 
        img="403.svg"
        title="Acesso Negado"
        description="Você não tem autorização para acessar esse conteúdo. Faça login, ou lorem ipsum."
    />
</template>

<script>
    import StatusPage from '../../components/StatusPage.vue'

    export default {
        name: 'Forbidden',
        components: {
            StatusPage,
        }    
    }
</script>
