import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import Slug from 'slug'
import UserService from "../js/api/UserService";
import UserClass from "../js/classes/user";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    key: 'vuex_apolar',
    storage: window.localStorage
});

const initialSearchData = {
    dealType: "Vendas",
    dealTypeId: null,
    city: null,
    addressSearchType: "",
    neighborhoods: [],
    propertyTypes: [],
    address: {
        address: null,
        number: null,
        radius: 300,
        latitude: null,
        longitude: null,
        city: null,
        neighborhood: null
    },
    minValue: null,
    maxValue: null,
    minArea: null,
    maxArea: null,
    valueRangeType: "rent", //rent or total
    room_count: {min: 0, max: 0},
    bathroom_count: {min: 0, max: 0},
    suite_count: {min: 0, max: 0},
    garage_count: {min: 0, max: 0},
    petFriendly: "",
    furnished: "",
    customFeaturesProperty: [],
    customFeaturesPropertyObj: [],
    customFeaturesCondominium: [],
    customFeaturesCondominiumObj: []
};

function removeMoneyFormatting(value) {
    if(value){
        let newValue = value.replace("R$ ", "").split(",")[0].replace(/\./g, "");
        return parseInt(newValue);
    }

    return 0;
}

function removeAreaFormatting(value) {
    if(value){
        let newValue = value.replace("m²", "").replace(".", "");
        return parseInt(newValue);
    }

    return 0;
}


export default new Vuex.Store({
    state: {
        searchData: initialSearchData,
        filtersOrder: [],
        filterMessages: [],
        searchResults: [],
        user: null,
        userToken: null,
        exchangesRegisterProperty: [],
        searchExchange: [],
        discardedProperties: []
    },
    mutations: {
        setSearchData(state, searchData) {
            state.searchData = searchData;
        },

        setSearchCity(state, city) {
            state.searchData.city = city;
        },

        clearSearchCity(state) {
            state.searchData.city = null;
        },

        assignSearchCity(state, newSearchData) {
            state.searchData = Object.assign(state.searchData, newSearchData);
        },

        clearSearchData(state) {
            state.searchData = initialSearchData;
            state.filtersOrder = [];
            state.searchResults = [];
            state.filterMessages = [];
            state.discardedProperties = [];
        },

        setFilterOrder(state, filterOrder) {
            state.filtersOrder = filterOrder;
            state.searchResults = [];
        },

        setFilterMessages(state, filterMessage) {
            state.filterMessages = filterMessage;
        },

        setSearchResults(state, searchResults) {
            state.searchResults = searchResults;
            state.discardedProperties = []; // new results? clear discarded properties
        },

        clearSearchResults(state){
            state.searchResults = []
        },

        setUserToken(state, token){
            state.userToken = token;
            localStorage.token = token;
        },

        setUser(state, user){
            state.user = user;
        },

        logoutUser(state){
            state.user = null;
            state.userToken = null;
            localStorage.removeItem("token");
        },

        clearExchangesRegisterProperty(state){
            state.exchangesRegisterProperty = [];
        },

        addExchangesRegisterProperty(state, exchange){
            state.exchangesRegisterProperty.push(exchange)
        },

        removeExchangesRegisterProperty(state, index){
            state.exchangesRegisterProperty.splice(index, 1)
        },

        clearSearchExchange(state){
            state.searchExchange = [];
        },

        addSearchExchange(state, exchange){
            state.searchExchange = [];
            state.searchExchange.push(exchange);
        },

        discardProperty(state, propertyId){
            state.discardedProperties.push(propertyId);
        }
    },

    actions: {
        async loadUser(context) {
            try {
                const {data} = await UserService.getData(context.state.userToken);
                context.commit("setUser", new UserClass(data['data']));
            } catch (err) {
               return err;
            }
        }
    },

    getters: {
        getSearchResults: state => {
            return state.searchResults;
        },

        getDiscardedProperties: state => {
            return state.discardedProperties;
        },

        getSearchData: state => {
            return state.searchData;
        },

        getFilterOrder: state => {
            return state.filtersOrder;
        },

        getFilterMessages: state => {
            return state.filterMessages;
        },

        getAddress: (state) => {
            return state.searchData.address;
        },

        getSearchFilters: state => {
            let filters = [];

            if (state.searchData.propertyTypes.length !== 0) {
                filters.push({property_type_filter: {property_type: state.searchData.propertyTypes}})
            }

            if (state.searchData.addressSearchType === "neighborhood" && state.searchData.neighborhoods.length !== 0) {
                filters.push({neighborhood_filter: {neighborhood: state.searchData.neighborhoods}})
            }

            if (state.searchData.addressSearchType === "within_radius" && state.searchData.address.radius !== null && state.searchData.address.latitude !== null) {
                let fullAddress = state.searchData.address.address;
                if(state.searchData.address.number){
                    fullAddress+= `, ${state.searchData.address.number}`;
                }
                filters.push({
                    within_radius_filter: {
                        latitude: state.searchData.address.latitude,
                        longitude: state.searchData.address.longitude,
                        radius: state.searchData.address.radius / 1000,
                        address: fullAddress
                    }
                })
            }

            if (state.searchData.addressSearchType === "on_address" && state.searchData.address.address !== null) {
                filters.push({
                    on_address_filter: {
                        address: state.searchData.address.address
                    }
                })
            }

            if (removeMoneyFormatting(state.searchData.minValue) !== 0 && removeMoneyFormatting(state.searchData.maxValue) !== 0) {
                filters.push({
                    price_range_filter: {
                        min_value: removeMoneyFormatting(state.searchData.minValue),
                        max_value: removeMoneyFormatting(state.searchData.maxValue)
                    }
                })
            } else if (removeMoneyFormatting(state.searchData.maxValue) !== 0){
                filters.push({
                    price_range_filter: {
                        min_value: 0,
                        max_value: removeMoneyFormatting(state.searchData.maxValue)
                    }
                })
            }

            if (removeAreaFormatting(state.searchData.minArea) !== 0 && removeAreaFormatting(state.searchData.maxArea) !== 0) {
                filters.push({
                    area_filter: {
                        min_area: removeAreaFormatting(state.searchData.minArea),
                        max_area: removeAreaFormatting(state.searchData.maxArea)
                    }
                })
            } else if (removeAreaFormatting(state.searchData.maxArea) !== 0){
                filters.push({
                    area_filter: {
                        min_area: 0,
                        max_area: removeAreaFormatting(state.searchData.maxArea)
                    }
                })
            }

            if (state.searchData.room_count["min"] !== 0 && state.searchData.room_count["max"] !== 0) {
                filters.push({
                    bedroom_range_filter: {
                        min: state.searchData.room_count["min"],
                        max: state.searchData.room_count["max"]
                    }
                })
            }

            if (state.searchData.bathroom_count["min"] !== 0 && state.searchData.bathroom_count["max"] !== 0) {
                filters.push({
                    bathroom_range_filter: {
                        min: state.searchData.bathroom_count["min"],
                        max: state.searchData.bathroom_count["max"]
                    }
                })
            }

            if (state.searchData.suite_count["min"] !== 0 && state.searchData.suite_count["max"] !== 0) {
                filters.push({
                    suite_range_filter: {
                        min: state.searchData.suite_count["min"],
                        max: state.searchData.suite_count["max"]
                    }
                })
            }

            if (state.searchData.garage_count["min"] !== 0 && state.searchData.garage_count["max"] !== 0) {
                filters.push({
                    garage_range_filter: {
                        min: state.searchData.garage_count["min"],
                        max: state.searchData.garage_count["max"]
                    }
                })
            }

            if (state.searchData.furnished !== "") {
                filters.push({
                    furnished_filter: {
                        furnished: state.searchData.furnished
                    }
                })
            }

            if (state.searchData.petFriendly !== "") {
                filters.push({
                    pet_friendly_filter: {
                        pet_friendly: state.searchData.petFriendly === "yes"
                    }
                })
            }

            if (state.searchData.customFeaturesProperty.length !== 0 || state.searchData.customFeaturesCondominium.length !== 0) {
                state.searchData.customFeaturesCondominiumObj.forEach(featProperty => {
                    const keyName = "custom_feature_filter_condominium_" + Slug(featProperty.label, "_");
                    filters.push({
                        [keyName]: featProperty
                    });
                });
                state.searchData.customFeaturesPropertyObj.forEach(featProperty => {
                    const keyName = "custom_feature_filter_property_" + Slug(featProperty.label, "_");
                    filters.push({
                        [keyName]: featProperty
                    });
                });
            }

            return filters;
        },

        getSearchPost: (state, getters) => {
            let searchPost = {
                city: state.searchData.city.label,
                deal_type_id: state.searchData.dealTypeId
            };

            let filters = getters.getSearchFilters;
            filters = filters.reduce((result, filt) => {
                return Object.assign(result, filt);
            });
            const filtOrders = getters.getFilterOrder;
            filters = filtOrders.reduce((acc, order, i) => {
                const orderNumber = i+1;
                return Object.assign(acc, {[order]: Object.assign(filters[order], {order: orderNumber})}); // put order in existing formatted filters
            }, {});

            searchPost = Object.assign(searchPost, {filters: filters});
            if(getters.getSearchExchange.length > 0){
                searchPost = Object.assign(searchPost, {filter_exchange: [getters.getSearchExchange[0]]})
            }

            return searchPost;
        },

        getCreateDemandPost: (state, getters) =>{
            const filters = getters.getFilterOrder;
            let addressPayload;
            if (filters.includes("neighborhood_filter")) {
                addressPayload = null;
            } else if (filters.includes("within_radius_filter")){
                addressPayload = state.searchData.address;
                addressPayload["number"] = addressPayload["number"] || 0;
            } else {
                addressPayload = state.searchData.address;
                addressPayload["radius"] = 0;
                addressPayload["number"] = addressPayload["number"] || 0;
            }

            console.log("Search Data: ", state.searchData)

            let payload = {
                city_id: state.searchData.city.id,
                city_label: state.searchData.city.label,
                address: addressPayload,
                neighborhood: state.searchData.neighborhoods,
                deal_type_id: state.searchData.dealTypeId,
                min_area: removeAreaFormatting(state.searchData.minArea),
                max_area: removeAreaFormatting(state.searchData.maxArea),
                min_value: removeMoneyFormatting(state.searchData.minValue),
                max_value: removeMoneyFormatting(state.searchData.maxValue),
                status: 'published',
                feature: {
                    property_age: 0,
                    min_rooms: state.searchData.room_count["min"] === 0 ? -1 : state.searchData.room_count["min"],
                    max_rooms: state.searchData.room_count["max"] === 0 ? -1 : state.searchData.room_count["max"],
                    min_bathrooms: state.searchData.bathroom_count["min"] === 0 ? -1 : state.searchData.bathroom_count["min"],
                    max_bathrooms: state.searchData.bathroom_count["max"] === 0 ? -1 : state.searchData.room_count["max"],
                    min_suites: state.searchData.suite_count["min"] === 0 ? -1 : state.searchData.suite_count["min"],
                    max_suites: state.searchData.suite_count["max"] === 0 ? -1 : state.searchData.suite_count["max"],
                    min_garages: state.searchData.garage_count["min"] === 0 ? -1 : state.searchData.garage_count["min"],
                    max_garages: state.searchData.garage_count["max"] === 0 ? -1 : state.searchData.garage_count["max"],
                    pet_friendly: state.petFriendly !== ""? false : state.petFriendly,
                    furnished: state.furnished !== "" ? 'no' : state.furnished
                },
                property_type: state.searchData.propertyTypes,
                custom_features: {
                    property: state.searchData.customFeaturesProperty,
                    condominium: state.searchData.customFeaturesCondominium
                }
            };


            if(getters.getSearchExchange[0]) {
                payload = Object.assign(payload, {offered_exchange: [getters.getSearchExchange[0]]})
            }

            return payload;
        },

        getSearchExchange: state => {
            return state.searchExchange;
        },
        getExchangesRegisterProperty: state => {
            return state.exchangesRegisterProperty;
        },
        getExchangesRegisterPropertyObj: (state, getters) => {
            let exchanges = {vehicle: [], property: []};
            getters.getExchangesRegisterProperty.forEach(((exchange, index) => {
                if(exchange.exchange_type === "vehicle"){
                    exchanges.vehicle.push({exchange: exchange, index: index})
                } else {
                    exchanges.property.push({exchange: exchange, index: index})
                }
            }));

            return exchanges;
        },

        getUserFirstName: (state) => {
            if(state.user){
                return state.user.name.split(` `)[0];
            }

            return false;
        },

        getUserInitials: (state) => {
            const arr = state.user.name.split(" ");

            const firstLetter = arr[0].charAt(0);
            const lastLetter = arr[arr.length - 1].charAt(0);
            return (firstLetter + lastLetter).toUpperCase();
        }
    },

    plugins: [vuexLocalStorage.plugin]
})
