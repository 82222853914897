<template>
    <div class="modal" tabindex="-1">
        <div class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-sm"
             role="document"
             style="pointer-events: auto;">
            <div class="modal-content">
                <div class="modal-body flex justify-content-center">
                    <div class="inner-modal">
                        <div class="close-modal" ref="closeModalFavorite" data-toggle="modal"
                             :data-target="`#permutaModal`">×
                        </div>
                        <div class="modal-body-content">
                            <div>
                               A Comissão deverá ser negociada pelas partes envolvidas.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'PermutaModal'
    }
</script>
