import Api from '../api/Api.js'

const apiPath = 'comments';
export default {
    post(payload, token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        return Api.post(apiPath, {comment: payload}, config);
    },

    delete(comment_id, token){
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        return Api.delete(apiPath + "/" + comment_id, config);
    },

    update(comment_id, payload, token){
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        return Api.put(apiPath + "/" + comment_id, {comment: payload}, config)
    }
};
