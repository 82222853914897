<template>
    <div class="modal" tabindex="-1">
        <div class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
             role="document"
             style="pointer-events: auto;">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="inner-modal">
                        <div class="close-modal cursor-pointer" ref="closeModalRef" data-toggle="modal"
                             :data-target="auto? '#modal-auto' : '#modal-property'">×
                        </div>
                        <div class="modal-page-header md:hidden">
                            <a data-toggle="modal" :data-target="auto? '#modal-auto' : '#modal-property'"
                               class="close-button">
                                <i class="icon icon-arrow-link-left dark-gray"></i>
                            </a>
                            <h3 v-if="!auto">Adicionar Imóvel</h3>
                            <h3 v-if="auto">Adicionar Automóvel</h3>
                        </div>
                        <div class="container">
                            <form v-if="!auto" class="row pt-1 pb-3 overflow-x-hidden">
                                <div class="col-md-6">
                                    <h1 class="modal-title">Descreva o imóvel que você estudaria como permuta</h1>
                                    <Autocomplete :items="cities.map(city => city.label)" @optionSelected="onSelectCity" :initial_value="cityInitial"
                                                  label="Em qual cidade? *"/>
                                    <InputBox :form-data="alphabeticallySortedNeighborhoods"
                                             
                                              :selected-data="inputBoxValues['neighborhoods']"
                                              @updateCheckedValues="updateCheckedValues"
                                              description="Selecione um ou mais bairros"
                                              label="neighborhoods"
                                              title="Bairros *"
                                    />

                                    <InputBox
                                            :form-data="propertyTypes"
                                            @updateCheckedValues="updateCheckedValues"
                                            description="Qual o tipo do imóvel?"
                                            label="propertyType"
                                            title="Tipo de Imóvel *"
                                    />
                                    <div style="display:none;" class="input-container">
                                        <div class="double-inputs">
                                            <div class="input-set md:pl-0">
                                                <input
                                                    class="form-input forceBlurFields"
                                                    id="minArea"
                                                    
                                                    v-model="minArea"
                                                    @blur="checkArea"
                                                    @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                                    v-money="minArea === null? null : area"
                                                    />
                                                    <!--label :class="{'hide-small': !minArea}" class="form-label" for="minArea">
                                                    Área
                                                    Mínima *
                                                    </label-->
                                            </div>
                                            <div class="input-set md:pr-0">
                                                <input
                                                    class="form-input forceBlurFields"
                                                    id="maxArea"
                                                    
                                                    v-model="maxArea"
                                                    @blur="checkArea"
                                                    @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                                    v-money="maxArea === null? null: area"
                                                    />
                                                    <!--label :class="{'hide-small': !maxArea}" class="form-label" for="maxArea">
                                                    Área
                                                    Máxima *
                                                    </label-->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-container">
                                        <div class="double-inputs">
                                            <div class="input-set md:pl-0">
                                                <input class="form-input forceBlurFields"
                                                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                                       id="minValue" @blur="checkMoney" v-model.lazy="minValue"
                                                       v-money="minValue === null ? null : money" required>
                                                <label class="form-label" for="minValue"
                                                       :class="{'hide-small': !minValue}">Valor Mínimo *</label>
                                            </div>
                                            <div class="input-set md:pr-0">
                                                <input class="form-input forceBlurFields"
                                                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                                       id="maxValue" @blur="checkMoney" v-model.lazy="maxValue"
                                                       v-money="maxValue === null ? null : money" required>
                                                <label class="form-label" for="maxValue"
                                                       :class="{'hide-small': !maxValue}">Valor Máximo *</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 md:mt-32">
                                    <MultiSelect :initial-value="multiSelectValues['room_count']"
                                                 @updateMultiSelectValues="updateMultiSelectValues"
                                                 label="room_count" register="false"
                                                 title="Número de Quartos (contando Suíte, Demi-Suíte)"/>
                                    <MultiSelect :initial-value="multiSelectValues['bathroom_count']"
                                                 @updateMultiSelectValues="updateMultiSelectValues"
                                                 label="bathroom_count" register="false" title="Número de Banheiros (contando Lavabo, Banheiro Suíte, Banheiro Empregada)"/>
                                    <MultiSelect :initial-value="multiSelectValues['suite_count']"
                                                 @updateMultiSelectValues="updateMultiSelectValues"
                                                 label="suite_count" register="false" title="Número de Suítes"/>
                                    <MultiSelect :initial-value="multiSelectValues['garage_count']"
                                                 @updateMultiSelectValues="updateMultiSelectValues"
                                                 label="garage_count" register="false" title="Número de Vagas"/>
                                    <button type="button" :class="{valid: !this.$v.$invalid && formValid}"
                                            @click="sendForm">
                                        Adicionar Imóvel
                                    </button>
                                </div>
                            </form>

                            <form v-if="auto" class="row">
                                <div class="col-md-6 offset-md-3">
                                    <h1 class="modal-title">Descreva o automóvel que você estudaria como permuta</h1>
                                    <InputBox
                                            :form-data="vehicleTypes"
                                            @updateCheckedValues="updateCheckedValues"
                                            description="Selecione um Tipo de Automóvel"
                                            label="vehicleType"
                                            title="Tipo de Automóvel *"
                                            :allow-multiple="false"
                                    />
                                    <InputBox
                                            :form-data="vehicleYears"
                                            :selected-data="inputBoxValues['minYear']"
                                            @updateCheckedValues="updateCheckedValues"
                                            title="Ano Mínimo de Lançamento *"
                                            label="minYear"
                                            description="Selecione um ano mínimo de lançamento"
                                            :allow-multiple="false"
                                    />
                                    <div class="input-container">
                                        <div class="double-inputs">
                                            <div class="input-set md:pl-0">
                                                <input class="form-input forceBlurFields"
                                                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                                       id="minValue2" @blur="checkMoney" v-model="minValue"
                                                       v-money="minValue === null ? null : money" required>
                                                <label class="form-label pointer-events-none" for="minValue"
                                                       :class="{'hide-small': !minValue}">Valor Mínimo *</label>
                                            </div>
                                            <div class="input-set md:pr-0">
                                                <input class="form-input forceBlurFields"
                                                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                                       id="maxValue2" @blur="checkMoney" v-model="maxValue"
                                                       v-money="maxValue === null ? null : money" required>
                                                <label class="form-label pointer-events-none" for="maxValue"
                                                       :class="{'hide-small': !maxValue}">Valor Máximo *</label>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="mx-auto" type="button"
                                            :class="{valid: formValid && minValue !== null && maxValue !== 'R$ 0,00'}"
                                            @click="sendForm">Adicionar Automóvel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MultiSelect from "../filters/MultiSelect";
    import InputBox from "../filters/InputBox";
    import {required} from "vuelidate/lib/validators";
    import {VMoney} from 'v-money';
    import FetchCities from "../../js/mixins/fetchCities"
    import FetchPropertyTypes from "../../js/mixins/fetchPropertyTypes"
    import FetchCustomFeatures from "../../js/mixins/fetchCustomFeatures"
    import FetchVehicleTypes from "../../js/mixins/fetchVehicleTypes"
    import Autocomplete from "../filters/Autocomplete";
    import CityService from "../../js/api/CityService";
    import NeighborhoodClass from "../../js/classes/neighborhood";

    const notZero = (value) => value !== "R$ 0,00" && value !== "0m²";
    export default {
        name: 'RegisterPropertyModal',
        mixins: [FetchCities, FetchPropertyTypes, FetchCustomFeatures, FetchVehicleTypes],
        props: {
            auto: {
                type: Boolean,
                required: false,
                default: false,
            },
            propidx: null
        },
        components: {MultiSelect, InputBox, Autocomplete},
        directives: {money: VMoney},
        data() {
            return {
                formValid: false,
                inputBoxValues: {},
                multiSelectValues: {},
                vehicleYears: [],
                city: null,
                neighborhoods: null,
                minValue: null,
                maxValue: null,
                minArea: null,
                maxArea: null,
                sent: false,
                cityInitial: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    suffix: '',
                    precision: 2,
                    masked: true /* doesn't work with directive */
                },
                area: {
                    decimal: '',
                    thousands: '.',
                    prefix: '',
                    suffix: 'm²',
                    precision: 0,
                    masked: true /* doesn't work with directive */
                }
            }
        },
        validations: {
            city: {
                required
            },
            minValue: {
                required
            },
            maxValue: {
                required,
                notZero
            },
            /*minArea: {
                required
            },
            maxArea: {
                required,
                notZero
            }*/
        },
        created() {
            this.multiSelectValues['room_count'] = {min: 0, max: 0};
            this.multiSelectValues['bathroom_count'] = {min: 0, max: 0};
            this.multiSelectValues['suite_count'] = {min: 0, max: 0};
            this.multiSelectValues['garage_count'] = {min: 0, max: 0};

            const yearsCount = 50;
            const currentYear = new Date(Date.now()).getFullYear();
            //let yearsArray = [];
            for (let i = 0; i <= yearsCount; i++) {
                this.vehicleYears.push({label: (currentYear - i).toString()});
            }

            if (typeof this.inputBoxValues['minYear'] == "undefined") {
                this.inputBoxValues['minYear'] = []
            }

            this.inputBoxValues['neighborhoods'] = []
            this.inputBoxValues['propertyType'] = []

            //this.vehicleYears = yearsArray;
        },
        watch: {
            propidx: function() {
                console.log(this.propidx)
                if ( this.propidx == null || typeof this.propidx == "undefined" ) {
                    return false
                }

                console.log("é auto?", this.auto)

                var propColection = this.$store.getters.getExchangesRegisterPropertyObj['vehicle']
                if (!this.auto) {
                    propColection = this.$store.getters.getExchangesRegisterPropertyObj['property']
                }

                var propData = null

                propColection.forEach((itemCol)=>{
                    if ( itemCol.index ==  this.propidx) {
                        propData = itemCol
                    }
                })

                if ( propData == null) {
                    return false
                }

                //var propData = this.auto ? this.$store.getters.getExchangesRegisterPropertyObj['vehicle'][this.propidx] : this.$store.getters.getExchangesRegisterPropertyObj['property'][this.propidx]
                
                if (this.auto) {

                    if ( this.inputBoxValues['vehicleType'].indexOf(propData.exchange.vehicle_type) == -1 ) {
                        this.inputBoxValues['vehicleType'].push(propData.exchange.vehicle_type)
                    }
                    this.updateCheckedValues({'vehicleType':[propData.exchange.vehicle_type]})

                    if ( this.inputBoxValues['minYear'].indexOf(propData.exchange.min_year.toString()) == -1 ) {
                        this.inputBoxValues['minYear'].push(propData.exchange.min_year.toString())
                    }
                    this.updateCheckedValues({'minYear':[propData.exchange.min_year.toString()]})

                    this.minValue = propData.exchange.min_value.toFixed(2)
                    this.maxValue = propData.exchange.max_value.toFixed(2)

                } else {
                   var propInterval = setInterval(()=>{
                        if ( this.cities != null && this.cities.length > 0 && this.propertyTypes != null && this.propertyTypes.length > 0 ) {

                            clearInterval(propInterval)

                            this.cityInitial = propData.exchange.city
                            this.onSelectCity([propData.exchange.city], propData.exchange.city, ()=> {
                                this.inputBoxValues['neighborhoods'] = []
                                this.inputBoxValues['neighborhoods'].push(propData.exchange.neighborhood[0])
                                this.updateCheckedValues({'neighborhood':propData.exchange.neighborhood})
                                
                            })

                            //this.inputBoxValues['propertyType'] = []
                            if ( this.inputBoxValues['propertyType'].indexOf(propData.exchange.property_type[0]) == -1 ) {
                                this.inputBoxValues['propertyType'].push(propData.exchange.property_type[0])
                            }
                            
                            this.updateCheckedValues({'propertyType':propData.exchange.property_type})

                            this.minArea = propData.exchange.min_area.toFixed(2)
                            this.maxArea = propData.exchange.max_area.toFixed(2)

                            this.minValue = propData.exchange.min_value.toFixed(2)
                            this.maxValue = propData.exchange.max_value.toFixed(2)

                            let min_rooms = propData.exchange.feature.min_rooms
                            let max_rooms = propData.exchange.feature.max_rooms
                            this.multiSelectValues['room_count'] = {min: min_rooms, max: max_rooms};
                            this.updateMultiSelectValues({'room_count': {min: min_rooms, max: max_rooms}})

                            let max_bathrooms = propData.exchange.feature.max_bathrooms
                            let min_bathrooms = propData.exchange.feature.min_bathrooms
                            this.multiSelectValues['bathroom_count'] = {min: min_bathrooms, max: max_bathrooms};
                            this.updateMultiSelectValues({'bathroom_count': {min: min_bathrooms, max: max_bathrooms}})

                            let max_suites = propData.exchange.feature.max_suites
                            let min_suites = propData.exchange.feature.min_suites
                            this.multiSelectValues['suite_count'] = {min: min_suites, max: max_suites};
                            this.updateMultiSelectValues({'suite_count': {min: min_suites, max: max_suites}})

                            let max_garages = propData.exchange.feature.max_garages
                            let min_garages = propData.exchange.feature.min_garages
                            this.multiSelectValues['garage_count'] = {min: min_garages, max: max_garages};
                            this.updateMultiSelectValues({'garage_count': {min: min_garages, max: max_garages}})
                            
                        }
                    },10)
                }

                console.log(propData)
            }
        },
        computed: {
            alphabeticallySortedNeighborhoods() {
                if (!this.neighborhoods) {
                    return null;
                }

                return this.orderList(this.neighborhoods)
            }
        },
        methods: {
            slugify(str) {
                const map = {
                    'a': 'á|à|ã|â|À|Á|Ã|Â',
                    'e': 'é|è|ê|É|È|Ê',
                    'i': 'í|ì|î|Í|Ì|Î',
                    'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
                    'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
                    'c': 'ç|Ç',
                    'n': 'ñ|Ñ'
                };

                str = str.toLowerCase();

                for (let pattern in map) {
                    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
                }

                return str;
            },
            orderList(list) {
                if (!list) {
                    return null;
                }

                let listOrdered = list;

                listOrdered.sort((a,b) => (this.slugify(a.label) > this.slugify(b.label)) ? 1 : ((this.slugify(b.label) > this.slugify(a.label)) ? -1 : 0))

                return listOrdered;
            },
            sendForm(ev) {
                let isValid = ev.target.classList.contains('valid');
                let payload = {};

                if (isValid) {
                    if (this.auto) {
                        payload = {
                            exchange_type: "vehicle",
                            min_year: this.inputBoxValues['minYear'][0],
                            min_value: this.removeMoneyFormattingIgnoreCommas(this.minValue),
                            max_value: this.removeMoneyFormattingIgnoreCommas(this.maxValue),
                            vehicle_type: this.inputBoxValues['vehicleType'][0]
                        };
                    } else {
                        payload = {
                            exchange_type: "property",
                            max_area: this.removeAreaFormatting(this.maxArea),
                            min_area: this.removeAreaFormatting(this.minArea),
                            min_value: this.removeMoneyFormattingIgnoreCommas(this.minValue),
                            max_value: this.removeMoneyFormattingIgnoreCommas(this.maxValue),
                            property_type: this.inputBoxValues['propertyType'],
                            city: this.city.label,
                            neighborhood: this.inputBoxValues['neighborhoods'],
                            feature: {
                                property_age: 0,
                                min_rooms: this.multiSelectValues['room_count']["min"] === 0 ? -1 : this.multiSelectValues['room_count']["min"], // -1 = indiferente
                                max_rooms: this.multiSelectValues['room_count']["max"] === 0 ? -1 : this.multiSelectValues['room_count']["max"],
                                min_bathrooms: this.multiSelectValues['bathroom_count']["min"] === 0 ? -1 : this.multiSelectValues['bathroom_count']["min"],
                                max_bathrooms: this.multiSelectValues['bathroom_count']["max"] === 0 ? -1 : this.multiSelectValues['bathroom_count']["max"],
                                min_suites: this.multiSelectValues['suite_count']["min"] === 0 ? -1 : this.multiSelectValues['suite_count']["min"],
                                max_suites: this.multiSelectValues['suite_count']["max"] === 0 ? -1 : this.multiSelectValues['suite_count']["max"],
                                min_garages: this.multiSelectValues['garage_count']["min"] === 0 ? -1 : this.multiSelectValues['garage_count']["min"],
                                max_garages: this.multiSelectValues['garage_count']["max"] === 0 ? -1 : this.multiSelectValues['garage_count']["max"],
                                pet_friendly: "false",
                                furnished: "no"
                            },
                        };
                    }

                    if ( this.propidx != null && typeof this.propidx != "undefined" ) {
                        this.$store.commit('removeExchangesRegisterProperty', this.propidx)
                    } 

                    this.$store.commit("addExchangesRegisterProperty", payload);

                    
                    this.$refs.closeModalRef.click();
                }
            },

            updateCheckedValues(value) {
                console.log(value)
                const label = Object.keys(value)[0];
                this.inputBoxValues[label] = value[label];
                this.checkUpdatedCheckedValuesValid();
            },

            checkUpdatedCheckedValuesValid() {
                let required;
                if (this.auto) {
                    required = ["vehicleType", "minYear"];
                } else {
                    required = ["propertyType", "neighborhoods"];
                }

                this.formValid = required.every(requiredKey => this.inputBoxValues[requiredKey] && this.inputBoxValues[requiredKey].length > 0);
            },

            updateMultiSelectValues(value) {
                console.log("Multi val: ", value)
                const label = Object.keys(value)[0];
                this.multiSelectValues[label] = value[label];
            },

            onSelectCity(result, searchString, callback) {
                if (result.length === 1 && searchString.toString().toLowerCase() === result[0].toString().toLowerCase()) {
                    this.city = this.cities.find(city => city.label === result[0]);
                    this.fetchNeighborhoods(this.city.id, callback)
                } else {
                    this.city = null;
                }
            },

            async fetchNeighborhoods(cityId, callback) {
                try {
                    const {data} = await CityService.getById(cityId);
                    this.neighborhoods = data['data']['neighborhoods'];
                    this.neighborhoods = this.neighborhoods.map((v) => new NeighborhoodClass(v));
                    if ( callback != null && typeof callback != "undefined") {
                        callback()
                    }
                } catch (err) {
                    // console.log(err);
                }
            },

            checkMoney() {

                if (this.minValue && !this.maxValue) {
                    this.maxValue = this.minValue;
                }

                if (this.minValue && this.maxValue) {
                    const min = this.removeMoneyFormatting(this.minValue);
                    const max = this.removeMoneyFormatting(this.maxValue);

                    if (min > max) {
                        this.maxValue = this.minValue;
                    }
                }
            },

            checkArea() {
                if (this.minArea && !this.maxArea) {
                    this.maxArea = this.minArea;
                }

                if (this.minArea && this.maxArea) {
                    const min = this.removeAreaFormatting(this.minArea);
                    const max = this.removeAreaFormatting(this.maxArea);

                    if (min > max) {
                        this.maxArea = this.minArea;
                    }
                }
            },


            removeMoneyFormatting(value) {
                if (value) {
                    let newValue = value.replace("R$ ", "").replace(/\./g, "").replace(",", "");
                    return parseInt(newValue);
                }
            },

            removeMoneyFormattingIgnoreCommas(value) {
                if (value) {
                    let newValue = value.replace("R$ ", "").split(",")[0].replace(/\./g, "");
                    return parseInt(newValue);
                }

                return 0;
            },

            removeAreaFormatting(value) {
                if ( !value ) {
                    return null
                }
                let newValue = value.replace("m²", "").replace(".", "");
                return parseInt(newValue);
            }

        }
    }
</script>
