<template>
  <div class="page" v-if="isLoaded" style="height: 100%">
    <div class="modal-header">
      <a href="#/profile">
        <div class="close-button">
          <i class="icon icon-close dark-gray"></i>
        </div>
      </a>
    </div>
    <div class="content-wrapper row">
      <div class="content col-md" style="margin-top: 5%">
        <img src="@/images/identification.svg" />
      </div>
      <div class="form col-md">
        <h1>Editar dados</h1>
        <!-- <p class="form-description">Lorem ipsum dolor amet mlkshk kitsch, fashion master cleansepickled skateboard. </p> -->
        <form>
          <div class="input-set">
            <input
              class="form-input"
              id="username"
              v-model="$v.fullName.$model"
              required
            />
            <label
              class="form-label"
              for="username"
              :class="{ 'hide-small': !fullName }"
              >Nome completo</label
            >
            <p class="form-error" v-show="sent && !$v.fullName.required">
              Esse campo é obrigatório
            </p>
          </div>
          <div class="input-set">
            <input
              class="form-input"
              id="email"
              v-model="$v.email.$model"
              required
              autocapitalize="none"
            />
            <label
              class="form-label"
              for="email"
              :class="{ 'hide-small': !email }"
              >E-mail</label
            >
            <p class="form-error" v-show="sent && this.emailTaken">
              Já existe uma conta com esse e-mail
            </p>
            <p class="form-error" v-show="sent && !$v.email.required">
              Esse campo é obrigatório
            </p>
            <p class="form-error" v-show="sent && !$v.email.email">
              Insira um e-mail válido
            </p>
          </div>

          <div class="input-set">
            <input
              class="form-input"
              id="phone"
              v-model="$v.phone.$model"
              required
              type="tel"
              v-mask="['(##) #### ####', '(##) ##### ####']"
            />
            <label
              class="form-label"
              for="phone"
              :class="{ 'hide-small': !phone }"
              >Telefone</label
            >
            <p class="form-error" v-show="sent && !$v.phone.required">
              Esse campo é obrigatório
            </p>
          </div>

          <button
            :class="{ valid: !$v.$invalid }"
            v-on:click.prevent="sendForm"
          >
            Salvar edições
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../js/api/UserService";
import UserClass from "@/js/classes/user";
import { required, email } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
export default {
  name: "ResetPassword",
  directives: { mask },
  data() {
    return {
      user: UserClass,
      isLoaded: false,
      email: null,
      fullName: null,
      sent: false,
      emailTaken: false,
      phone: null,
    };
  },
  validations: {
    fullName: {
      required,
    },
    phone: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  created() {
    this.getData(localStorage.token);
  },
  methods: {
    async getData(token) {
      try {
        const { data } = await UserService.getData(token);
        this.user = new UserClass(data["data"]);
        this.isLoaded = true;
      } catch (err) {
        // console.log(err);
      }
    },
    async updateUser() {
      var user = {
        user: {
          email: this.email,
          name: this.fullName,
          phone: this.phone,
        },
      };

      var userId = this.user.id;

      try {
        await UserService.updateUser(userId, user, localStorage.token);
        this.$router.push("/profile");
      } catch (err) {
        if (
          err.response.data.errors["email"] &&
          err.response.data.errors["email"][0] === "has already been taken"
        ) {
          this.emailTaken = true;
        }
      }
    },
    sendForm() {
      this.sent = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }

      this.updateUser();
    },
  },
};
</script>
