var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"result-map"},[_c('GmapMap',{ref:"baseMap",attrs:{"center":_vm.centerPosition,"options":{styles:_vm.mapTheme, disableDefaultUI: true},"zoom":15,"id":"map"}},[_c('GmapMarker',{attrs:{"icon":{url:require('@/images/marker.png')},"position":_vm.centerPosition}}),_c('GmapCircle',{attrs:{"center":_vm.centerPosition,"options":{
                                    fillColor: "#FFCB00",
                                    fillOpacity: 0.15,
                                    strokeColor: "#FFCB00",
                                    strokeOpacity: 0.6,
                                    strokeWeight: 1
                                },"radius":_vm.radius * 1000}}),_c('GmapCircle',{attrs:{"center":_vm.centerPosition,"radius":(_vm.radius * 1000)*1.5,"options":{
                                    fillColor: "#FFCB00",
                                    fillOpacity: 0.05,
                                    strokeWeight: 0
                                }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }