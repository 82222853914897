import TypeService from "../api/TypeService";

export default {
    data() {
        return {
            propertyTypes: []
        }
    },
    created() {
        this.fetchPropertyTypes()
    },

    methods: {
        async fetchPropertyTypes() {
            try {
                const {data} = await TypeService.getPropertyTypes();
                this.propertyTypes = data['data'].sort((a,b) => a.label.localeCompare(b.label));
            } catch (err) {
                // console.log(err);
            }
        },
    }
};
