<template>
  <div class="page" style="height: 100%">
    <div class="modal-header">
      <RouterLink class="apolar-logo" :to="{ name: 'demands-page' }">
        <img src="../../images/logo.svg" width="89" />
      </RouterLink>
      <a @click="returnButton()">
        <div class="close-button">
          <i class="icon icon-close dark-gray"></i>
        </div>
      </a>
    </div>
    <div class="content-wrapper row" style="padding-top: 67px">
      <div class="content col-md" style="margin-top: 5%">
        <img src="@/images/reset-password.svg" />
      </div>
      <div class="form col-md">
        <h1>Redefinir senha</h1>
        <!-- <p class="form-description">Lorem ipsum dolor amet mlkshk kitsch, fashion master cleansepickled skateboard. </p> -->
        <form v-focusFirstOnLoad>
          <div v-show="!this.$route.query.token" class="input-set">
            <input
              class="form-input"
              :type="showCurrentPasswordType"
              id="currentPassword"
              v-model="$v.currentPassword.$model"
            />
            <label
              class="form-label"
              for="currentPassword"
              :class="{ 'hide-small': !currentPassword }"
              >Senha atual</label
            >
            <span class="reveal-password" v-on:click="toggleCurrentPassword">{{
              showCurrentPasswordText
            }}</span>
            <p class="form-error" v-show="sent && wrongCredentials">
              Credenciais incorretas. Por favor, tente novamente!
            </p>
          </div>
          <div class="input-set">
            <input
              class="form-input"
              :type="showPasswordType"
              id="password"
              v-model="$v.password.$model"
              required
              v-on:keyup.enter="nextPlease"
            />
            <label
              class="form-label"
              for="password"
              :class="{ 'hide-small': !password }"
              >Senha</label
            >
            <span class="reveal-password" v-on:click="togglePassword">{{
              showPasswordText
            }}</span>
            <p class="form-hint" v-show="$v.password.$dirty && !sent">
              Use entre 6 e 20 caracteres
            </p>
            <p class="form-error" v-show="sent && !$v.password.required">
              Esse campo é obrigatório
            </p>
            <p
              class="form-error"
              v-show="
                sent && (!$v.password.minLength || !$v.password.maxLength)
              "
            >
              Use entre 6 e 20 caracteres
            </p>
          </div>
          <div class="input-set">
            <input
              class="form-input"
              :type="showConfirmPasswordType"
              id="confirmPassword"
              v-model.lazy="$v.confirmPassword.$model"
              required
            />
            <label
              class="form-label"
              for="confirmPassword"
              :class="{ 'hide-small': !password }"
              >Confirmar senha</label
            >
            <span class="reveal-password" v-on:click="toggleConfirmPassword">{{
              showConfirmPasswordText
            }}</span>
            <p class="form-error" v-show="sent && !$v.confirmPassword.required">
              Esse campo é obrigatório
            </p>
            <p
              class="form-error"
              v-show="
                sent &&
                !$v.confirmPassword.sameAs &&
                $v.password.required &&
                $v.confirmPassword.required
              "
            >
              As senhas devem ser iguais
            </p>
          </div>
          <button
            :class="{ valid: !$v.$invalid }"
            v-on:click.prevent="sendForm"
          >
            Confirmar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LoginService from "../../js/api/LoginService";
import UserService from "../../js/api/UserService";
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import togglePasswordMixin from "../../js/mixins/togglePasswordMixin";

export default {
  name: "ResetPassword",
  mixins: [togglePasswordMixin],
  data() {
    return {
      currentPassword: null,
      password: null,
      confirmPassword: null,
      sent: false,
      wrongCredentials: false,
    };
  },
  validations: {
    currentPassword: {
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    confirmPassword: {
      required,
      sameAs: sameAs(`password`),
    },
  },
  directives: {
    focusFirstOnLoad: {
      inserted(el, binding, vnode) {
        vnode.elm[0].focus();
      },
    },
  },
  methods: {
    nextPlease: function () {
      document.getElementById("confirmPassword").focus();
    },
    async newPassword() {
      var user;

      if (localStorage.token) {
        user = {
          new_password: this.password,
        };

        try {
          await UserService.newPassword(user, localStorage.token);
          this.$router.back();
        } catch (err) {
          // console.log(err.response);
        }
      } else {
        user = {
          email: this.$route.query.m,
          new_password: this.password,
          token: this.$route.query.token,
        };

        try {
          await LoginService.newPassword(user);
          this.$router.push("/login");
        } catch (err) {
          // console.log(err);
        }
      }
    },
    returnButton() {
      if (localStorage.token) {
        this.$router.back();
      } else {
        this.$router.push("/");
      }
    },
    async login(user) {
      try {
        if (!this.$route.query.token) {
          await LoginService.login(user);
          this.wrongCredentials = false;
        }
        this.newPassword();
      } catch (err) {
        this.wrongCredentials = true;
      }
    },
    sendForm() {
      var user = {
        email: this.$route.query.email,
        password: this.currentPassword,
      };
      this.login(user);

      this.sent = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }
    },
  },
};
</script>
