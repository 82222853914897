class UserClass  {
	constructor(data){
		this.name = data.name;
		this.email = data.email;
		this.id = data.id;
		this.favorite = data.favorite;
		this.role = data.role;
		this.comparison = data.comparison;
		this.comment = data.comment;
		this.storecode = data.storecode;
	}

	initials = () => {
		const arr = this.name.split(" ");

		const firstLetter = arr[0].charAt(0);
		const lastLetter = arr[arr.length - 1].charAt(0);
		return (firstLetter + lastLetter).toUpperCase();
	};
}

export default UserClass
