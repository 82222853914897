import TypeService from "../api/TypeService";

export default {
    data() {
        return {
            customFeatures: []
        }
    },
    created() {
        this.fetchCustomFeatures()
    },

    methods: {
        async fetchCustomFeatures() {
            try {
                const {data} = await TypeService.getCustomFeatures();
                this.customFeatures = data['data'];
            } catch (err) {
                // console.log(err);
            }
        },
    }
};
