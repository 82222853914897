import CityService from "../api/CityService";
import CityClass from "../classes/city";

export default {
    data() {
        return {
            cities: []
        }
    },
    created() {
        this.fetchCities()
    },

    methods: {
        async fetchCities() {
            try {
                const {data} = await CityService.get();
                this.cities = data['data'];
                this.cities = this.cities.map((v) => new CityClass(v));
            } catch (err) {
                // console.log(err);
            }
        },
    }
};
