<template>
    <div>
        <div :class="{ 'not-favorites' : !favorites_page }" class="property-card row no-gutters" v-if="isLoaded">
            <div class="property-img col-md-5">
                <div class="property-img-header">
                    <div v-if="property.badge[0]" class="tag tag-text bg-highlight text-white"> {{ property.badge[0].label
                        }}
                    </div>
                    <div v-if="property.badge[1]" class="tag tag-text bg-white text-highlight"> {{ property.badge[1].label
                        }}
                    </div>
                </div>
                <div v-if="!favorites_page" class="property-body md:hidden relative">
                    <img :src="property.picture[0].path">
                    <div class="property-img-footer">
                        <p>{{ property.address.neighborhood }} . {{ String(Math.round(property.total_area)) }}m²</p>
                    </div>
                </div>
                <carousel :class="{ hidden : !favorites_page, 'more-photos' : (property.getFeaturedImages().length) > 5  }" class="md:flex"
                          :perPage="1"
                          paginationPosition="bottom-overlay"
                          :loop="true"
                          paginationActiveColor="#FFFFFF"
                          paginationColor="rgba(255,255,255,0.5)"
                          :paginationPadding="4"
                          :navigationEnabled="true"
                          >
                    <slide v-for="item in property.getFeaturedImages()" :key="item.id">
                        <div class="relative">
                            <img class="w-full carousel-img cursor-pointer" :src="item.path" @click="goToProperty">
                            <div class="property-img-footer">
                                <p> {{ item.room | capitalize }} </p>
                                <div v-if="favorites_page" class="favorite-footer sm:hidden">
                                    <div class="icons-wrapper" v-if="!remove_buttons" @click="compareProperty">
                                        <i class="icon icon-compare white mr-2"></i>
                                        <template v-if="!isCompared">Comparar</template>
                                        <template v-else>Remover</template>
                                    </div>
                                    <i class="icon icon-discard white"  v-if="!remove_buttons" data-toggle="modal" :data-target="`#discardModal_${property.id}`"></i>
                                    <i v-if="search_page" class="icon" :class="{'icon-favorite': !isFavorite, 'icon-heart': isFavorite, 'error': isFavorite}" @click="openModalFavoriteProperty"></i>
                                    <i v-if="!search_page" @click="unfavoriteProperty" class="icon icon-heart error ml-auto"></i>
                                </div>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div :class="{ hidden : !favorites_page }" class="property-description col-md-7 md:overflow-x-hidden md:flex">
                <div @click="goToProperty" class="cursor-pointer">
                    <p v-if="favorites_page && !search_page">Ref: {{property.reference}}</p>
                    <Relevance v-if="!favorites_page || search_page" :class="{ 'search-page' : search_page }"
                               :relevance="String(Math.round(relevance))"/>
                    <div :class="{ 'px-4' : search_page }">
                        <h1>{{ property.address.neighborhood }} . {{ String(Math.round(property.total_area)) }}m²</h1>
                        <div class="row">
                            <div class="col">
                                <h2 v-if="property.deal_type === 'Vendas'">Valor de Venda</h2>
                                <h2 v-else>Valor do Aluguel</h2>
                                <h3>
                                    R$
                                    <span class="text-lg"> {{ (property.sell_value).toLocaleString('pt-BR') }} </span>,00
                                </h3>
                            </div>
                            <div class="col" v-if="property.condominium">
                                <h2>Condomínio*</h2>
                                <h3>
                                    R$
                                    <span class="text-base"> {{ (property.splitCondominium())[0] }} </span>,{{
                                    (property.splitCondominium())[1] }}
                                </h3>
                            </div>
                        </div>
                        <div class="flex">
                            <p v-if="property.bedrooms > 0" class="mr-4"><span class="mr-1 text-base text-black"> {{ property.bedrooms }} </span>
                                Dorm.</p>
                            <p v-if="property.bathrooms > 0" class="mr-4"><span class="mr-1 text-base text-black"> {{ property.bathrooms }} </span>
                                Ban.</p>
                            <p v-if="property.suites > 0" class="mr-4"><span class="mr-1 text-base text-black"> {{ property.suites }} </span>
                                {{ property.suitesText() }} </p>
                            <p v-if="property.garages > 0" class="mr-4"><span class="mr-1 text-base text-black"> {{ property.garages }} </span>
                                {{ property.garagesText() }} </p>
                        </div>
                    </div>
                </div>
                <div class="comment-item" v-if="comment">
                    <div class="comment-info">
                        <p>
                            {{$store.state.user.name}} 
                            <span>{{new Date(comment.date).toLocaleDateString('en-GB')}}, </span>
                            <span>{{new Date(comment.date).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })}}</span>
                        </p>    
                    </div>
                    <p class="comment-data">"{{comment.comment}}"</p>
                    <a class="comment-button" data-toggle="modal" :data-target="`#commentModal_${property.id}`">
                        <i class="icon icon-edit highlight"></i>
                        Editar Comentário
                    </a>
                </div>
                <div v-if="favorites_page && !search_page" class="favorite-footer hidden md:flex">
                    <div class="icons-wrapper cursor-pointer hover:underline" @click="unfavoriteProperty">
                        <i class="icon icon-heart error mr-2"></i>
                        Remover Favorito
                    </div>
                </div>
                <div v-if="property.exchange[0] && search_page && !match" class="property-footer">
                    <p class="mb-2">Estuda bens como parte de pagamento:</p>
                    <div class="flex">
                        <div class="tag tag-text"> {{ property.firstExchange() }}</div>
                        <div v-if="property.exchange[1]" class="tag tag-number"> {{ property.otherExchanges() }}</div>
                    </div>
                </div>
                <div v-if="match" class="match-footer" @click="goToProperty">
                    <img src="../../images/bingo.svg">
                    <div>
                        <strong>Bingo!</strong>
                        <p>Estuda o seu bem como parte do negócio</p>
                    </div>
                    <i class="icon icon-arrow-right white"></i>
                </div>
                <div v-if="search_page" class="search-footer">
                    <div class="icons-wrapper" @click.stop="compareProperty">
                        <i class="icon icon-compare gray ml-0"></i>
                        <template v-if="!isCompared">Comparar</template>
                        <template v-else>Remover</template>
                    </div>
                    <div class="icons-wrapper" data-toggle="modal" :data-target="`#discardModal_${property.id}`">
                        <i class="icon icon-discard white"></i>
                        Descartar
                    </div>
                    <div class="icons-wrapper" @click="openModalFavoriteProperty">
                        <i class="icon" :class="{'icon-favorite': !isFavorite, 'icon-heart': isFavorite, 'error': isFavorite}"></i>
                        <template v-if="!isFavorite">Favoritar</template>
                        <template v-else>Remover</template>
                    </div>
                </div>
            </div>
        </div>
        <MatchModal id="matchModal"/>
        <SearchModal :id="`discardModal_${property.id}`" class="discardModal" :property_id="property.id" type="discard"/>
        <SearchModal @updateFavorite="updateFavorite" :id="`favoriteModal_${property.id}`" class="favoriteModal" :relevance="relevance" :property_id="property.id"  type="favorite"/>
        <CommentModal @updateComment="updateComment" :id="`commentModal_${property.id}`" :original_comment="comment" class="commentModal" :property_id="property.id" />
    </div>
</template>
<script>
    import Relevance from "../Relevance";
    import SearchModal from "../SearchModal";
    import MatchModal from "../MatchModal";
    import CommentModal from "../CommentModal";
    import PropertyService from "../../js/api/PropertyService";
    import PropertyClass from "@/js/classes/property";
    import {Carousel, Slide} from 'vue-carousel';
    import fetchUser from "../../js/mixins/fetchUser";
    import CompareService from "../../js/api/CompareService";
    import FavoriteService from "../../js/api/FavoriteService";
    import jQuery from 'jquery'
    import CommentService from "../../js/api/CommentService";

    export default {
        name: 'PropertyCard',
        mixins: [fetchUser],
        components: {
            Relevance,
            SearchModal,
            MatchModal,
            CommentModal,
            Carousel,
            Slide
        },
        props: {
            relevance: {
                type: Number,
                required: false,
                default: () => []
            },
            favorites_page: {
                type: Boolean,
                required: false
            },
            search_page: {
                type: Boolean,
                required: false,
                default: false
            },
            match: {
                type: Boolean,
                required: false,
                default: false
            },
            remove_buttons: {
                type: Boolean,
                default: false
            },
            property_reference: null,
            property_obj: null
        },
        data() {
            return {
                property: this.property_obj || PropertyClass,
                isLoaded: false,
                isCompared: false,
                isFavorite: false,
                comment: null
            }
        },
        created() {
            if(this.property_obj){
                this.property = new PropertyClass(this.property_obj);
                this.isLoaded = true;
            } else {
                this.getByReference(encodeURIComponent(this.property_reference));
            }

            if(this.$store.state.user && this.$store.state.user.comparison.find(comparison => comparison.property_id === this.property.id)){
                this.isCompared = true;
            }

            if(this.$store.state.user && this.$store.state.user.favorite.find(fav => fav.property_id === this.property.id)){
                this.isFavorite = true;
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                    value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        methods: {
            async getByReference(property_reference) {
                try {
                    const {data} = await PropertyService.getByReference(property_reference);
                    this.property = new PropertyClass(data['data']);
                    this.isLoaded = true;
                    if (this.$store.state.user && this.$store.state.user.comment.find(comm => comm.property_id === this.property.id)) {
                        this.comment = this.$store.state.user.comment.find(comm => comm.property_id === this.property.id);
                    }
                } catch (err) {
                    // console.log(err);
                }
            },

            goToProperty(){
                let route = this.$router.resolve({
                    name: 'property',
                    params: {reference: this.property_reference}
                });
                window.open(route.href, '_blank');
            },

            async compareProperty(){
                if(!this.$store.state.user){
                    this.$router.push({name: "login"});
                }
                if(this.isCompared){
                    const comparison = this.$store.state.user.comparison.find(comparison => comparison.property_id === this.property.id);
                    try{await CompareService.delete( comparison.id, this.$store.state.userToken);
                        this.updateUser(this.$store.state.userToken);
                        this.isCompared = false;
                    } catch (err){
                        // console.log(err.response);
                    }
                } else {
                    try{
                        await CompareService.post({property_id: this.property.id}, this.$store.state.userToken);
                        this.updateUser(this.$store.state.userToken)
                        this.isCompared = true;
                    } catch (err){
                        // console.log(err.response);
                    }
                }
            },

            updateFavorite(status) {
                this.isFavorite = status;
                this.updateUser(this.$store.state.userToken).then(() => {
                    if (this.$store.state.user && this.$store.state.user.favorite.find(fav => fav.property_id === this.property.id)) {
                        this.isFavorite = true;
                    }
                    if (this.$store.state.user && this.$store.state.user.comment.find(comm => comm.property_id === this.property.id)) {
                        this.comment = this.$store.state.user.comment.find(comm => comm.property_id === this.property.id);
                    }
                });
            },

            updateComment() {
                this.updateUser(this.$store.state.userToken).then(() => {
                    if (this.$store.state.user && this.$store.state.user.comment.find(comm => comm.property_id === this.property.id)) {
                        this.comment = this.$store.state.user.comment.find(comm => comm.property_id === this.property.id);
                    }
                });
            },

            openModalFavoriteProperty(){
                if(!this.$store.state.user){
                    this.$router.push({name: "login"});
                } else {
                    if(!this.isFavorite){
                        jQuery(`#favoriteModal_${this.property.id}`).modal();
                    } else {
                        this.unfavoriteProperty();
                    }


                }
            },

            async unfavoriteProperty(){
                const favorite = this.$store.state.user.favorite.find(fav => fav.property_id === this.property.id);
                const comment = this.$store.state.user.comment.find(comm => comm.property_id === this.property.id);
                try{
                    await FavoriteService.delete( favorite.id, this.$store.state.userToken);
                    if(comment){
                        await CommentService.delete( comment.id, this.$store.state.userToken);
                    }
                    this.updateUser(this.$store.state.userToken);
                    this.isFavorite = false;
                    if(this.remove_buttons){
                        this.$emit('updateRemoved', this.property.id);
                    }
                } catch (err){
                    // console.log(err);
                }

            }
        }
    }
</script>
