<template>
    <div class="page" v-if="isLoaded">
        <input type="hidden" id="copy-property-share" :value="window.location.href">
        <div class="main">
            <div>
                <div class="container">
                    <div class="main-icons">
                        <a class="icon-wrapper" href="#/demands">
                            <i class="icon icon-arrow-link-left white"></i>
                        </a>
                        <button class="icon-wrapper" @click="share">
                            <i class="icon icon-share white"></i>
                        </button>
                    </div>
                    <div class="main-line">
                        <small>Referência: {{demand.reference}}</small>
                        <h1>{{demand.firstName()}} busca {{demand.property_type | toSentence('ou') | toLowercase}}, {{demand.minMaxArea()}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="demand-values-features row">
                    <div class="values-features col-12 col-md-5">
                        <h1 class="on-max">Valores Buscados</h1>
                        <div class="demand-values">
                            <div class="value"><span>Valor Mínimo</span>
                                <div class="currency-display"><p class="currency-type">R$</p>
                                    <p class="currency-value">{{Math.round(demand.min_value) | currency}}</p>
                                    <p class="currency-cents">,00</p>
                                </div>
                            </div>
                            <div class="value"><span>Valor Máximo</span>
                                <div class="currency-display"><p class="currency-type">R$</p>
                                    <p class="currency-value">{{Math.round(demand.max_value) | currency}}</p>
                                    <p class="currency-cents">,00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="values-features col-12 col-md-7">
                        <h1 class="on-max">Características Buscadas</h1>
                        <div class="demand-features row">
                            <div class="feature col-6 col-lg-4 col-xl-3"><div class="icon icon-bed"></div>
                                <div><p class="feature-value">{{demand.minMaxRooms()}}</p>
                                    <p class="feature-name">Dormitórios</p></div>
                            </div>
                            <div class="feature col-6 col-lg-4 col-xl-3"><div class="icon icon-toilet"></div>
                                <div><p class="feature-value">{{demand.minMaxBathrooms()}}</p>
                                    <p class="feature-name">Banheiros</p></div>
                            </div>
                            <div class="feature col-6 col-lg-4 col-xl-3"><div class="icon icon-suite"></div>
                                <div><p class="feature-value">{{demand.minMaxSuites()}}</p>
                                    <p class="feature-name">Suítes</p></div>
                            </div>
                            <div class="feature col-6 col-lg-4 col-xl-3"><div class="icon icon-car"></div>
                                <div><p class="feature-value">{{demand.minMaxGarages()}}</p>
                                    <p class="feature-name">Vagas</p></div>
                            </div>
                        </div>
                        <div class="demand-condominium on-max">
                            <div class="demand-features">
                                <div class="feature"><div class="icon icon-pet" :class="{'light-gray': !demand.features.pet_friendly}"></div>
                                    <p class="feature-name">Pet Friendly</p></div>
                                <div class="feature"><div class="icon icon-mobiliado" :class="{'icon-mobiliado-gray': demand.features.furnished !== 'yes' && demand.features.furnished !== 'semi'}"></div>
                                    <p class="feature-name" v-if="demand.features.furnished === 'yes' || demand.features.furnished === 'no'">Mobiliado</p>
                                    <p class="feature-name" v-if="demand.features.furnished === 'semi'">Semimobiliado</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="demand-card-local">
                    <div class="local" v-if="demand.address !== null">
                        <div class="local-icon">
                            <p>{{demand.address.radius}}km</p>
                            <i class="icon icon-local"></i>
                        </div>
                        <p class="complete-address">No raio de {{demand.address.radius}}km de {{demand.fullAddress()}}</p>
                        <div class="address"><p>{{demand.fullStreet()}}</p><span>{{demand.fullCityDetails()}}</span>
                        </div>
                    </div>
                    <GoogleMapContainer  v-if="demand.address !== null" :center-position="demand.mapPos()" :radius="demand.mapRadius()" />
                </div>
                <div class="demand-condominium">

                    <div class="demand-features on-min justify-content-around">
                        <div class="feature"><div class="icon icon-pet" :class="{'light-gray': !demand.features.pet_friendly}"></div>
                            <p class="feature-name">Pet Friendly</p></div>
                        <div class="feature"><div class="icon icon-mobiliado" :class="{'icon-mobiliado-gray': demand.features.furnished !== 'yes' && demand.features.furnished !== 'semi'}"></div>
                            <p class="feature-name" v-if="demand.features.furnished === 'yes' || demand.features.furnished === 'no'">Mobiliado</p>
                            <p class="feature-name" v-if="demand.features.furnished === 'semi'">Semimobiliado</p>
                        </div>
                    </div>

                    <div class="attributes">
                        <div class="attr" v-if="demand.custom_features['property']">
                            <div class="title">
                                <i class="icon icon-home"></i>
                                <h1>Atributos desejados do imóvel</h1>
                            </div>
                            <div class="list">
                                <div class="items" :key="feat" v-for="feat in demand.custom_features['property']"><i class="icon icon-dot error"></i>{{feat}}</div>
                            </div>
                        </div>
                        <div class="attr" v-if="demand.custom_features['condominium']">
                            <div class="title">
                                <i class="icon icon-condominio"></i>
                                <h1>Atributos desejados do condomínio</h1>
                            </div>
                            <div class="list">
                                <div class="items"  :key="feat" v-for="feat in demand.custom_features['condominium']"><i class="icon icon-dot error"></i>{{feat}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="has-immobile">
                    <div class="demand-text" style="margin-top: 6%;">
                        <h1>Você tem este Imóvel?
                            <br>
                            Vamos fazer negócio
                        </h1>
                        <div class="md:w-3/4">
                            <p>Preencha o cadastro com os detalhes do seu imóvel que um dos nossos corretores entrará em
                                contato.</p>
                            <a class="primary-button cursor-pointer" @click="redirectCreateProperty">Cadastrar imóvel</a>
                        </div>
                    </div>
                    <div class="img-interested">
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DemandService from "@/js/api/DemandService";
    import DemandClass from "@/js/classes/demand";
    import GoogleMapContainer from "../../components/GoogleMapContainer";

    export default {
        name: 'demand',
        components: {GoogleMapContainer},
        data() {
            return {
                'demand': DemandClass,
                isLoaded: false,
                window: window,

            }
        },
        created() {
            this.fetch();
        },
        methods: {
            async fetch() {
                try {
                    const {data} = await DemandService.getById(this.$route.params.reference);
                    this.demand = new DemandClass(data['data']);
                    this.isLoaded = true;
                } catch (err) {
                    // console.log(err.response);
                }
            },

            redirectCreateProperty(){
                this.$router.push({ name: 'register-property', params: { demand_id: this.demand.id } })
            },

            share() {
                let inputToCopy = document.querySelector('#copy-property-share')
                inputToCopy.setAttribute('type', 'text') 
                inputToCopy.select();

                try {
                    document.execCommand('copy');
                    this.$toasted.show("Link copiado para a área de transferência")
                } catch (err) {
                    // console.log(err);
                }

                inputToCopy.setAttribute('type', 'hidden')
            },
        }
    }
</script>
