<template>
  <div class="page">
    <div>
      <div class="main" style="background-color: #C2E3EA;">
        <div class="container">
          <div class="main-icons">
            <span></span>
            <a class="close-button">
              <i class="icon icon-close white"></i>
            </a>
          </div>
          <div class="main-line">
            <h1>Organize suas Prioridades</h1>
            <p style="color: white">
              Arraste para organizar suas prioridades. Assim, buscamos os imóveis com
              <strong>
                o que é mais
                importante para você
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div class="main-desktop" style="background-color: #C2E3EA; border-color: #C2E3EA;">
        <div class="container">
          <div>
            <a
              class="main-desktop-button"
              style="cursor:pointer;"
              @click="cleanAndRedirect()"
            >Voltar à home</a>
          </div>
          <div>
            <StepCounter step-count="3" steps-done="2" />
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="complementary-info">
            <div class="complementary-info-content">
              <h2>Organize suas Prioridades</h2>
              <p>Arraste para organizar suas prioridades.</p>
              <p>
                Assim, buscamos os imóveis focando no
                <strong>
                  que é
                  mais importante para você.
                </strong>
              </p>
              <img src="@/images/ordenacao.svg" />
            </div>
          </div>
          <div class="main-info">
            <div>
              <Draggable @update="updateOrder" id="orders">
                <div
                  :key="filter"
                  class="view-search-order"
                  draggable="true"
                  v-for="(filter, i) in getFilterOrder"
                >
                  <div
                    :data-message="getFilterMessage(filter)"
                    :data-filter-name="filter"
                    class="filter_draggable"
                  >
                    <div class="position">{{i+1}}º</div>
                    <p>{{getFilterMessage(filter) | limitString(45)}}</p>
                  </div>
                </div>
              </Draggable>
            </div>
            <div class="my-8">
              <a class="primary-button" @click.prevent="sendForm">
                <div v-if="loading" class="spinner-border text-success-darker" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-else>Continuar</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepCounter from "../components/StepCounter";
import Draggable from "vuedraggable";
import { mapGetters } from "vuex";
import SearchService from "../js/api/searchService";

export default {
  name: "Order",
  components: { StepCounter, Draggable },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.updateOrder();
  },
  methods: {
    cleanAndRedirect() {
      const vuex_apolar = this.$store.state;
      vuex_apolar.searchData.dealTypeId = null;
      vuex_apolar.searchData.city = null;
      vuex_apolar.searchData.addressSearchType = "";
      vuex_apolar.searchData.neighborhoods = [];
      vuex_apolar.searchData.propertyTypes = [];
      vuex_apolar.searchData.address.address = null;
      vuex_apolar.searchData.address.number = null;
      vuex_apolar.searchData.address.radius = 300;
      vuex_apolar.searchData.address.latitude = null;
      vuex_apolar.searchData.address.longitude = null;
      vuex_apolar.searchData.address.city = null;
      vuex_apolar.searchData.address.neighborhood = null;
      vuex_apolar.searchData.minValue = null;
      vuex_apolar.searchData.maxValue = null;
      vuex_apolar.searchData.minArea = null;
      vuex_apolar.searchData.maxArea = null;
      vuex_apolar.searchData.valueRangeType = "rent";
      vuex_apolar.searchData.room_count.min = 0;
      vuex_apolar.searchData.room_count.max = 0;
      vuex_apolar.searchData.bathroom_count.min = 0;
      vuex_apolar.searchData.bathroom_count.max = 0;
      vuex_apolar.searchData.suite_count.min = 0;
      vuex_apolar.searchData.suite_count.max = 0;
      vuex_apolar.searchData.garage_count.min = 0;
      vuex_apolar.searchData.garage_count.max = 0;
      vuex_apolar.searchData.petFriendly = "";
      vuex_apolar.searchData.furnished = "";
      vuex_apolar.searchData.customFeaturesProperty = [];
      vuex_apolar.searchData.customFeaturesPropertyObj = [];
      vuex_apolar.searchData.customFeaturesCondominium = [];
      vuex_apolar.searchData.customFeaturesCondominiumObj = [];
      vuex_apolar.searchData.dealType = "Vendas";
      vuex_apolar.filtersOrder = [];
      vuex_apolar.filterMessages = [];
      vuex_apolar.searchResults = [];
      vuex_apolar.exchangesRegisterProperty = [];
      vuex_apolar.searchExchange = [];
      vuex_apolar.discardedProperties = [];
      this.$store.commit("clearSearchData", vuex_apolar);
      this.$router.push({ name: `demands-page` });
    },
    updateOrder() {
      const parentEl = document.getElementById("orders");
      const newFilterOrder = [];
      const filterMessages = [];
      Array.from(parentEl.children).forEach((el, index) => {
        el.getElementsByClassName("position")[0].textContent = `${index + 1}º`;
        newFilterOrder.push(
          el
            .getElementsByClassName("filter_draggable")[0]
            .getAttribute("data-filter-name")
        );
        filterMessages.push(
          el
            .getElementsByClassName("filter_draggable")[0]
            .getAttribute("data-message")
        );
      });
      this.$store.commit("setFilterOrder", newFilterOrder);
      this.$store.commit("setFilterMessages", filterMessages);
    },

    getFilterMessage(filter_type) {
      switch (filter_type) {
        case "neighborhood_filter": {
          let neighborhoods = this.getFilterParams(filter_type);
          return this.$options.filters.toSentence(
            neighborhoods["neighborhood"]
          );
        }

        case "within_radius_filter": {
          let address = this.getFilterParams(filter_type);
          return `${this.$options.filters.metersToKm(
            address["radius"] * 1000
          )} de ${address["address"]}`;
        }

        case "on_address_filter": {
          let address = this.getFilterParams(filter_type);
          return `Em ${address["address"]}`;
        }

        case "property_type_filter": {
          let property_types = this.getFilterParams(filter_type);
          return this.$options.filters.toSentence(
            property_types["property_type"]
          );
        }

        case "price_range_filter": {
          // let price_range = this.getFilterParams(filter_type);
          if (this.$store.state.searchData.minValue === "R$ 0,00") {
            return `Até R$ ${this.$store.state.searchData.maxValue}`;
          }
          return `De R$ ${this.$store.state.searchData.minValue} até R$ ${this.$store.state.searchData.maxValue}`;
        }

        case "area_filter": {
          // let area_range = this.getFilterParams(filter_type);
          if (this.$store.state.searchData.minArea === "0m²") {
            return `Até ${this.$store.state.searchData.maxArea}`;
          }
          return `De ${this.$store.state.searchData.minArea} até ${this.$store.state.searchData.maxArea}`;
        }

        case "bedroom_range_filter": {
          let bedroom_range = this.getFilterParams(filter_type);
          if (bedroom_range["min"] === bedroom_range["max"]) {
            let label = "quartos";
            if (bedroom_range["min"] === 1) {
              label = "quarto";
            }
            return `${bedroom_range["min"]} ${label}`;
          }
          return `De ${bedroom_range["min"]} até ${bedroom_range["max"]} quartos`;
        }

        case "bathroom_range_filter": {
          let bathroom_range = this.getFilterParams(filter_type);
          if (bathroom_range["min"] === bathroom_range["max"]) {
            let label = "banheiros";
            if (bathroom_range["min"] === 1) {
              label = "banheiro";
            }
            return `${bathroom_range["min"]} ${label}`;
          }
          return `De ${bathroom_range["min"]} até ${bathroom_range["max"]} banheiros`;
        }

        case "suite_range_filter": {
          let suite_range = this.getFilterParams(filter_type);
          if (suite_range["min"] === suite_range["max"]) {
            let label = "suítes";
            if (suite_range["min"] === 1) {
              label = "suíte";
            }
            return `${suite_range["min"]} ${label}`;
          }
          return `De ${suite_range["min"]} até ${suite_range["max"]} suítes`;
        }

        case "garage_range_filter": {
          let garage_range = this.getFilterParams(filter_type);
          if (garage_range["min"] === garage_range["max"]) {
            let label = "vagas";
            if (garage_range["min"] === 1) {
              label = "vaga";
            }
            return `${garage_range["min"]} ${label}`;
          }
          return `De ${garage_range["min"]} até ${garage_range["max"]} vagas`;
        }

        case "furnished_filter": {
          let furnished = this.getFilterParams(filter_type);
          let label = "Mobiliado";
          if (furnished["furnished"] === "semi") {
            label = "Semimobiliado";
          } else if (furnished["furnished"] === "no") {
            label = "Não mobiliado";
          }
          return `${label}`;
        }

        case "pet_friendly_filter": {
          let pet_friendly = this.getFilterParams(filter_type);
          let label = "Não pet friendly";
          if (pet_friendly["pet_friendly"]) {
            label = "Pet friendly";
          }
          return `${label}`;
        }

        default: {
          // todos os filtros feitos, sobrou custom_features
          let filter = this.getFilterParams(filter_type);
          return filter.label;
        }
      }
    },

    getFilterParams(filter_type) {
      return this.getSearchFilters.find(
        (filt) => Object.keys(filt)[0] === filter_type
      )[filter_type];
    },

    async sendForm() {
      try {
        this.loading = true;
        const { data } = await SearchService.postSearch(
          this.$store.getters.getSearchPost
        );
        this.$store.commit("setSearchResults", data["data"]);
        await this.$router.push({
          name: "result-page",
        });
      } catch (err) {
        // console.log(err);
      }
    },
  },
  computed: {
    ...mapGetters(["getSearchFilters", "getFilterOrder"]),
  },
};
</script>
