<template>
    <div class="page">
        <div class="modal-header">
            <RouterLink class="apolar-logo" :to="{ name: 'demands-page' }">
                <img src="../../images/logo.svg" width="89">
            </RouterLink>
            <a href="#/login">
                <div class="close-button">
                    <i class="icon icon-close dark-gray"></i>
                </div>
            </a>
        </div>
        <div class="content-wrapper row">
             <div class="content col-md">
                <img src="@/images/lost-password.svg">
            </div>
            <div class="form col-md">
                <h1>Esqueceu sua senha?</h1>
                <p class="form-description">Não se preocupe! Insira seu e-mail que enviaremos um link para você criar uma nova agora
                    mesmo.</p>
                <form>
                    <div class="input-set">
                        <input class="form-input" id="email" v-model.lazy="$v.email.$model" required autocapitalize="none">
                        <label class="form-label" for="email" :class="{'hide-small': !email}">E-mail</label>
                        <p class="form-error" v-show="sent && !$v.email.required">Esse campo é obrigatório</p>
                        <p class="form-error" v-show="sent && !$v.email.email">Insira um e-mail válido </p>
                    </div>
                    <button :class="{valid:!$v.$invalid}" v-on:click.prevent="sendForm">Confirmar</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import LoginService from "../../js/api/LoginService";
    import {email, required} from "vuelidate/lib/validators";
   
    export default {
        name: "ForgotPassword",
        data() {
            return {
                email: null,
                sent: false
            }
        },
        validations: {
            email: {
                required,
                email
            }
        },
        methods: {
            async forgotPassword(user) {
                try {
                    await LoginService.forgotPassword(user);                    
                    this.$router.push('/confirmation');
                } catch (err) {
                    // console.log(err.response);
                }
            },
            sendForm() {
                this.sent = true;
                this.$v.$touch();

                if (this.$v.$invalid) {
                    // console.log(`form is invalid!`);
                    return false;
                }

                var user = {
                    "email": this.email,
                }
                    
                this.forgotPassword(user);
                // console.log('form is valid!')
            }
        }
    };
</script>
