<template>
    <div :class="getStatusColor" class="dashboard-card-wrapper">
        <div class="dashboard-card collapsed md:hidden">
            <div class="row m-0">
                <div class="details-footer mb-3">
                    <div class="dashboard-card-footer mt-0">
                        <p class="dashboard-status">{{getStatusMessage}}</p>
                        <div class="icon icon-clock light-gray"></div>
                        <p class="dashboard-paragraph text-dark-gray">Gerado <strong>{{elapsedTimeString}}</strong></p>
                    </div>
                </div>
                <div v-bind:class="[demands ? 'col-md-3' : 'col-md-4', 'flex']">
                    <div v-if="demands">
                        <h1>{{getPropertyType | toSentence('ou')}} {{getMinMaxArea}}</h1>
                        <h2 v-if="getAddress !== null && getAddress.radius !==0">{{getAddress.radius * 1000 | metersToKm}} de {{getFullAddress}}</h2>
                        <h2 v-else-if="getAddress !== null">{{getFullAddress}}</h2>
                        <h2 v-else-if="demand.neighborhood !== null">{{cityLabel}} - {{demand.neighborhoodNames() | toSentence("ou")}}</h2>
                        <p class="dashboard-paragraph" v-if="!demands">Ref.: {{match.property.reference}}</p>
                    </div>
                    <div v-else>
                        <h1>{{getPropertyType | toSentence('ou')}} . {{getMinMaxArea}} . R${{match.property.sell_value | currency}}</h1>
                        <h2>{{match.property.fullAddress()}}</h2>
                        <p class="dashboard-paragraph" v-if="!demands">Ref.: {{match.property.reference}}</p>
                    </div>
                </div>
                <div class="col-md-2 border-b border-lighter-gray py-4" v-if="!demands">
                    <Relevance v-if="match.relevance" :dashboard="true" :relevance="String(Math.round(match.relevance))"/>
                    <div v-else class="relevance-badge no-relevance" data-toggle="tooltip" data-placement="top" title="Comprador acessou a página do imóvel diretamente, sem passar pela busca">
                        ?
                    </div>
                </div>
                <div v-if="demands" class="col-md-6 flex flex-col items-start">
                    <div class="demand-card-values">
                      <div class="value mr-4">
                        <span class="text-gray">Valor Mínimo</span>
                        <div class="currency-display">
                          <p class="currency-type">R$</p>
                          <p class="currency-value">{{Math.round(demand.min_value) | currency}}</p>
                          <p class="currency-cents">,00</p>
                        </div>
                      </div>
                      <div class="value mr-4">
                        <span class="text-gray">Valor Máximo</span>
                        <div class="currency-display">
                          <p class="currency-type">R$</p>
                          <p class="currency-value">{{Math.round(demand.max_value) | currency}}</p>
                          <p class="currency-cents">,00</p>
                        </div>
                      </div>
                    </div>
                    <div class="demand-card-features">
                        <div class="feature w-3/5">
                            <i class="icon icon-bed"></i>
                            <p class="feature-value">{{demand.minMaxRooms()}}</p>
                            <p class="feature-description">Dormitórios</p>
                        </div>
                        <div class="feature">
                            <i class="icon icon-suite"></i>
                            <p class="feature-value">{{demand.minMaxSuites()}}</p>
                            <p class="feature-description">Suítes</p>
                        </div>
                    </div>
                    <div class="demand-card-features">
                        <div class="feature w-3/5">
                            <i class="icon icon-toilet"></i>
                            <p class="feature-value">{{demand.minMaxBathrooms()}}</p>
                            <p class="feature-description">Banheiros</p>
                        </div>
                        <div class="feature">
                            <i class="icon icon-car"></i>
                            <p class="feature-value">{{demand.minMaxGarages()}}</p>
                            <p class="feature-description">Vagas</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 py-4">
                    <p v-if="demands" class="dashboard-paragraph mb-1">Cliente</p>
                    <p v-else class="dashboard-paragraph mb-1">Cliente Comprador</p>
                    <h1>{{getClientDetails.name}}</h1>
                    <p class="dashboard-paragraph text-dark-gray">{{getClientDetails.phone_number}} . {{getClientDetails.email}}</p>
                </div>
                <div v-if="!demands" class="col-md-3">
                    <p class="dashboard-paragraph mb-1">Cliente Vendedor</p>
                    <h1 v-if="match.property.client">{{match.property.client.name}}</h1>
                    <h1 v-else>Não especificado</h1>
                    <p v-if="match.property.client" class="dashboard-paragraph text-dark-gray">{{match.property.client.phone_number}} . {{match.property.client.email}}</p>
                </div>
                <div class="buttons-wrapper">
                    <a data-toggle="modal" :data-target="`#modal-dashboard-${getId}`">Ver Detalhes</a>
                    <DashboardModal v-if="demands" @updateInfo="updateInfo" class="modal-dashboard" :id="`modal-dashboard-${getId}`" :demand="demand" :demands="demands"/>
                    <DashboardModal v-if="!demands" @updateInfo="updateInfo" class="modal-dashboard" :id="`modal-dashboard-${getId}`" :match="match" :demands="false"/>
                </div>
            </div>
        </div>
        <a class="dashboard-card collapsed hidden md:block" data-toggle="collapse" :href="`.dashboard-card-details_${getId}`" role="button" aria-expanded="false" :aria-controls="`dashboard-card-details_${getId}`">
            <div class="row m-0">
                <div class="col-md-3">
                    <h1>{{getClientDetails.name}}</h1>
                    <h2>{{getClientDetails.phone_number}}</h2>
                    <p class="dashboard-paragraph">{{getClientDetails.email}}</p>
                </div>
                <div v-if="!demands" class="col-md-3">
                    <h1 v-if="match.property.client">{{match.property.client.name}}</h1>
                    <h1 v-else>Não especificado</h1>
                    <h2 v-if="match.property.client">{{match.property.client.phone_number}}</h2>
                    <p v-if="match.property.client" class="dashboard-paragraph">{{match.property.client.email}}</p>
                </div>
                <div v-bind:class="[demands ? 'col-md-3' : 'col-md-4', 'flex']">
                    <div v-if="!demands" class="card-property-img block" :style="{background: `url(${match.property.getFeaturedImages()[0].path})`, 'background-size': 'cover'}" ></div>
                    <div>
                        <h1 v-if="demands">{{getPropertyType | toSentence('ou')}} {{getMinMaxArea}}</h1>
                        <h1 v-else>{{getPropertyType | toSentence('ou')}} . {{getMinMaxArea}} . R${{match.property.sell_value | currency}}</h1>
                        <h2 v-if="getAddress !== null && getAddress.radius !==0">{{getAddress.radius * 1000 | metersToKm}} de {{getFullAddress}}</h2>
                        <h2 v-else-if="getAddress !== null">{{getFullAddress}}</h2>
                        <h2 v-else-if="demand.neighborhood !== null">{{cityLabel}} - {{demand.neighborhoodNames() | toSentence("ou")}}</h2>
                        <p class="dashboard-paragraph" v-if="!demands">Ref.: {{match.property.reference}}</p>
                    </div>
                </div>
                <div class="col-md-2" v-if="!demands">
                    <div v-if="match.relevance" class="relevance-badge">
                        {{ Math.round(match.relevance) }}
                        <span>%</span>
                    </div>
                    <div v-else class="relevance-badge no-relevance" data-toggle="tooltip" data-placement="top" title="Comprador acessou a página do imóvel diretamente, sem passar pela busca">
                        -
                    </div>
                </div>
                <div v-if="demands" class="col-md-6 flex items-start md:items-end">
                    <div class="demand-card-values">
                      <div class="value mr-4">
                        <span>Valor Mínimo</span>
                        <div class="currency-display">
                          <p class="currency-type">R$</p>
                          <p class="currency-value">{{Math.round(demand.min_value) | currency}}</p>
                          <p class="currency-cents">,00</p>
                        </div>
                      </div>
                      <div class="value mr-4">
                        <span>Valor Máximo</span>
                        <div class="currency-display">
                          <p class="currency-type">R$</p>
                          <p class="currency-value">{{Math.round(demand.max_value) | currency}}</p>
                          <p class="currency-cents">,00</p>
                        </div>
                      </div>
                    </div>
                    <div class="demand-card-features row">
                        <div class="feature col-6 col-lg-4 col-xl-3">
                            <p class="feature-value">{{demand.minMaxRooms()}}</p>
                            <p class="feature-description">Dormitórios</p>
                        </div>
                        <div class="feature col-6 col-lg-4 col-xl-3">
                            <p class="feature-value">{{demand.minMaxBathrooms()}}</p>
                            <p class="feature-description">Banheiros</p>
                        </div>
                        <div class="feature col-6 col-lg-4 col-xl-3">
                            <p class="feature-value">{{demand.minMaxSuites()}}</p>
                            <p class="feature-description">Suítes</p>
                        </div>
                        <div class="feature col-6 col-lg-4 col-xl-3">
                            <p class="feature-value">{{demand.minMaxRooms()}}</p>
                            <p class="feature-description">Vagas</p>
                        </div>
                    </div>
                </div>
            </div>
        </a>
        <div class="collapse" id="dashboard-card-details" :class="`dashboard-card-details_${getId}`">
            <div v-if="demands" class="details-body">
                <!-- div class="demand-features on-min">
                    <div class="feature"><i class="icon icon-pet" :class="{'light-gray': !demand.features.pet_friendly}"></i>
                        <p class="feature-name">Pet Friendly</p></div>
                    <div class="feature">
                        <i class="icon icon-mobiliado" :class="{'icon-mobiliado-gray': demand.features.furnished !== 'yes' && demand.features.furnished !== 'semi'}"></i>
                        <p class="feature-name" v-if="demand.features.furnished === 'yes' || demand.features.furnished === 'no'">Mobiliado</p>
                        <p class="feature-name" v-if="demand.features.furnished === 'semi'">Semimobiliado</p></div>
                </div -->

                <div class="attributes">
                    <div class="attr" v-if="demand.custom_features['property']">
                        <div class="title">
                            <i class="icon icon-home light-gray"></i>
                            <h1>Atributos desejados do imóvel</h1>
                        </div>
                        <div class="list">
                            <p class="items" :key="feat" v-for="feat in demand.custom_features['property']"><span class="icon icon-dot light-gray"></span>{{feat}}</p>
                        </div>
                    </div>
                    <div class="attr" v-if="demand.custom_features['condominium']">
                        <div class="title">
                            <i class="icon icon-condominio light-gray"></i>
                            <h1>Atributos desejados do condomínio</h1>
                        </div>
                        <div class="list">
                            <p class="items" :key="feat" v-for="feat in demand.custom_features['condominium']"><span class="icon icon-dot light-gray"></span>{{feat}}</p>
                        </div>
                    </div>
                </div>

                <div v-if="demands" class="demand-features">
                    <p class="name" v-if="demand.offered_exchange.length > 0">{{demand.firstName()}} oferece permuta de</p>
                    <template v-for="exchange in demand.offered_exchange">
                        <div :key="exchange.id" v-if="exchange.exchange_type === 'vehicle'">
                            <h1>{{exchange.vehicle_make}} {{exchange.vehicle_model}}</h1>
                            <div class="demand-card-values">
                                <div class="value mr-8">
                                    <span>Valor Aproximado</span>
                                    <div class="currency-display">
                                        <p class="currency-type">R$</p>
                                        <p class="currency-value">{{exchange.value | currency}}</p>
                                        <p class="currency-cents">,00</p>
                                    </div>
                                </div>
                                <div class="value">
                                    <span>Ano</span>
                                    <div class="currency-display">
                                        <p class="currency-value">{{exchange.year}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :key="exchange.id" v-if="exchange.exchange_type === 'property'">
                            <h1>{{exchange.property_type}} em {{exchange.neighborhood}}</h1>
                            <div class="demand-card-values">
                                <div class="value mr-8">
                                    <span>Valor Aproximado</span>
                                    <div class="currency-display">
                                        <p class="currency-type">R$</p>
                                        <p class="currency-value">{{exchange.value | currency}}</p>
                                        <p class="currency-cents">,00</p>
                                    </div>
                                </div>
                                <div class="value">
                                    <span>Área</span>
                                    <div class="currency-display">
                                        <p class="currency-value">{{exchange.area}}m²</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-else-if="!demands && match.offered_exchange.length > 0" class="details-body">
                <div class="first-column">
                    <p class="name" v-if="match.offered_exchange.length > 0">{{match.firstName()}} oferece permuta de</p>
                    <template v-for="exchange in match.offered_exchange">
                        <div class="mb-2" :key="exchange.id" v-if="exchange.exchange_type === 'vehicle'">
                            <h1>{{exchange.vehicle_make}} {{exchange.vehicle_model}}</h1>
                            <div class="demand-card-values">
                                <div class="value mr-8">
                                    <span>Valor Aproximado</span>
                                    <div class="currency-display">
                                        <p class="currency-type">R$</p>
                                        <p class="currency-value">{{exchange.value | currency}}</p>
                                        <p class="currency-cents">,00</p>
                                    </div>
                                </div>
                                <div class="value">
                                    <span>Ano</span>
                                    <div class="currency-display">
                                        <p class="currency-value">{{exchange.year}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2" :key="exchange.id" v-if="exchange.exchange_type === 'property'">
                            <h1>{{exchange.property_type}} em {{exchange.neighborhood}}</h1>
                            <div class="demand-card-values">
                                <div class="value mr-8">
                                    <span>Valor Aproximado</span>
                                    <div class="currency-display">
                                        <p class="currency-type">R$</p>
                                        <p class="currency-value">{{exchange.value | currency}}</p>
                                        <p class="currency-cents">,00</p>
                                    </div>
                                </div>
                                <div class="value">
                                    <span>Área</span>
                                    <div class="currency-display">
                                        <p class="currency-value">{{exchange.area}}m²</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="icon icon-arrow-cross light-gray" v-if="match.offered_exchange && match.property.exchange"></div>
                <div class="second-column">
                    <p class="name" v-if="match.property.exchange.length > 0">{{match.property.firstName()}} estuda permuta de</p>
                    <template v-for="exchange in match.property.exchange">
                        <div class="mb-2" :key="exchange.id" v-if="exchange.exchange_type === 'vehicle'">
                            <h1>{{exchange.vehicle_type}}</h1>
                            <div class="demand-card-values">
                                <div class="value mr-8">
                                    <span>Valor Mínimo</span>
                                    <div class="currency-display">
                                        <p class="currency-type">R$</p>
                                        <p class="currency-value">{{exchange.min_value | currency}}</p>
                                        <p class="currency-cents">,00</p>
                                    </div>
                                </div>
                                <div class="value mr-8">
                                    <span>Valor Máximo</span>
                                    <div class="currency-display">
                                        <p class="currency-type">R$</p>
                                        <p class="currency-value">{{exchange.max_value | currency}}</p>
                                        <p class="currency-cents">,00</p>
                                    </div>
                                </div>
                                <div class="value">
                                    <span>Ano Mínimo</span>
                                    <div class="currency-display">
                                        <p class="currency-value">{{exchange.min_year}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2" :key="exchange.id" v-if="exchange.exchange_type === 'property'">
                            <h1>{{exchange.property_type | toSentence('ou')}} em {{exchange.neighborhood.map(neighborhood => neighborhood.label) | toSentence('ou')}}</h1>
                            <div class="demand-card-values">
                                <div class="value mr-8">
                                    <span>Valor Mínimo</span>
                                    <div class="currency-display">
                                        <p class="currency-type">R$</p>
                                        <p class="currency-value">{{exchange.min_value | currency}}</p>
                                        <p class="currency-cents">,00</p>
                                    </div>
                                </div>
                                <div class="value mr-8">
                                    <span>Valor Máximo</span>
                                    <div class="currency-display">
                                        <p class="currency-type">R$</p>
                                        <p class="currency-value">{{exchange.min_value | currency}}</p>
                                        <p class="currency-cents">,00</p>
                                    </div>
                                </div>
                                <div class="value mr-8">
                                    <span>Área Mínima</span>
                                    <div class="currency-display">
                                        <p class="currency-value">{{exchange.min_area}}m²</p>
                                    </div>
                                </div>

                                <div class="value">
                                    <span>Área Máxima</span>
                                    <div class="currency-display">
                                        <p class="currency-value">{{exchange.max_area}}m²</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                  </div>
                </div>
        </div>
        <div class="details-footer hidden md:flex">
            <div class="dashboard-card-footer mt-0">
                <p class="dashboard-status">{{getStatusMessage}}</p>
                <div class="icon icon-clock light-gray"></div>
                <p class="dashboard-paragraph text-dark-gray">Gerado <strong>{{elapsedTimeString}}</strong></p>
            </div>
            <div class="buttons-wrapper" v-if="demands">
                <a v-if="demand.status !== 'finished'" @click="changeStatusDemand(getId, 'finished')" class="mr-8">Marcar como Finalizado</a>
                <a v-if="demand.status === 'answered'" @click="changeStatusDemand(getId, 'published')">Publicar Demanda</a>
                <i class="arrow-up ml-2" data-toggle="collapse" :href="`.dashboard-card-details_${getId}`"></i>
            </div>
            <div class="buttons-wrapper" v-else>
                <a v-bind:href="shareWhatsapp(match)" target="_blank" class="mr-8">Compartilhar Whatsapp</a>
                <a v-if="match.status === 'pending'" @click="changeStatusMatch(getId, 'answered')" class="mr-8">Marcar como Atendido</a>
                <a v-if="match.status !== 'finished'" @click="changeStatusMatch(getId, 'finished')" class="mr-8">Marcar como Finalizado</a>
                <i class="arrow-up ml-2" data-toggle="collapse" :href="`.dashboard-card-details_${getId}`"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import Relevance from "../../components/Relevance";
    import DashboardModal from "../../components/DashboardModal"
    import DemandService from "../../js/api/DemandService";
    import MatchService from "../../js/api/MatchService";

    export default {
        name: 'DashboardCard',
        components: {
            Relevance,
            DashboardModal
        },
        props: {
            demands: {
                type: Boolean,
                required: false,
                default: false
            },
            demand: {
                type: Object,
                required: false
            },
            match: {
                type: Object,
                required: false
            },
            cities:{
                type: Array,
                default() {
                    return {}
                }
            }
        },
        created(){
            if(this.demands){
                this.color = this.demandStatusColor[this.demand.status];
            } else {
                this.color = this.demandStatusColor[this.match.status];
            }

        },
        data() {
            return {
                color: null,
                demandStatusColor: {pending: "red", answered: "yellow", published: "blue", finished: "green"},
                demandStatusMessage: {pending: "Não Atendido", answered: "Atendido", published: "Publicado", finished: "Finalizado"},
                relevance: 27
            }
        },
        computed: {
            elapsedTimeString(){
                const date1 = this.demands ? new Date(this.demand.inserted_at) : new Date(this.match.inserted_at);
                const date2 = new Date(Date.now());
                const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
                const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
                const diffDays = Math.abs((utc2 - utc1) / (1000 * 60 * 60 * 24));
                let demandDateString = "Hoje";
                if(diffDays === 1) {
                    demandDateString = "Ontem";
                } else if (diffDays > 1) {
                    demandDateString = `Há ${diffDays} dias`;
                }
                return demandDateString;
            },

            getStatusColor(){
                if(this.demands){
                    return this.demandStatusColor[this.demand.status];
                } else {
                    return this.demandStatusColor[this.match.status];
                }
            },

            getStatusMessage(){
                if(this.demands){
                    return this.demandStatusMessage[this.demand.status];
                } else {
                    return this.demandStatusMessage[this.match.status];
                }
            },

            getClientDetails(){
                if(this.demands){
                    return this.demand.client;
                } else {
                    return this.match.client;
                }
            },

            getId(){
                if(this.demands){
                    return this.demand.id;
                } else {
                    return this.match.id;
                }
            },

            getPropertyType(){
                if (this.demands){
                    return this.demand.property_type;
                } else {
                    return this.match.property.property_type;
                }
            },

            getAddress(){
                if(this.demands){
                    return this.demand.address;
                } else {
                    return this.match.property.address;
                }
            },

            getFullAddress(){
              if(this.demands){
                  return this.demand.fullAddress();
              } else {
                  return this.match.property.fullAddress();
              }
            },

            getMinMaxArea(){
                if(this.demands){
                    return this.demand.minMaxArea();
                } else {
                    return Math.floor(this.match.property.total_area) + "m²";
                }
            },

            cityLabel: function() {
                let city = this.cities.filter(city => city['id'] == this.demand['neighborhood'][0]['city_id'])
                return city.length > 0 ? city[0]['label']: ''
            }
        },
        methods: {
            async changeStatusDemand(demandId, status){
                try {
                    await DemandService.changeStatus(demandId, status);
                    this.$emit('updateInfo');
                } catch (err) {
                    // console.log(err);
                }
            },


            async changeStatusMatch(matchId, status){
                try {
                    await MatchService.changeStatus(matchId, status);
                    this.$emit('updateInfo');
                } catch (err) {
                    // console.log(err);
                }
            },
            shareWhatsapp(match){
                let message = `%2ACliente Comprador:%2A
                %0D
                ${match.client.email ? "Email: " + match.client.email + "%0D" : ""}
                ${match.client.name ? "Nome: " + match.client.name + "%0D" : ""}
                ${match.client.phone_number ? "Telefone: " + match.client.phone_number + "%0D" : ""}
                %0D
                %2ACliente Vendedor:%2A%0D
                ${match.property.client.email ? "Email: " + match.property.client.email + "%0D" : ""}
                ${match.property.client.name ? "Nome: " + match.property.client.name + "%0D" : ""}
                ${match.property.client.phone_number ? "Telefone: "+ match.property.client.phone_number + "%0D" : ""}
                %0D
                %2AImóvel:%2A%0D
                ${match.property.property_type ? "Tipo do Imóvel: " + match.property.property_type + "%0D": ""} 
                ${match.property.fullAddress() ? "Endereço: " +  match.property.fullAddress() + "%0D" : ""} 
                ${match.property.sell_value ? "Valor: R$ " + match.property.sell_value + "%0D" : ""} 
                ${match.property.total_area ? "Área: " + match.property.total_area + "%0D" : ""}
                ${match.property.reference ? "Ref: " +  match.property.reference + "%0D" : ""} 
                `
                let linkWhatsapp = `https://api.whatsapp.com/send?text=${message}`
                return linkWhatsapp
            },


            updateInfo(){
                this.$emit('updateInfo');
            }
        }
    }
</script>
