<template>
  <div class="page">
    <div class="property-page" v-if="isLoaded">
      <div class="main-interna">
        <div class="top-interna">
          <div class="img-interna-fachada">
            <div class="main-icons">
              <a class="icon-wrapper" href="#/">
                <i class="icon icon-arrow-link-left white"></i>
              </a>
              <a class="icon-wrapper" @click="favoriteProperty">
                <i
                  class="icon icon-favorite white"
                  :class="{'icon-heart': isFavorite, 'error': isFavorite}"
                ></i>
              </a>
            </div>
            <carousel
              ref="carousel"
              :perPage="1"
              paginationPosition="bottom-overlay"
              :loop="true"
              paginationActiveColor="#FFFFFF"
              paginationColor="rgba(255,255,255,0.5)"
              :paginationPadding="4"
              :navigationEnabled="true"
            >
              <slide v-for="item in property.getFeaturedImages()" :key="item.id">
                <div class="relative">
                  <img class="w-full carousel-img" @load="imageLoaded" :src="item.path" />
                  
                  <div class="property-img-footer">
                    <p>{{ item.room | capitalize }}</p>
                  </div>
                </div>
              </slide>
            </carousel>
            <div class="white-border-bottom md:hidden"></div>
          </div>
          <div class="interna-midias hidden md:flex">
            <button class="interna-like" @click="favoriteProperty">
              <i
                class="icon icon-favorite dark-gray"
                :class="{'icon-heart': isFavorite, 'error': isFavorite}"
              ></i>
            </button>
            <button class="interna-share" @click="share">
              <i class="icon icon-share dark-gray"></i>
            </button>
            <input type="hidden" id="copy-property-share" :value="window.location.href" />
            <button class="interna-msg" data-toggle="modal" data-target="#questionModal">
              <i class="icon icon-message white"></i>
            </button>
          </div>
        </div>
        <div class="container first-container">
          <div class="sidebar md:hidden">
            <button class="interna-like" @click="favoriteProperty">
              <i
                class="icon icon-favorite dark-gray"
                :class="{'icon-heart': isFavorite, 'error': isFavorite}"
              ></i>
            </button>
            <button class="interna-share" @click="share">
              <i class="icon icon-share dark-gray"></i>
            </button>
            <input type="hidden" id="copy-property-share" :value="window.location.href" />
            <button class="interna-msg" data-toggle="modal" data-target="#questionModal">
              <i class="icon icon-message white"></i>
            </button>
          </div>
          <div class="interna-info">
            <div class="row">
              <div class="col-md-6 interna-title">
                <p class="ref">Referência: {{property.reference}}</p>
                <h1>
                  {{property.property_type}} para {{property.deal_type}} no
                  {{property.address.neighborhood}}
                </h1>
                <p class="address">
                  <i class="icon icon-mini-pin"></i>
                  <span>{{property.fullStreet()}}</span>
                </p>
              </div>
              <div v-if="relevance" class="col-md-6 interna-desc mt-0 items-center">
                <Relevance :relevance="parseInt(relevance).toString()" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="interna-type-ap-info">
                  <h1 class="title hidden md:block">Valores e Taxas</h1>
                  <div class="row">
                    <div class="value-min col mb-4">
                      <h1 v-if="property.deal_type === 'Vendas'">Valor de Venda</h1>
                      <h1 v-else>Valor do Aluguel</h1>
                      <div class="coin-num coin-num-lg">
                        <div class="value-coin">R$</div>
                        <div class="value-num">{{property.sell_value | currencyReais}}</div>
                        <div class="value-coin">,{{property.sell_value | currencyCents}}</div>
                      </div>
                    </div>

                    <div class="value-min col mb-4" v-if="property.condominium">
                      <h1>Condomínio*</h1>
                      <div class="coin-num coin-num-lg">
                        <div class="value-coin">R$</div>
                        <div class="value-num">{{property.condominium | currencyReais}}</div>
                        <div class="value-coin">,{{property.condominium | currencyCents}}</div>
                      </div>
                    </div>

                    <div class="w-100"></div>

                    <div class="value-min col" v-if="property.iptu">
                      <h1>IPTU</h1>
                      <div class="coin-num coin-num-lg">
                        <div class="value-coin">R$</div>
                        <div class="value-num">{{property.iptu | currencyReais}}</div>
                        <div class="value-coin">,{{property.iptu | currencyCents}}</div>
                      </div>
                    </div>
                    <div class="value-min col" v-if="property.fire_insurance">
                      <h1>Seguro Incêndio</h1>
                      <div class="coin-num coin-num-lg">
                        <div class="value-coin">R$</div>
                        <div class="value-num">{{property.fire_insurance | currencyReais}}</div>
                        <div class="value-coin">,{{property.fire_insurance | currencyCents}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 class="subtitle-interna">Sobre o imóvel</h1>
                <div class="about-info-imovel row">
                  <div class="about-img-info items-start col-6">
                    <i class="icon icon-area-total"></i>
                    <div>
                      <label>Área total</label>
                      <p>{{property.total_area | currency}}m²</p>
                    </div>
                  </div>
                  <div class="about-img-info items-start col-6">
                    <i class="icon icon-area-util"></i>
                    <div>
                      <label>Área Útil</label>
                      <p>{{property.useful_area | currency}}m²</p>
                    </div>
                  </div>

                  <div class="about-img-info col-6">
                    <i class="icon icon-bed"></i>
                    <p>
                      {{property.bedrooms}}
                      <label>{{property.bedroomsText()}}</label>
                    </p>
                  </div>
                  <div class="about-img-info col-6">
                    <i class="icon icon-suite"></i>
                    <p>
                      {{property.suites}}
                      <label>{{property.suitesText()}}</label>
                    </p>
                  </div>
                  <div class="about-img-info col-6">
                    <i class="icon icon-toilet"></i>
                    <p>
                      {{property.bathrooms}}
                      <label>{{property.bathroomsText()}}</label>
                    </p>
                  </div>
                  <div class="about-img-info col-6">
                    <i class="icon icon-car"></i>
                    <p>
                      {{property.garages}}
                      <label>{{property.garagesText()}}</label>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 interna-desc mt-0">
                <div class="int-description">
                  <h1>Descrição</h1>
                  <p class="description-mobile text-base" v-html="property.description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="interna-pictures">
          <div class="container">
            <div class="title-pictures">
              <h1 class="fotos-label">Fotos</h1>
              <div class="buttons-pictures hidden md:flex">
                <div class="carousel-control">
                  <div v-on:click="swipeLeft()">
                    <i class="icon icon-arrow-left darker-gray"></i>
                  </div>
                  <div v-on:click="swipeRight()">
                    <i class="icon icon-arrow-right darker-gray"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PropertyModal
            id="propertyModal"
            :photos="photos"
            :photos-description="photosDescription"
            :index="index"
          />
          <div class="hidden" data-toggle="modal" data-target="#propertyModal" ref="openModal"></div>
          <div class="photo-gallery">
            <div v-if="photos.length < 7" class="grid-container" :class="'items-' + photos.length">
              <div
                v-for="(photo, index) in photos"
                :key="`${photo}-${index}`"
                :class="'photo photo-' + index"
                data-toggle="modal"
                data-target="#propertyModal"
              >
                <img :src="photo" />
              </div>
            </div>
            <div v-if="photos.length > 6 && mod == 0" class="more-than-six-items">
              <div v-for="x in div" :key="x" class="grid-wrapper">
                <div class="grid-container items-6">
                  <div class="photo photo-0" @click="getPicture">
                    <img :src="photos[ (6 * (x-1)) + 0 ]" />
                  </div>
                  <div class="photo photo-1" @click="getPicture">
                    <img :src="photos[ (6 * (x-1)) + 1 ]" />
                  </div>
                  <div class="photo photo-2" @click="getPicture">
                    <img :src="photos[ (6 * (x-1)) + 2 ]" />
                  </div>
                  <div class="photo photo-3" @click="getPicture">
                    <img :src="photos[ (6 * (x-1)) + 3 ]" />
                  </div>
                  <div class="photo photo-4" @click="getPicture">
                    <img :src="photos[ (6 * (x-1)) + 4 ]" />
                  </div>
                  <div class="photo photo-5" @click="getPicture">
                    <img :src="photos[ (6 * (x-1)) + 5 ]" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="photos.length > 6 && mod != 0" class="more-than-six-items">
              <div class="grid-wrapper">
                <div class="grid-container items-5">
                  <div class="photo photo-0" @click="getPicture">
                    <img :src="photos[0]" />
                  </div>
                  <div class="photo photo-1" @click="getPicture">
                    <img :src="photos[1]" />
                  </div>
                  <div class="photo photo-2" @click="getPicture">
                    <img :src="photos[2]" />
                  </div>
                  <div class="photo photo-3" @click="getPicture">
                    <img :src="photos[3]" />
                  </div>
                  <div class="photo photo-4" @click="getPicture">
                    <img :src="photos[4]" />
                  </div>
                </div>
              </div>
              <div v-for="x in div" :key="x" class="grid-wrapper">
                <div v-if="x != div" class="grid-container items-6">
                  <div class="photo photo-0" @click="getPicture">
                    <img :src="photos[(6 * x) + 0]" />
                  </div>
                  <div class="photo photo-1" @click="getPicture">
                    <img :src="photos[(6 * x) + 1]" />
                  </div>
                  <div class="photo photo-2" @click="getPicture">
                    <img :src="photos[(6 * x) + 2]" />
                  </div>
                  <div class="photo photo-3" @click="getPicture">
                    <img :src="photos[(6 * x) + 3]" />
                  </div>
                  <div class="photo photo-4" @click="getPicture">
                    <img :src="photos[(6 * x) + 4]" />
                  </div>
                  <div class="photo photo-5" @click="getPicture">
                    <img :src="photos[(6 * x) + 5]" />
                  </div>
                </div>
                <div v-else class="grid-container" :class="'items-' + (mod + 1)">
                  <div
                    v-for="(y, index) in (mod + 1)"
                    :key="y"
                    :class="'photo photo-' + (y-1)"
                    @click="getPicture"
                  >
                    <img :src="photos[(4 + div + index)]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="interna-atributtes interna-atributtes-mobile">
            <div class="atributtes-imovel" v-if="property.custom_features['property']">
              <div class="title-atributtes">
                <i class="icon icon-home"></i>
                <label>Atributos do Imóvel</label>
              </div>
              <ul>
                <li v-for="feat in property.custom_features['property']" :key="feat">
                  <i class="icon icon-dot"></i>
                  <span>{{feat}}</span>
                </li>
              </ul>
            </div>
            <div class="atributtes-condominio" v-if="property.custom_features['condominium']">
              <div class="title-atributtes mt-8">
                <i class="icon icon-condominio"></i>
                <label>Atributos do Condomínio</label>
              </div>
              <ul>
                <li v-for="feat in property.custom_features['condominium']" :key="feat">
                  <i class="icon icon-dot"></i>
                  <span>{{feat}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="interna-map interna-map-mobile">
            <!-- <div class="interna-map-title">
              <h1>Explore a vizinhança</h1>
              <button
                class="button-border-yellow"
                @click="$refs.propertyMapMobile.applyTraffic()"
              >Ver Tráfego Ao Vivo</button>
            </div> -->
            <OpenStreetContainer
              ref="propertyMapMobile"
              class="-map-explorer"
              :center-position="property.mapPos()"
              :radius="property.mapRadius()"
            />
          </div>
        </div>
        <div v-if="property.exchange.length" class="container hidden md:block">
          <div class="assets">
            <div class="interna-assets">
              <div class="assets-label">
                <label>Este imóvel estuda aceitar bens como parte de pagamento</label>
              </div>
              <template v-for="exchange in property.exchange">
                <div
                  class="assets-imovel"
                  :key="exchange.id"
                  v-if="exchange.exchange_type === 'property'"
                >
                  <i class="icon icon-home white"></i>
                  <p class="-title-ic">Imóvel</p>
                  <p>
                    <span>De:</span>
                    <span class="font-bold">R$ {{exchange.min_value | currencyWithComma}}</span>
                  </p>
                  <p>
                    <span>De:</span>
                    <span class="font-bold">R$ {{exchange.max_value | currencyWithComma}}</span>
                  </p>
                </div>
                <div
                  class="assets-car"
                  :key="exchange.id"
                  v-if="exchange.exchange_type === 'vehicle'"
                >
                  <i class="icon icon-car white"></i>
                  <p class="-title-ic">Automóvel</p>
                  <p>
                    <span>De:</span>
                    <span class="font-bold">R$ {{exchange.min_value | currencyWithComma}}</span>
                  </p>
                  <p>
                    <span>De:</span>
                    <span class="font-bold">R$ {{exchange.max_value | currencyWithComma}}</span>
                  </p>
                </div>
              </template>
            </div>
            <div
              class="requeriments"
              v-if="property.requirement.percentage_of_total || property.requirement.month_minimum"
            >
              <div>
                <label>Requisitos financeiros</label>
              </div>
              <div class="-info-basic">
                <div v-if="property.requirement.percentage_of_total">
                  <label>{{property.requirement.percentage_of_total}}%</label>
                  <p>do valor do imóvel como entrada</p>
                </div>
                <div v-if="property.requirement.month_minimum">
                  <label>R$ {{property.requirement.month_minimum | currencyWithComma}}</label>
                  <p>de renda mensal comprovada</p>
                </div>
                <div class="img-turtle"></div>
              </div>
              <div class="-desc-doc" v-if="property.requirement.documentation">
                <label>Checklist de documentos</label>
                <p>
                  Veja os documentos necessários para o processo de aluguel/venda de um imóvel. Ao
                  final da lista você pode baixá-lo para te ajudar a não esquecer de nada.
                </p>
              </div>
              <div class="documents-button">
                <button data-toggle="modal" data-target="#documentModal">Ver Documentos</button>
              </div>
              <DocumentModal id="documentModal" :documents="property.requirement.documentation" />
            </div>
          </div>
        </div>
        <div v-if="property.exchange.length" class="container md:hidden">
          <div class="assets assets-mobile px-10 mt-20">
            <div class="interna-assets" v-if="property.exchange.length">
              <div class="assets-label">
                <label>Este imóvel estuda aceitar bens como parte de pagamento</label>
              </div>
              <template v-for="exchange in property.exchange">
                <div
                  class="assets-imovel flex"
                  :key="exchange.id"
                  v-if="exchange.exchange_type === 'property'"
                >
                  <i class="icon icon-home white"></i>
                  <div>
                    <p class="-title-ic">Imóvel</p>
                    <p>
                      <span>De:</span>
                      <span class="font-bold">R$ {{exchange.min_value | currencyWithComma}}</span>
                    </p>
                    <p>
                      <span>De:</span>
                      <span class="font-bold">R$ {{exchange.max_value | currencyWithComma}}</span>
                    </p>
                  </div>
                </div>
                <div
                  class="assets-car flex"
                  :key="exchange.id"
                  v-if="exchange.exchange_type === 'vehicle'"
                >
                  <i class="icon icon-car white"></i>
                  <div>
                    <p class="-title-ic">Automóvel</p>
                    <p>
                      <span>De:</span>
                      <span class="font-bold">R$ {{exchange.min_value | currencyWithComma}}</span>
                    </p>
                    <p>
                      <span>De:</span>
                      <span class="font-bold">R$ {{exchange.max_value | currencyWithComma}}</span>
                    </p>
                  </div>
                </div>
              </template>
            </div>
            <div
              class="requeriments requirements-mobile"
              v-if="property.requirement.percentage_of_total || property.requirement.month_minimum"
            >
              <div class="img-turtle my-10"></div>
              <div>
                <label>Requisitos financeiros</label>
              </div>
              <div class="-info-basic mb-10">
                <div v-if="property.requirement.percentage_of_total">
                  <label>{{property.requirement.percentage_of_total}}%</label>
                  <p>do valor do imóvel como entrada</p>
                </div>
              </div>

              <div class="-info-basic mb-10">
                <div v-if="property.requirement.month_minimum">
                  <label>R$ {{property.requirement.month_minimum | currencyWithComma}}</label>
                  <p>de renda mensal comprovada</p>
                </div>
              </div>
              <div class="-desc-doc" v-if="property.requirement.documentation">
                <label>Checklist de documentos</label>
                <p>Veja os documentos necessários para o processo de aluguel/venda de um imóvel. Ao final da lista você pode copiá-lo para te ajudar a não esquecer de nada.</p>
              </div>
              <div class="documents-button">
                <button data-toggle="modal" data-target="#documentModal">Ver Documentos</button>
              </div>
              <DocumentModal id="documentModal" :documents="property.requirement.documentation" />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="assets">
            <div class="interested">
              <div class="img-interested"></div>
              <div class="interested-contato">
                <h1>Tem interesse nesse imóvel?</h1>
                <p>Agende uma visita ou entre em contato com um de nossos corretores.</p>
                <div class="row interested-button-wrapper">
                  <div class="col-6">
                    <div class="interested-button" data-toggle="modal" data-target="#scheduleModal">
                      <i class="icon icon-agenda white"></i>
                      <p>Agendar uma visita</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="interested-button" data-toggle="modal" data-target="#questionModal">
                      <i class="icon icon-mail white"></i>
                      <p>Entrar em contato</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="container hidden lg:block">
                    <div class="interna-atributtes">
                        <div class="atributtes-imovel" v-if="property.custom_features['property']">
                            <div class="title-atributtes">
                                <i class="icon icon-home"></i>
                                <label>Atributos do Imóvel</label></div>
                            <ul>
                                <li v-for="feat in property.custom_features['property']" :key="feat"><i
                                        class="icon icon-dot"></i><span>{{feat}}</span></li>
                            </ul>
                        </div>
                        <div class="atributtes-condominio" v-if="property.custom_features['condominium']">
                            <div class="title-atributtes">
                                <i class="icon icon-condominio"></i>
                                <label>Atributos do Condomínio</label></div>
                            <ul>
                                <li v-for="feat in property.custom_features['condominium']" :key="feat"><i
                                        class="icon icon-dot"></i><span>{{feat}}</span></li>
                            </ul>
                        </div>
                    </div>
        </div>-->
        <ScheduleModal id="scheduleModal" :property_id="property.id" :relevance="relevance" :to_email="property.storeemail" />
        <QuestionModal id="questionModal" :property_id="property.id" :to_whatsapp="property.storecellphone" :to_email="property.storeemail" />
        <SearchModal
          @updateFavorite="updateFavorite"
          :id="`favoriteModal_${property.id}`"
          class="favoriteModal"
          :relevance="relevance"
          :property_id="property.id"
          type="favorite"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import Relevance from "../components/Relevance";
import PropertyModal from "../components/property/PropertyModal";
import DocumentModal from "../components/DocumentModal";
import ScheduleModal from "../components/ScheduleModal";
import QuestionModal from "../components/QuestionModal";
import PropertyService from "../js/api/PropertyService";
import PropertyClass from "../js/classes/property";
import OpenStreetContainer from "../components/OpenStreetContainer";
import FavoriteService from "../js/api/FavoriteService";
import CommentService from "../js/api/CommentService";
import fetchUser from "../js/mixins/fetchUser";
import jQuery from "jquery";
import SearchModal from "../components/SearchModal";

export default {
  name: "Property",
  mixins: [fetchUser],
  components: {
    Relevance,
    PropertyModal,
    OpenStreetContainer,
    DocumentModal,
    ScheduleModal,
    QuestionModal,
    Carousel,
    Slide,
    SearchModal
  },
  data() {
    return {
      photos: [],
      photosDescription: [],
      isLoaded: false,
      property: PropertyClass,
      relevance: null,
      index: 0,
      window: window,
      isFavorite: false,
      loadForced: false
    };
  },
  created() {
    let property = this.$store.getters.getSearchResults.find(
      prop => prop.reference === this.$route.params.reference
    );
    if (property) {
      this.relevance = property.relevance;
    }
    this.getByReference(encodeURIComponent(this.$route.params.reference));
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  methods: {
    async getByReference(property_reference) {
      try {
        const { data } = await PropertyService.getByReference(
          property_reference
        );
        this.property = new PropertyClass(data["data"]);
        this.property.description = this.property.description.replace(/(\r\n|\n|\r)/gm,"<br>")
        this.photos = this.property.getImages().map(img => img.path);
        this.photosDescription = this.property
          .getGridImages()
          .map(img => img.room);
        this.isLoaded = true;
        if (
          this.$store.state.user &&
          this.$store.state.user.favorite.find(
            fav => fav.property_id === this.property.id
          )
        ) {
          this.isFavorite = true;
        }
      } catch (err) {
        await this.$router.push({
          name: "not-found"
        });
      }
    },

    swipeLeft() {
      let content = document.querySelector(".photo-gallery");
      content.scrollLeft -= content.scrollWidth / this.div;
    },

    swipeRight() {
      let content = document.querySelector(".photo-gallery");
      content.scrollLeft += content.scrollWidth / this.div;
    },

    getPicture(ev) {
      this.index = this.photos.indexOf(ev.target.src);
      this.$refs.openModal.click();
    },

    share() {
      let inputToCopy = document.querySelector("#copy-property-share");
      inputToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      inputToCopy.select();

      try {
        document.execCommand("copy");
        this.$toasted.show("Link copiado para a área de transferência");
      } catch (err) {
        // console.log(err);
      }

      inputToCopy.setAttribute("type", "hidden");
    },

    async favoriteProperty() {
      if (!this.$store.state.user) {
        this.$router.push({ name: "login" });
      } else {
        if (!this.isFavorite) {
          jQuery(`#favoriteModal_${this.property.id}`).modal();
        } else {
          const favorite = this.$store.state.user.favorite.find(
            fav => fav.property_id === this.property.id
          );
          const comment = this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          );
          try {
            await FavoriteService.delete(
              favorite.id,
              this.$store.state.userToken
            );
            if (comment) {
              await CommentService.delete(
                comment.id,
                this.$store.state.userToken
              );
            }
            this.updateUser(this.$store.state.userToken);
            this.isFavorite = false;
          } catch (err) {
            // console.log(err);
          }
        }
      }
    },
    updateFavorite(status) {
      this.isFavorite = status;
      this.updateUser(this.$store.state.userToken).then(() => {
        if (
          this.$store.state.user &&
          this.$store.state.user.favorite.find(
            fav => fav.property_id === this.property.id
          )
        ) {
          this.isFavorite = true;
        }
        if (
          this.$store.state.user &&
          this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          )
        ) {
          this.comment = this.$store.state.user.comment.find(
            comm => comm.property_id === this.property.id
          );
        }
      });
    },

    imageLoaded() {
      if (!this.loadForced) {
        // hack to make vue carousel recalculate itself
        this.$refs.carousel.$forceUpdate();
        this.loadForced = true;
      }
    }
  },
  computed: {
    div() {
      return Math.floor(this.photos.length / 6);
    },
    mod() {
      return this.photos.length % 6;
    }
  }
};
</script>
