import Api from '../api/Api.js'

const apiPath = '/search/properties';
export default {

    postSearch(searchParams) {
        return Api.post(apiPath, {searchParams: searchParams});
    },

    getCustomFeatures(){
        return Api.get(apiPath + "/custom_features");
    },

    getDealTypes(){
        return Api.get(apiPath + "/deal_types");
    }
};