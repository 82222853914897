<template>
    <div class="modal" tabindex="-1">
        <div class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
             role="document"
             style="pointer-events: auto;">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="inner-modal">
                        <div class="close-modal" ref="closeModalFavorite" data-toggle="modal" :data-target="`#commentModal_${property_id}`">×</div>
                        <div class="modal-body-content">
                            <h3>
                                Deseja adicionar alguma anotação/observação a este favorito para você?
                            </h3>
                            <div class="input-set">
                                <input class="form-input" v-model="$v.comment.$model" required>
                                <label class="form-label md:hidden pointer-events-none"
                                       :class="{'hide-small': !comment}">Escreva um comentário</label>
                                <label class="form-label hidden md:block pointer-events-none"
                                       :class="{'hide-small': !comment}">Escreva um comentário sobre o imóvel
                                    aqui</label>
                            </div>
                            <p class="text-error mt-n2 mb-0" v-if="sent && $v.comment.$invalid">Esse campo é obrigatório</p>
                            <div class="primary-button" @click="sendForm">Salvar Anotação/Observação</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {required} from "vuelidate/lib/validators"
    import CommentService from "../js/api/CommentService";

    export default {
        name: 'CommentModal',
        props: {
            type: {
                type: String,
                required: false
            },
            property_id: {type: Number},
            original_comment: {type: Object}
        },
        data() {
            return {
                comment: null,
                sent: false
            }
        },
        validations: {
            comment: {
                required,
            },
        },

        created() {
            if(this.original_comment){
                this.comment = this.original_comment.comment;
            }
        },

        methods: {
            async sendForm(){
                this.sent = true;
                if(!this.$v.comment.$invalid){
                    if(this.original_comment){
                        await this.editComment();
                    } else {
                        await this.createComment();
                    }

                }
            },
            async editComment(){
                try{
                    await CommentService.update(this.original_comment.id, {comment: this.comment}, this.$store.state.userToken);
                    this.$refs.closeModalFavorite.click();
                    this.$emit('updateComment');
                } catch (err){
                    // console.log(err);
                }
            },

            async createComment(){
                try{
                    await CommentService.post({comment: this.comment, property_id: this.property_id}, this.$store.state.userToken);
                    this.$refs.closeModalFavorite.click();
                    this.$emit('updateComment');
                } catch (err){
                    // console.log(err);
                }
            }
        }
    }
</script>
