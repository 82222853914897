import Vue from 'vue'

Vue.filter('toSentence', function (arr, separator = "e") {
    if (!arr) {
        return '';
    }

    if (typeof arr == 'string') {
        return arr;
    }

    if (arr.length === 0) {
        return '';
    }

    if (arr.length === 1) {
        return arr[0]
    }

    if (arr.length === 2) {
        return `${arr[0]} ${separator} ${arr[1]}`;
    }

    return arr.slice(0, arr.length - 1).join(', ') + ` ${separator} ` + arr[arr.length - 1];
});

Vue.filter('toLowercase', function (str) {
    return str.toLowerCase();
});

Vue.filter('toUppercase', function (str) {
    return str.toUpperCase();
});

Vue.filter('currency', function (int) {
    return Intl.NumberFormat('pt-BR').format(int)
});

Vue.filter('currencyWithComma', function (int) {
    return Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2}).format(int);
});

Vue.filter('currencyCents', function (int) {
    const array = Intl.NumberFormat('pt-BR').format(int).split(',');
    if(array.length === 1){
        return "00"
    } else {
        return array[1];
    }
});

Vue.filter('currencyReais', function (int) {
    return Intl.NumberFormat('pt-BR').format(int).split(',')[0]
});

Vue.filter('metersToKm', function (int) {
    if (int >= 1000) {
        return int / 1000 + "km";
    }

    return int + "m";
});

Vue.filter('limitString', function (str, limit = 80, end = "...") {
    if (str.length > limit) {
        return str.slice(0, limit) + end;
    }
    return str;
});

Vue.filter('trim', function (str) {
    return str.trim();
});

Vue.filter('pluralString', function (str, singularString, pluralString) {
    if (str === 1) {
        return singularString.replace(/%VAL%/g, str);

    }
    return pluralString.replace(/%VAL%/g, str);
});

Vue.filter('exchangeFormat', function (exchange) {
    if (exchange.exchange_type === 'vehicle') {
        return `${exchange.vehicle_type} a partir de ${exchange.min_year}, entre R$ ${Intl.NumberFormat('pt-BR').format(exchange.min_value)},00 e R$${Intl.NumberFormat('pt-BR').format(exchange.max_value)},00`
    } else {
        return ` entre R$ ${Intl.NumberFormat('pt-BR').format(exchange.min_value)},00 e R$${Intl.NumberFormat('pt-BR').format(exchange.max_value)},00`
    }
});
