<template>
    <div class="demand-card">
        <div class="demand-card-main">
            <h1 style="font-size: 30px;">Você tem um Imóvel?</h1>
            <h1 style="font-size: 30px;">Vamos fazer negócio</h1>
            <br/>
            <h2>Preencha o cadastro com os detalhes do seu imóvel </h2>
            <h2>que um dos nossos corretores entrará em contato. </h2>
        </div>
        <div class="demand-card-footer">
            <div class="col-md-4">
                <a class="primary-button" style="color:white" @click="$router.push('/register-property')">Cadastrar Imóvel</a>
            </div>
        </div>
    </div>
</template>

<script>
    import DemandClass from "../../js/classes/demand";

    export default {
        name: 'DemandCard',
        props: {
            'demand': DemandClass
        },
    }

</script>
