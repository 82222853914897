import MatchClass from "../classes/match";
import MatchService from "../api/MatchService";

export default {
  data() {
    return {
      matches: [],
      loading: false
    };
  },
  created() {
    this.fetchMatches();
  },

  methods: {
    async fetchMatches() {
      let user = this.$store.state.user
      let user_token = localStorage.token
      this.loading = true;
      try {
        const { data } = await MatchService.get("",user_token);
        this.matches = data["data"];
        if (user.storecode){ 
          //foi movido a logica para o backend, para realizar osfiltros diretamente na query
          /*if (user.storecode != 1){
            this.matches = this.matches.filter(match => match.property.storecode === user.storecode)
          }*/
          this.matches = this.matches.map((v) => new MatchClass(v));
        } else {
          this.matches = []
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    }
  }
};
