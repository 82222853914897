import Api from '../api/Api.js'

const registerPath = 'sign_up';
const loginPath = 'sign_in';
const forgotPasswordPath = 'request_password_reset';
const newPasswordPath = 'reset_password';

export default {
	register(user) {
		return Api.post(registerPath, {
			"user": user
		});
	},
	login(user) {
		return Api.post(loginPath, user);
	},
	forgotPassword(user) {
		return Api.post(forgotPasswordPath, user);
	},
	newPassword(user) {
		return Api.post(newPasswordPath, user);
	},
};