<template>
    <div class="page">
        <div class="page-header md:hidden">
            <a @click="$router.push({name: 'search-filters'})" class="close-button">
                <i class="icon icon-arrow-link-left dark-gray"></i>
            </a>
        </div>
        <div class="page-header hidden md:flex">
            <div class="container">
                <a class="main-desktop-button cursor-pointer" @click="$router.push({name: 'search-filters'})">Voltar aos
                    Filtros</a>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="first-column col-md-6">
                        <div class="content-wrapper">
                            <div class="row hidden">
                                <div class="col-md-4">
                                    <div class="advantages-cards active">
                                        Lorem Ipsum vantagens da permuta
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="advantages-cards">
                                        <img src="@/images/illustration.svg">
                                        1. Pague menos Imposto de Renda sobre o negócio
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="advantages-cards">
                                        <img src="@/images/illustration.svg">
                                        1. Pague menos Imposto de Renda sobre o negócio
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="advantages-cards">
                                        <img src="@/images/illustration.svg">
                                        2. Has ex high ceilings persecuti
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="advantages-cards">
                                        <img src="@/images/illustration.svg">
                                        3. Has ex high ceilings persecuti
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="advantages-cards">
                                        <img src="@/images/illustration.svg">
                                        3. Has ex high ceilings persecuti
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h1>
                                    <font-awesome-icon data-toggle="modal" data-target="#permutaModal" class="info-permuta" icon="info-circle" />
                                    Descreva o que quer oferecer</h1>
                                <p>
                                    Informe as principais características do bem que você tem para oferecer como permuta nesse negócio.
                                </p>
                            </div>
                        </div>
                    </div>

                    <PermutaModal id="permutaModal" />

                    <div class="second-column col-md-6">
                        <form class="main-info">
                            <div class="switch">
                                <a :class="{active: !auto}" @click="auto = false">
                                    Tenho um Imóvel
                                    <i></i>
                                </a>
                                <a :class="{active: auto}" @click="auto = true">
                                    Tenho um Automóvel
                                    <i></i>
                                </a>
                            </div>
                            <div v-if="!auto">
                                <Autocomplete :items="cities.map(city => city.label)" @optionSelected="onSelectCity"
                                              :initial_value="cityInitial"
                                              label="Em qual cidade? *"/>
                                <p class="form-error ml-2" v-if="sent && $v.city.$invalid">Insira a cidade</p>
                                <InputBox :form-data="alphabeticallySortedNeighborhoods"
                                          
                                          :selected-data="inputBoxValues['neighborhood']"
                                          @updateCheckedValues="updateCheckedValues"
                                          description="Em que está bairro o imóvel?"
                                          label="neighborhood"
                                          title="Bairro *"
                                          :allow-multiple="false"
                                />
                                <p class="form-error ml-2" v-if="sent && inputBoxValues['neighborhood'].length === 0">
                                    Insira o bairro</p>
                                <InputBox
                                        :form-data="propertyTypes"
                                        @updateCheckedValues="updateCheckedValues"
                                        description="Qual o tipo do imóvel?"
                                        label="propertyType"
                                        title="Tipo de Imóvel *"
                                        :allow-multiple="false"
                                />
                                <p class="form-error ml-2" v-if="sent && inputBoxValues['propertyType'].length === 0">
                                    Insira o tipo de imóvel</p>
                                <!--div class="input-set">
                                    <input class="form-input forceBlurFields" id="area" v-model="totalArea"
                                           v-money="totalArea === null ? null : area"
                                           >
                                    <label class="form-label" for="area" :class="{'hide-small': !area}">Metragem *</label>
                                </div>
                                <p class="form-error ml-2" v-if="sent && $v.totalArea.$invalid">Insira a metragem</p -->
                                <div class="input-wrapper">
                                    <div class="input-set">
                                        <input class="form-input forceBlurFields" id="property_price" v-model="property_price"
                                               v-money="property_price === null ? null : money"
                                               required>
                                        <label class="form-label" for="property_price" :class="{'hide-small': !price}">Valor
                                            Aproximado *</label>
                                    </div>
                                    <p class="form-error ml-2" v-if="sent && $v.property_price.$invalid">Insira o valor
                                        aproximado</p>
                                   <!--  <div class=progress"progress">
                                        <div class="progress-bar" role="progressbar" style="width: 80%;"
                                             aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                            <div class="progress-ball"></div>
                                        </div>
                                    </div> -->
                                    <div class="input-description"
                                         v-if="selectedNeighborhood !== null && selectedNeighborhood.square_meter_value > 0">
                                        <i class="icon icon-information success"></i>
                                        No {{selectedNeighborhood.label}}, o m² médio está em <strong class="ml-1">R$
                                        {{selectedNeighborhood.square_meter_value | currencyWithComma}}</strong>
                                    </div>
                                </div>
                                <MultiSelect :initial-value="multiSelectValues['room_count']"
                                             :single-select="true"
                                             @updateMultiSelectValues="updateMultiSelectValues"
                                             :selectedValues="multiSelectValues['room_count']"
                                             label="room_count" register="true"
                                             title="Número de Quartos (contando Suíte, Demi-Suíte)"/>
                                <MultiSelect :initial-value="multiSelectValues['bathroom_count']"
                                             :single-select="true"
                                             @updateMultiSelectValues="updateMultiSelectValues"
                                             label="bathroom_count" register="true" title="Número de Banheiros (contando Lavabo, Banheiro Suíte, Banheiro Empregada)"/>
                                <MultiSelect :initial-value="multiSelectValues['suite_count']"
                                             :single-select="true"
                                             @updateMultiSelectValues="updateMultiSelectValues"
                                             label="suite_count" register="true" has-zero="true"
                                             title="Número de Suítes"/>
                                <MultiSelect :initial-value="multiSelectValues['garage_count']"
                                             :single-select="true"
                                             @updateMultiSelectValues="updateMultiSelectValues"
                                             label="garage_count" register="true" has-zero="true"
                                             title="Número de Vagas"/>
                            </div>
                            <div v-if="auto">
                                <InputBox
                                        :form-data="vehicleTypes"
                                        :selected-data="inputBoxValues['vehicleType']"
                                        @updateCheckedValues="updateCheckedValues"
                                        label="vehicleType"
                                        title="Tipo de Automóvel *"
                                        :allow-multiple="false"
                                        description="Selecione o tipo de automóvel"
                                />
                                <p class="form-error ml-2" v-if="sent &&  inputBoxValues['vehicleType'].length === 0">
                                    Insira o tipo de automóvel</p>
                                <InputBox
                                        :form-data="marcas"
                                        :selected-data="inputBoxValues['marca']"
                                        @updateCheckedValues="updateCheckedValues"
                                        label="marca"
                                        title="Fabricante *"
                                        :allow-multiple="false"
                                        description="Qual o fabricante do automóvel?"
                                />
                                <p class="form-error ml-2" v-if="sent &&  inputBoxValues['marca'].length === 0">
                                    Insira o fabricante</p>
                                <InputBox
                                        :form-data="modelos"
                                        :selected-data="inputBoxValues['modelo']"
                                        @updateCheckedValues="updateCheckedValues"
                                        label="modelo"
                                        title="Modelo *"
                                        :allow-multiple="false"
                                        description="Qual o modelo do automóvel?"
                                />
                                <p class="form-error ml-2" v-if="sent &&  inputBoxValues['modelo'].length === 0">
                                    Insira o modelo</p>
                                <InputBox
                                        :form-data="ano"
                                        :selected-data="inputBoxValues['ano']"
                                        @updateCheckedValues="updateCheckedValues"
                                        label="ano"
                                        title="Ano *"
                                        :allow-multiple="false"
                                        description="Qual o ano do automóvel?"
                                />
                                <p class="form-error ml-2" v-if="sent &&  inputBoxValues['ano'].length === 0">
                                    Insira o ano</p>
                                <div class="input-wrapper">
                                    <div class="input-set">
                                        <input class="form-input forceBlurFields" id="price" v-model="price" v-money="price === null? null : money"
                                               required>
                                        <label class="form-label" for="price" :class="{'hide-small': !price}">Valor
                                            Aproximado *</label>
                                    </div>
                                    <p class="form-error ml-2" v-if="sent && $v.price.$invalid">Insira o valor
                                        aproximado</p>
                                    <div class="input-description" v-if="fipeValue">
                                        <i class="icon icon-information success"></i>
                                        <strong class="mr-1">{{fipeValue["Valor"]}}</strong> pela Tabela Fipe
                                    </div>
                                </div>
                            </div>
                            <button :class="{valid: true}" type="button" v-on:click.prevent="sendForm">Continuar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MultiSelect from "../components/filters/MultiSelect";
    import InputBox from "../components/filters/InputBox";
    import {required} from "vuelidate/lib/validators";
    import {VMoney} from 'v-money';
    import FetchCities from "../js/mixins/fetchCities"
    import FetchPropertyTypes from "../js/mixins/fetchPropertyTypes"
    import FetchCustomFeatures from "../js/mixins/fetchCustomFeatures"
    import FetchVehicleTypes from "../js/mixins/fetchVehicleTypes"
    import Autocomplete from "../components/filters/Autocomplete";
    import CityService from "../js/api/CityService";
    import NeighborhoodClass from "../js/classes/neighborhood";
    import FipeService from "../js/api/FipeService";
    import PermutaModal from "../components/PermutaModal";

    export default {
        name: "Exchange",
        components: {MultiSelect, InputBox, Autocomplete, PermutaModal},
        mixins: [FetchCities, FetchPropertyTypes, FetchCustomFeatures, FetchVehicleTypes],
        directives: {money: VMoney},
        data() {
            return {
                auto: false,
                price: null,
                property_price: null,
                totalArea: null,
                city: null,
                cityInitial: "",
                sent: false,
                neighborhoods: null,
                selectedNeighborhood: null,
                inputBoxValues: {},
                multiSelectValues: {},
                formValid: false,
                tabelaReferencia: 252, // TODO pegar automaticamente
                marcas: null,
                modelos: null,
                ano: null,
                vehicleTypeSelected: null,
                marcaSelected: null,
                modeloSelected: null,
                anoSelected: null,
                fipeValue: null,
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    suffix: '',
                    precision: 2,
                    masked: true /* doesn't work with directive */
                },
                area: {
                    decimal: '',
                    thousands: '.',
                    prefix: '',
                    suffix: 'm²',
                    precision: 0,
                    masked: true /* doesn't work with directive */
                }
            }
        },
        validations: {
            city: {
                required
            },
            price: {
                required
            },
            property_price: {
                required
            }
            /*totalArea: {
                required
            }*/
        },
        created() {
            this.fetchCodigoFipe();
            this.inputBoxValues['propertyType'] = [];
            this.inputBoxValues['neighborhood'] = [];
            this.inputBoxValues['vehicleType'] = [];
            this.inputBoxValues['marca'] = [];
            this.inputBoxValues['modelo'] = [];
            this.inputBoxValues['ano'] = [];
            this.multiSelectValues['room_count'] = {min: 1, max: 1};
            this.multiSelectValues['bathroom_count'] = {min: 1, max: 1};
            this.multiSelectValues['suite_count'] = {min: 0, max: 0};
            this.multiSelectValues['garage_count'] = {min: 0, max: 0};

            //possibilita a alteraçnao de dados
            if ( this.$store.getters.getSearchExchange.length > 0 ) {
                this.populateToEdit()
            }


        },
        computed: {
            alphabeticallySortedNeighborhoods() {
                if (!this.neighborhoods) {
                    return null;
                }

                return this.orderList(this.neighborhoods)
            }
        },
        methods: {
            slugify(str) {
                const map = {
                    'a': 'á|à|ã|â|À|Á|Ã|Â',
                    'e': 'é|è|ê|É|È|Ê',
                    'i': 'í|ì|î|Í|Ì|Î',
                    'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
                    'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
                    'c': 'ç|Ç',
                    'n': 'ñ|Ñ'
                };

                str = str.toLowerCase();

                for (let pattern in map) {
                    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
                }

                return str;
            },
            orderList(list) {
                if (!list) {
                    return null;
                }

                let listOrdered = list;

                listOrdered.sort((a,b) => (this.slugify(a.label) > this.slugify(b.label)) ? 1 : ((this.slugify(b.label) > this.slugify(a.label)) ? -1 : 0))

                return listOrdered;
            },
            updateCheckedValues(value, callback) {
                console.log("on check value", value)
                const label = Object.keys(value)[0];
                this.inputBoxValues[label] = value[label];
                if (label === "neighborhood") {
                    if (value[label].length > 0) {
                        this.selectedNeighborhood = this.neighborhoods.find(neighborhood => neighborhood.label === value[label][0])
                    } else {
                        this.selectedNeighborhood = null;
                    }
                } else {
                    this.fipeSteps(label, value[label][0], callback);
                }
                this.checkUpdatedCheckedValuesValid();
            },

            checkUpdatedCheckedValuesValid() {
                let required;
                if (this.auto) {
                    required = ["vehicleType", "modelo", "marca", "ano"];
                } else {
                    required = ["propertyType", "neighborhood"];
                }

                this.formValid = required.every(requiredKey => this.inputBoxValues[requiredKey] && this.inputBoxValues[requiredKey].length > 0);
            },

            objectKeysToLowerCase(obj) {
                let key, keys = Object.keys(obj);
                let n = keys.length;
                const newobj = {};
                while (n--) {
                    key = keys[n];
                    newobj[key.toLowerCase()] = obj[key];
                }
                return newobj
            },

            async fipeSteps(label, value, callback) {
                if (label === "vehicleType") {
                    const vehicleType = this.vehicleTypes.find(type => type.label === value);
                    this.vehicleTypeSelected = vehicleType;
                    if (vehicleType) {
                        try {
                            const {data} = await FipeService.getConsultarMarcas({codigoTabelaReferencia: this.tabelaReferencia, codigoTipoVeiculo: this.vehicleTypeSelected['fipe_code']});
                            const lowercaseResponse = data['data'].map(obj => this.objectKeysToLowerCase(obj))
                            this.marcas = lowercaseResponse;
                            this.modelos = [];
                            this.ano = []
                            this.fipeValue = null;
                        } catch (err) {
                            this.marcas = null;
                            console.log(err);
                        }
                    }
                } else if (label === "marca" && value){
                    const marca = this.marcas.find(type => type.label === value);
                    this.marcaSelected = marca;
                    if(marca){
                        try {
                            const {data} = await FipeService.getConsultarModelos({codigoTabelaReferencia: this.tabelaReferencia, codigoTipoVeiculo: this.vehicleTypeSelected['fipe_code'], codigoMarca: this.marcaSelected['value']});
                            const lowercaseResponse = data["data"]["Modelos"].map(obj => this.objectKeysToLowerCase(obj));
                            this.modelos = lowercaseResponse;
                            this.ano = [];
                            this.fipeValue = null;
                        } catch (err) {
                            this.modelos = null;
                            // console.log(err);
                        }
                    }
                } else if (label === "modelo" && value){
                    const modelo = this.modelos.find(type => type.label === value);
                    this.modeloSelected = modelo;
                    if(modelo){
                        try {
                            const {data} = await FipeService.getConsultarAnoModelo({codigoTabelaReferencia: this.tabelaReferencia, codigoTipoVeiculo: this.vehicleTypeSelected['fipe_code'], codigoMarca: this.marcaSelected['value'], codigoModelo: this.modeloSelected['value']});
                            const lowercaseResponse = data["data"].map(obj => this.objectKeysToLowerCase(obj));
                            this.ano = lowercaseResponse;
                            this.fipeValue = null;
                        } catch (err) {
                            this.ano = null;
                            // console.log(err);
                        }
                    }
                } else if (label === "ano" && value){
                    const ano = this.ano.find(type => type.label.indexOf(value) !== -1);
                    this.anoSelected = ano;
                    // console.log(this.anoSelected);
                    if(ano){
                        try {
                            const anoVal = this.anoSelected['value'];
                            const anoModelo = anoVal.split('-')[0]
                            const codigoCombustivel = anoVal.split('-')[1]
                            const {data} = await FipeService.getValor({codigoTabelaReferencia: this.tabelaReferencia, codigoTipoVeiculo: this.vehicleTypeSelected['fipe_code'], codigoMarca: this.marcaSelected['value'], codigoModelo: this.modeloSelected['value'], ano: anoVal, anoModelo: anoModelo, codigoTipoCombustivel: codigoCombustivel, tipoConsulta: 'tradicional'});
                            const lowercaseResponse = data["data"];
                            this.fipeValue = lowercaseResponse;
                        } catch (err) {
                            this.ano = null;
                            // console.log(err);
                        }
                    }
                }
                if (callback != null && typeof callback != "undefined") {
                    callback()
                }
            },

            async fetchCodigoFipe(){
                try {
                    const {data} = await FipeService.getTabelaReferencia({});
                    this.tabelaReferencia = data['data'][0]["Codigo"];

                } catch (err) {
                    // console.log(err);
                }
            },

            updateMultiSelectValues(value) {
                console.log("mult", value)
                const label = Object.keys(value)[0];
                this.multiSelectValues[label] = value[label];
            },
            onSelectCity(result, searchString, callback) {
                console.log(result, searchString)
                if (searchString.toString().toLowerCase() === result[0].toString().toLowerCase()) {
                    this.city = this.cities.find(city => city.label === result[0]);
                    this.fetchNeighborhoods(this.city.id, callback)
                } else {
                    this.city = null;
                }
                
            },
            async fetchNeighborhoods(cityId, callback) {
                try {
                    const {data} = await CityService.getById(cityId);
                    this.neighborhoods = data['data']['neighborhoods'];
                    this.neighborhoods = this.neighborhoods.map((v) => new NeighborhoodClass(v));

                    if (callback != null && typeof callback != "undefined") {
                        callback()
                    }
                } catch (err) {
                    // console.log(err);
                }
            },

            sendForm() {
                this.sent = true;

                if(this.auto && !this.$v.price.$invalid && this.formValid){
                    let payload = {
                        exchange_type: "vehicle",
                        year: this.anoSelected['value'].split('-')[0],
                        value: this.removeMoneyFormattingIgnoreCommas(this.price),
                        vehicle_type_id: this.vehicleTypeSelected['id'],
                        vehicle_type_label: this.vehicleTypeSelected['label'],
                        vehicle_make: this.marcaSelected['label'],
                        vehicle_model: this.modeloSelected['label']
                    };
                    this.$store.commit("addSearchExchange", payload);
                    this.$router.push({
                        name: 'search-filters'
                    });
                } else if (!this.auto && !this.$v.property_price.$invalid /*&& !this.$v.totalArea.$invalid*/ && this.formValid){
                    let payload = {
                        exchange_type: "property",
                        //area: this.removeAreaFormatting(this.totalArea),
                        area: 0,
                        value: this.removeMoneyFormattingIgnoreCommas(this.property_price),
                        property_type_id: this.propertyTypes.find(type => type.label === this.inputBoxValues['propertyType'][0])['id'],
                        property_type_label: this.propertyTypes.find(type => type.label === this.inputBoxValues['propertyType'][0])['label'],
                        city_id: this.city.id,
                        city_label: this.city.label,
                        neighborhood_id: this.neighborhoods.find(type => type.label === this.inputBoxValues['neighborhood'][0])['id'],
                        neighborhood_label: this.neighborhoods.find(type => type.label === this.inputBoxValues['neighborhood'][0])['label'],
                            rooms: this.multiSelectValues['room_count']["min"],
                            bathrooms: this.multiSelectValues['bathroom_count']["min"],
                            suites: this.multiSelectValues['suite_count']["min"],
                            garages: this.multiSelectValues['garage_count']["min"],
                            pet_friendly: false,
                            furnished: 'no'
                    };
                    this.$store.commit("addSearchExchange", payload);
                    this.$router.push({
                        name: 'search-filters'
                    });
                }
            },


            removeMoneyFormattingIgnoreCommas(value) {
                if(value){
                    let newValue = value.replace("R$ ", "").split(",")[0].replace(/\./g, "");
                    return parseInt(newValue);
                }

                return 0;
            },

            removeAreaFormatting(value) {
                let newValue = value.replace("m²", "").replace(".", "");
                return parseInt(newValue);
            },

            populateToEdit() {
                this.auto = this.$store.getters.getSearchExchange[0].exchange_type == "vehicle" ? true : false
            
                if (this.auto) {
                    var autoInterval = setInterval(()=>{
                        if (this.vehicleTypes != null && this.vehicleTypes.length > 0) {
                            clearInterval(autoInterval)
                            this.inputBoxValues['vehicleType'].push(this.$store.getters.getSearchExchange[0].vehicle_type_label)
                            this.updateCheckedValues({'vehicleType':[this.$store.getters.getSearchExchange[0].vehicle_type_label]},()=>{
                                this.updateCheckedValues({'marca':[this.$store.getters.getSearchExchange[0].vehicle_make]}, ()=>{
                                    this.updateCheckedValues({'modelo':[this.$store.getters.getSearchExchange[0].vehicle_model]}, ()=>{
                                        this.updateCheckedValues({'ano':[this.$store.getters.getSearchExchange[0].year]})
                                    })
                                })
                            })
                            this.price = this.$store.getters.getSearchExchange[0].value.toFixed(2)
                        }
                    },10)

                } else {
                    var propInterval = setInterval(()=>{
                        if ( this.cities != null && this.cities.length > 0 && this.propertyTypes != null && this.propertyTypes.length > 0 ) {

                            clearInterval(propInterval)

                            this.cityInitial = this.$store.getters.getSearchExchange[0].city_label
                            this.onSelectCity([this.$store.getters.getSearchExchange[0].city_label], this.$store.getters.getSearchExchange[0].city_label, ()=> {
                                this.updateCheckedValues({'neighborhood':[this.$store.getters.getSearchExchange[0].neighborhood_label]})
                                
                            })
                            this.inputBoxValues['propertyType'].push(this.$store.getters.getSearchExchange[0].property_type_label)
                            this.updateCheckedValues({'propertyType':[this.$store.getters.getSearchExchange[0].property_type_label]})

                            this.totalArea = this.$store.getters.getSearchExchange[0].area
                            this.property_price = this.$store.getters.getSearchExchange[0].value.toFixed(2)

                            let rooms = this.$store.getters.getSearchExchange[0].rooms
                            this.multiSelectValues['room_count'] = {min: rooms, max: rooms};
                            this.updateMultiSelectValues({'room_count': {min: rooms, max: rooms}})

                            let bathrooms = this.$store.getters.getSearchExchange[0].bathrooms
                            this.multiSelectValues['bathroom_count'] = {min: bathrooms, max: bathrooms};
                            this.updateMultiSelectValues({'bathroom_count': {min: bathrooms, max: bathrooms}})

                            let suites = this.$store.getters.getSearchExchange[0].suites
                            this.multiSelectValues['suite_count'] = {min: suites, max: suites};
                            this.updateMultiSelectValues({'suite_count': {min: suites, max: suites}})

                            let garages = this.$store.getters.getSearchExchange[0].garages
                            this.multiSelectValues['garage_count'] = {min: garages, max: garages};
                            this.updateMultiSelectValues({'garage_count': {min: garages, max: garages}})

                        }
                    },10)
                }
            }
        }
    };
</script>
