import Api from '../api/Api.js'

const apiPath = 'cities';
export default {
    get() {
        return Api.get(apiPath)
    },

    getById(id) {
        return Api.get(apiPath + `/${id}`)
    }
};