<template>
    <div class="modal" tabindex="-1">
        <div class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
             role="document"
             style="pointer-events: auto;">
            <div class="modal-content">
                <div class="modal-body flex justify-content-center">
                    <div class="inner-modal">
                        <div class="close-modal" ref="closeModalFavorite" data-toggle="modal"
                             :data-target="`#documentModal`">×
                        </div>
                        <div class="modal-body-content">
                            <div>
                                <h1 class="text-darker-gray text-4xl font-bold">Documentos Necessários</h1>
                                <ul class="w-75">
                                    <li class="text-base my-3 text-dark-gray" v-for="document in documents"
                                        :key="document.id">{{document.label}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'DocumentModal',
        props: {
            documents: {type: Array},
        },
    }
</script>
