<template>
     <StatusPage 
        img="404.svg"
        title="Não há nada aqui"
        description="O conteúdo que você está buscando não existe. Verifique o endereço digitado."
    />
</template>

<script>
    import StatusPage from '../../components/StatusPage.vue'

    export default {
        name: 'PageNotFound',
        components: {
            StatusPage,
        }    
    }
</script>
