<template>
    <div class="result-map">
        <GmapMap ref="baseMap" :center="centerPosition" :options="{styles:mapTheme, disableDefaultUI: true}" :zoom="15"
                 id="map">
            <GmapMarker :icon="{url:require('@/images/marker.png')}" :position="centerPosition"/>
            <GmapCircle
                    :center="centerPosition"
                    :options="{
                                        fillColor: `#FFCB00`,
                                        fillOpacity: 0.15,
                                        strokeColor: `#FFCB00`,
                                        strokeOpacity: 0.6,
                                        strokeWeight: 1
                                    }"
                    :radius="radius * 1000"
            />
            <GmapCircle
                    :center="centerPosition"
                    :radius="(radius * 1000)*1.5"
                    :options="{
                                        fillColor: `#FFCB00`,
                                        fillOpacity: 0.05,
                                        strokeWeight: 0
                                    }"
            />

        </GmapMap>
    </div>
</template>


<script>
    import GoogleMapTheme from "../js/googleMapTheme"
    export default {
        name: 'GoogleMapContainer',
        props: [
            'centerPosition',
            'radius'
        ],
        data(){
            return {
                mapTheme: GoogleMapTheme
            }
        },
        methods: {
            applyTraffic(){
                const google=window.google;
                this.$refs.baseMap.$mapPromise.then(map => {

                    const trafficLayer = new google.maps.TrafficLayer();
                    trafficLayer.setMap(map);

                })
            }
        }
    }
</script>
