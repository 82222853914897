import Api from '../api/Api.js'

const apiPath = 'matches';
export default {
    get(filter = "", token) {

        const config = {
			headers: { Authorization: `Bearer ${token}` }
		};

        return Api.get(apiPath + filter, config);
    },
    getById(id){
        return Api.get(apiPath+`/${id}`);
    },

    changeStatus(id, newStatus){
        return Api.put("match"+`/status/${id}`, {"status": newStatus})
    },

    post(payload){
        return Api.post(apiPath, {match: payload});
    }


};
