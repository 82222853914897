<template>
  <div class="modal" tabindex="-1">
    <div
      class="elm-bootstrap-modal modal-dialog modal-dialog-centered modal-lg"
      role="document"
      style="pointer-events: auto;"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="inner-modal">
            <div
              class="close-modal z-10"
              ref="closeModalFavorite"
              data-toggle="modal"
              :data-target="`#scheduleModal`"
            >×</div>
            <div class="modal-body-content">
              <div class="row w-full m-0">
                <div class="col-md-6 justify-end block ml-n4">
                  <img class="mx-auto hidden md:block w-full" src="../images/book-visit.svg" />
                  <div class="ml-5">
                    <h1 class="text-darker-gray text-4xl font-bold my-6">
                      Deseja agendar uma
                      visita?
                    </h1>
                    <span
                      class="text-base my-3 text-dark-gray"
                    >Insira seus dados e um de nossos corretores entrará em contato assim que possível.</span>
                  </div>
                </div>
                <div class="col-md-6 p-0 mt-10 md:mt-n5">
                  <div class="input-set md:ml-8 mt-n1">
                    <input class="form-input" v-model="$v.fullName.$model" required />
                    <label
                      class="form-label pointer-events-none"
                      :class="{'hide-small': !fullName}"
                    >Nome*</label>
                    <span class="form-error" v-show="sent && !$v.fullName.required">
                      Esse campo é
                      obrigatório
                    </span>
                  </div>
                  <div class="input-set md:ml-8 mt-n1">
                    <input class="form-input" v-model="$v.email.$model" required autocapitalize="none" />
                    <label
                      class="form-label pointer-events-none"
                      :class="{'hide-small': !email}"
                    >E-mail*</label>
                    <span class="form-error" v-show="sent && !$v.email.required">
                      Esse campo é
                      obrigatório
                    </span>
                  </div>
                  <div class="input-set md:ml-8 mt-n1">
                    <input
                      v-mask="['(##) #### ####', '(##) ##### ####']"
                      class="form-input"
                      v-model="$v.phone.$model"
                      required
                    />
                    <label
                      class="form-label pointer-events-none"
                      :class="{'hide-small': !phone}"
                    >Telefone para Contato*</label>
                    <span class="form-error" v-show="sent && !$v.phone.required">
                      Esse campo é
                      obrigatório
                    </span>
                  </div>
                  <div class="input-set md:ml-8 mt-n1">
                    <input class="form-input" v-model="$v.bestDay.$model" required />
                    <label
                      class="form-label pointer-events-none"
                      :class="{'hide-small': !bestDay}"
                    >Qual o melhor dia para visitar?*</label>
                    <span class="form-error" v-show="sent && !$v.bestDay.required">
                      Esse campo é
                      obrigatório
                    </span>
                  </div>
                  <button class="primary-button mb-10 md:mb-0 md:ml-4" @click="sendForm">
                    <div v-if="loading" class="spinner-border text-success-darker" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span v-else>Confirmar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import MatchService from "../js/api/MatchService";
import ScheduleService from "../js/api/ScheduleService";
export default {
  name: "ScheduleModal",
  directives: { mask },
  props: {
    property_id: { type: Number },
    relevance: { type: Number },
    to_email: { type: String }
  },

  data() {
    return {
      fullName: null,
      phone: null,
      email: null,
      bestDay: null,
      sent: false,
      offeredExchanges: null,
      matchPayload: null,
      schedulePayload: null,
      loading: false
    };
  },

  validations: {
    fullName: {
      required
    },
    bestDay: {
      required
    },
    phone: {
      required
    },
    email: {
      required,
      email
    }
  },

  created() {
    let searchResults = this.$store.getters.getSearchResults.find(
      prop => prop.id === this.property_id
    );
    if (searchResults) {
      this.offeredExchanges = searchResults["exchange_results"];
    }

    let matchPayload = {
      property_id: this.property_id,
      relevance: this.relevance ? this.relevance : 100,
      status: "pending"
    };

    if (this.offeredExchanges) {
      matchPayload = Object.assign(matchPayload, {
        offered_exchange: this.offeredExchanges
      });
    }

    let schedulePayload = {
      property_id: this.property_id,
      search_relevance: this.relevance,
      to_email: this.to_email
    };

    this.matchPayload = matchPayload;
    this.schedulePayload = schedulePayload;
  },
  methods: {
    async sendForm() {
      this.sent = true;
      this.loading = true;

      if (!this.$v.$invalid) {
        try {
          let client = {
            name: this.fullName,
            phone_number: this.phone,
            email: this.email
          };
          let matchPayload = this.matchPayload;
          let schedulePayload = this.schedulePayload;
          matchPayload = Object.assign(matchPayload, { client: client });
          schedulePayload = Object.assign(schedulePayload, { client: client });
          schedulePayload = Object.assign(schedulePayload, {
            preferred_day: this.bestDay
          });

          const { data } = await MatchService.post(matchPayload);
          // console.log(data);
          schedulePayload = Object.assign(schedulePayload, {
            match_id: data.id
          });
          await ScheduleService.post(schedulePayload);
          this.$toasted.show("Enviado com sucesso!");
          this.$refs.closeModalFavorite.click();
          this.loading = false;
        } catch (err) {
          // console.log(err)
        }
      }else{
          this.loading = false;
      }
    }
  }
};
</script>
