<template>
    <div id="app" :class="`main-${routerName}`">
        <div :class="routerName">
            <Header v-if="hasHeader"/>
            <router-view/>
        </div>
        <MainFooter />
        <Footer v-if="hasFooter"/>
    </div>
</template>


<script>
    import jQuery from "jquery";
    import Header from './components/Header.vue'
    import Footer from "./components/Footer";
    import MainFooter from "./components/MainFooter";

    export default {
        name: 'app',
        components: {
            Footer,
            Header,
            MainFooter
        },
        data: function () {
            return {
                routerName: this.$route.name   
            }
        },
        computed: {
            hasHeader: function() {
                if(this.routerName == 'confirmation' || this.routerName == 'forgotpassword' || this.routerName == 'resetpassword' || this.routerName == 'editprofile' || this.routerName == 'input-options') {
                    return false;
                }
                else {
                    return true;
                }
            },
            hasFooter: function() {
                if(this.routerName == 'input-options') {
                    return false;
                }
                else {
                    return true;
                }
            }
        },
        watch: {
            '$route'() {
                this.routerName = this.$route.name;
            }
        },
        mounted() {

            if ( !jQuery("div#app").hasClass('main-new-home') ) {

                jQuery(document).on('scroll', ()=>{
                    if ( window.top.scrollY > 0 ) {
                        jQuery("header.master-header").addClass('master-header-white-bg')
                    } else {
                        jQuery("header.master-header").removeClass('master-header-white-bg')
                    }
                })
                
            }
            
        }
    }
</script>