<template>
    <div class="page" style="overflow: scroll">
        <div class="main">
            <div class="container">
                <div class="main-icons">
                    <a class="icon-wrapper" href="#/">
                        <i class="icon icon-arrow-link-left dark-gray"></i>
                    </a>
                </div>
                <div class="main-line">
                    <h1>
                        <small>Tabela de </small>
                        Comparação
                    </h1>
                </div>
            </div>
        </div>
        <div class="main-desktop" style="background-color: #C2E3EA; border-color: #C2E3EA;">
            <div class="container">
                <h1>Tabela de Comparação</h1>
                <div class="flex items-center">
                    <a class="main-desktop-button" @click="redirectSearch">Adicionar Imóvel à Comparação</a>
                    <a class="icon_wrapper mr-4 ml-8" @click="scrollTo('left')">
                        <i style="background-color: rgb(56, 44, 122);" class="icon icon-arrow-left "></i>
                    </a>
                    <a class="icon_wrapper" @click="scrollTo('right')">
                        <i style="background-color: rgb(56, 44, 122);" class="icon icon-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="content">
            <div v-if="loading" style="margin-left:50%" class="spinner-border text-success-darker" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div v-else class="container relative">
                <div class="feed-shadow"></div>
                <div class="feed" ref="itemsScroll">
                    <div v-if="hasProperties" class="properties-comparations">
                        <PropertyComparation @deleted="triggerChange"  :property_reference="comparation.property_reference" :key="comparation.id"
                                             v-for="comparation in properties"/>
                    </div>
                    <div v-else class="properties-comparations empty">
                        <div>
                            <img src="@/images/no-one-accepts-your-offer-house.png">
                        </div>
                        <h1>Nenhum imóvel para comparar ainda</h1>
                        <p>Adicione pelo menos dois imóveis para comparar suas características</p>
                        <button class="primary-button" @click="redirectSearch">Buscar Imóveis</button>
                    </div>
                </div>
            </div>
            <a v-if="hasProperties" class="secondary-button md:hidden" @click="redirectSearch">Adicionar Imóvel à Comparação</a>
        </div>
    </div>
</template>

<script>
    import fetchUser from "../js/mixins/fetchUser";
    import PropertyComparation from "../components/property/PropertyComparation";

    export default {
        name: 'comparation',
        components: {
            PropertyComparation
        },
        mixins: [fetchUser],
        data() {
            return {
                properties: [],
                hasProperties: false
            }
        },
        created() {
            if (!this.$store.state.user) {
                this.$router.push({name: "login"});
            }
            this.updateUser(this.$store.state.userToken);
            if (this.$store.state.user.comparison.length > 0) {
                this.properties = this.$store.state.user.comparison;
                this.hasProperties = true;
            }
        },
        methods: {
            triggerChange() {
                if (this.$store.state.user.comparison.length > 0) {
                    this.properties = this.$store.state.user.comparison;
                    this.hasProperties = true;
                } else {
                    this.properties = [];
                    this.hasProperties = false;
                }
            },

            redirectSearch() {
                this.$router.push({
                    name: 'search-filters'
                });
            },

            scrollTo(dir) {
                switch (dir) {
                    case "right":
                        this.$refs.itemsScroll.scrollLeft = 9999;
                        break;
                    case "left":
                        this.$refs.itemsScroll.scrollLeft = 0;
                        break;
                }
            }
        }
    }
</script>
