export default {
    data() {
        return {
            showPassword: false,
            showPasswordType: 'password',
            showPasswordText: 'Exibir',
            showConfirmPassword: false,
            showConfirmPasswordType: 'password',
            showConfirmPasswordText: 'Exibir',
            showCurrentPassword: false,
            showCurrentPasswordType: 'password',
            showCurrentPasswordText: 'Exibir'
        }
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
            this.showPasswordType = this.showPassword? 'text' : 'password';
            this.showPasswordText = this.showPassword? 'Ocultar' : 'Exibir';
        },
        toggleConfirmPassword(){
            this.showConfirmPassword = !this.showConfirmPassword;
            this.showConfirmPasswordType = this.showConfirmPassword? 'text' : 'password';
            this.showConfirmPasswordText = this.showConfirmPassword? 'Ocultar' : 'Exibir';
        },
        toggleCurrentPassword(){
            this.showCurrentPassword = !this.showCurrentPassword;
            this.showCurrentPasswordType = this.showCurrentPassword? 'text' : 'password';
            this.showCurrentPasswordText = this.showCurrentPassword? 'Ocultar' : 'Exibir';
        }
    }
};