<template>
  <div class="page" v-if="isLoaded">
    <div class="mobile-header">
      <a class="close-button" href="#/">
        <i class="icon icon-arrow-link-left dark-gray"></i>
      </a>
    </div>
    <div class="container">
      <div class="row justify-center md:justify-start">
        <div class="first-col col-12 col-lg-5">
          <div class="flex flex-col md:flex-row items-center">
            <div class="user-img">
              <span> {{ user.initials() }} </span>
            </div>
            <div class="user-info">
              <h1 class="user-name"> {{ user.name }} </h1>
              <p class="user-email"> {{ user.email }} </p>
              <div class="row mx-0 my-8 lg:my-0 justify-center lg:justify-start">
                <a class="user-link pr-5 border-r border-lighter-gray" href="#/editprofile">Editar dados</a>
                <a class="user-link pl-5" :href="'#/resetpassword?email=' + user.email">Editar senha</a>
              </div>
              <button class="user-button hidden lg:inline-block" @click="$router.push('/register-property')">Cadastrar um imóvel</button>
            </div>
          </div>
        </div>
        <div class="second-col col-lg-7">
          <div class="row flex items-center justify-between mx-8 md:mx-0 mb-4 md:mb-8">
            <h1 class="content-title hidden md:block">Meus favoritos</h1>
            <h1 class="content-title md:hidden">Imóveis favoritos</h1>
            <a class="user-link" href="#/favorites">Ver todos</a>
          </div>
          <div class="feed">
            <div v-for="property in user.favorite.slice(0,5)" :key="property.property_id" class="property-card-wrapper">
              <PropertyCard 
                :relevance="property.relevance"
                :favorites_page="false"
                :property_reference="property.property_reference"
              />
            </div>
          </div>
          <div class="row mx-8">
            <button class="user-button lg:hidden mx-auto mb-4" @click="$router.push('/register-property')">Cadastrar um imóvel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserService from "../../js/api/UserService";
  import UserClass from "../../js/classes/user";
  import PropertyCard from "../../components/property/PropertyCard.vue";

  export default {
    name: 'profile',
    components: {
      PropertyCard
    },
    data() {
      return {
        'user': UserClass,
        isLoaded: false

      }
    },
    created() {
      if(!this.$store.state.user){
        this.$router.push({name: "login"});
      }
      this.getData(localStorage.token);
    },
    methods: {
      async getData(token) {
        try {
          const {data} = await UserService.getData(token);  
          this.user = new UserClass(data['data']);
          this.$store.commit(`setUser`, this.user);
          this.isLoaded = true;
        } catch (err) {
          // console.log(err);
        }
      }
    }  
  }
</script>
