<template>
    <nav aria-label="Page navigation">
        <ul class="pagination" v-if="totalCount > 0 && !limit" >
            <li v-if="currentPage!== 1" class="page-item">
                <a class="page-link z-0" :class="{'cursor-pointer': currentPage!== 1}" @click="previousPage">
                    <div class="icon icon-arrow-left highlight"></div>
                </a>
            </li>
            <template v-for="page in amountOfPages">
                <li class="flex items-center hidden" :key="page"
                    v-if="(page > currentPage + 2 || page < currentPage - 2) && page !== 1 && page !== amountOfPages">
                    ...
                </li>
                <li class="flex items-center text-gray" :key="page"
                    v-else-if="(page === currentPage + 2 || page === currentPage - 2) && page !== 1 && page !== amountOfPages">
                    ...
                </li>
                <li class="page-item" v-else :key="page" :class="{active: page === currentPage}"
                    @click="currentPage = page"><a class="page-link z-0 cursor-pointer">{{page}}</a></li>
            </template>
            <li v-if="currentPage !== amountOfPages" class="page-item">
                <a class="page-link z-0" :class="{'cursor-pointer': currentPage !== amountOfPages}" @click="nextPage">
                    <div class="icon icon-arrow-right highlight"></div>
                </a>
            </li>
        </ul>
        <ul class="pagination" v-else-if="totalCount > 0 && limit" >
            <li v-if="currentPage!== 1" class="page-item"><a class="page-link z-0" :class="{'cursor-pointer': currentPage!== 1}"
                                     @click="previousPage">
                <div class="icon icon-arrow-left highlight"></div>
            </a></li>
            <template v-for="page in amountOfPages">
                <template v-if="(currentPage === 1 && page === 2) && amountOfPages > 2">
                    <li class="page-item" :key="page" :class="{active: page === currentPage}"
                        @click="currentPage = page"><a class="page-link z-0 cursor-pointer">{{page}}</a></li>
                    <li class="flex items-center text-gray" :key="page + 'key'">
                        ...
                    </li>
                </template>
                <template v-else-if="(currentPage === amountOfPages && page === amountOfPages -1) && amountOfPages > 2">
                    <li class="page-item" :key="page" :class="{active: page === currentPage}"
                        @click="currentPage = page"><a class="page-link z-0 cursor-pointer">{{page}}</a></li>
                    <li class="flex items-center text-gray" :key="page + 'key'">
                        ...
                    </li>
                </template>

                <li class="flex items-center hidden" :key="page"
                    v-else-if="(page > currentPage + 1 || page < currentPage - 1) && page !== 1 && page !== amountOfPages">
                    ...
                </li>
                <li class="flex items-center text-gray" :key="page"
                    v-else-if="((page === currentPage + 1 || page === currentPage - 1) && page !== 1 && page !== amountOfPages)">
                    ...
                </li>
                <li class="page-item" v-else :key="page" :class="{active: page === currentPage}"
                    @click="currentPage = page"><a class="page-link z-0 cursor-pointer">{{page}}</a></li>
            </template>
            <li class="page-item"><a class="page-link z-0" :class="{'cursor-pointer': currentPage !== amountOfPages}"
                                     @click="nextPage">
                <div v-if="currentPage !== amountOfPages" class="icon icon-arrow-right highlight"></div>
            </a></li>
        </ul>
        <div style="clear:both;"></div>
    </nav>
</template>

<script>
    export default {
        name: 'Pagination',
        props: ['offset', 'instancesPerPage', 'totalCount', 'limit'],
        data() {
            return {
                currentPage: 1,
                shouldScrollTop: true
            }
        },
        computed: {
            amountOfPages() {
                return Math.ceil(this.totalCount / this.instancesPerPage);
            }
        },

        watch: {
            currentPage() {
                const newOffset = this.currentPage * this.instancesPerPage - this.instancesPerPage;
                this.$emit('update:offset', newOffset);
                if (this.shouldScrollTop) {
                    window.scrollTo(0, 0);
                }
            }
        },

        methods: {
            previousPage() {
                if (this.currentPage >= 2) {
                    this.currentPage--;
                }
            },
            nextPage() {
                if (this.currentPage <= this.amountOfPages - 1) {
                    this.currentPage++;
                }
            }
        }
    }
</script>
