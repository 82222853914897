<template>
  <div class="page">
    <div class="main" style="background-color: #C2E3EA;">
      <div class="main-icons">
        <a class="icon" href="#/" style="background-color: rgb(194, 227, 234);">
          <i class="icon icon-arrow-link-left white"></i>
        </a>
      </div>
      <div class="main-line">
        <h2 class="mb-6">
          Cadastre seu
          <strong>Imóvel</strong>
        </h2>
        <StepCounter step-count="3" :steps-done="step - 1" />
      </div>
    </div>
    <div class="main-desktop" style="background-color: #C2E3EA; border-color: #C2E3EA;">
      <div class="container">
        <a class="main-desktop-button cursor-pointer" @click="$router.push('/')">Voltar à home</a>
        <StepCounter step-count="3" :steps-done="step - 1" />
      </div>
    </div>
    <div v-if="step == 1" class="step-1">
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="complementary-info col-md-6">
              <div class="complementary-info-content">
                <small>Cadastro:</small>
                <h2>Descreva seu imóvel</h2>
                <p>
                  Informe as principais característica do imóvel que deseja anunciar. Mais detalhes representam mais compatibilidade para quem está procurando um imóvel,
                  <strong>aumentando a chance de fechar negócio.</strong>
                </p>
              </div>
              <img src="@/images/search-filters-desktop-house.svg" />
            </div>
            <form class="main-info col-md-6">
              <div class="switch-button mb-8" style="display: none;">
                <div
                  :class="{active: dealType === 'Vendas'}"
                  @click="dealType = 'Vendas'"
                >Para Vender</div>
                <div :class="{active: dealType === 'Locação'}" @click="dealType = 'Locação'">
                  Para
                  Alugar
                </div>
              </div>

              <Autocomplete
                :initial_value="city ? city.label : null"
                :items="cities.map(city => city.label)"
                @optionSelected="onSelectCity"
                @blur="setAddressLatLong"
                label="Em qual cidade? *"
              />
              <p class="form-error ml-2" v-if="sentStepOne && $v.city.$invalid">Insira a cidade</p>

              <InputBox 
                        :selected-data="inputBoxValues['neighborhood']"
                        :form-data="alphabeticallySortedNeighborhoods"
                        @updateCheckedValues="updateCheckedValues"
                        description="Em que está bairro o imóvel?"
                        label="neighborhood"
                        title="Bairro *"
                        @blur="setAddressLatLong"
                        :allow-multiple="false"
              />
              
              <p class="form-error ml-2" v-if="sentStepOne && inputBoxValues['neighborhood'].length == 0">Insira o bairro</p>

              <div class="input-set">
                <input
                  class="form-input forceBlurFields"
                  id="address"
                  v-model="address.address"
                  @blur="setAddressLatLong"
                  required
                />
                <!-- Verificar com  o luCas, retirar esse campo via google tira 
                demais campos necessários, como bairro, cidade, lat e long-->
                
                <label class="form-label" for="address" :class="{'hide-small': !address.address}">
                  Endereço do Imóvel *
                </label>
              </div>

              <p class="form-error ml-2" v-if="sentStepOne && !address.address">Insira o endereço</p>

              <div class="input-set">
                <input
                  class="form-input forceBlurFields"
                  id="address_number"
                  v-model="address.number"
                  @blur="setAddressLatLong"
                  required
                />
                <!-- Verificar com  o luCas, retirar esse campo via google tira 
                demais campos necessários, como bairro, cidade, lat e long-->
                <label
                  class="form-label"
                  for="address_number"
                  :class="{'hide-small': !address.number}"
                >Número *</label>
              </div>

              <p class="form-error ml-2" v-if="sentStepOne && !address.address">Insira o número</p>

              
              <InputBox
                :selected-data="inputBoxValues['propertyType']"
                :form-data="propertyTypes"
                @updateCheckedValues="updateCheckedValues"
                description="Você tem um apartamento, barracão?"
                label="propertyType"
                title="Tipo de Imóvel *"
                :allow-multiple="false"
              />
              
              <p
                class="form-error ml-2"
                v-if="sentStepOne && inputBoxValues['propertyType'].length === 0"
              >Insira o tipo de imóvel</p>
              
              <div style="display:none" class="input-set">
                <input
                  class="form-input forceBlurFields"
                  id="area"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  v-model="totalArea"
                  v-money="totalArea === null ? null : area"
                  required
                />
                <label class="form-label" for="area" :class="{'hide-small': !area}">
                  Metragem do
                  Imóvel *
                </label>
              </div>
              <!--p
                class="form-error ml-2"
                v-if="sentStepOne && $v.totalArea.$invalid"
              >Insira a metragem</p -->
              <div class="input-set">
                <input
                  class="form-input forceBlurFields"
                  id="price"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  v-model="price"
                  v-money="price === null ? null : money"
                  required
                />
                <label class="form-label" for="price" :class="{'hide-small': !price}">
                  Valor
                  Aproximado
                </label>
              </div>
              <!-- p class="form-error ml-2" v-if="sentStepOne && $v.price.$invalid">Insira o valor</p-->
              <MultiSelect
                :initial-value="multiSelectValues['room_count']"
                :single-select="true"
                @updateMultiSelectValues="updateMultiSelectValues"
                label="room_count"
                register="true"
                title="Número de Quartos (contando Suíte, Demi-Suíte)"
              />
              <MultiSelect
                :initial-value="multiSelectValues['bathroom_count']"
                :single-select="true"
                @updateMultiSelectValues="updateMultiSelectValues"
                label="bathroom_count"
                register="true"
                title="Número de Banheiros (contando Lavabo, Banheiro Suíte, Banheiro Empregada)"
              />
              <MultiSelect
                :initial-value="multiSelectValues['suite_count']"
                :single-select="true"
                @updateMultiSelectValues="updateMultiSelectValues"
                label="suite_count"
                register="true"
                has-zero="true"
                title="Número de Suítes"
              />
              <MultiSelect
                :initial-value="multiSelectValues['garage_count']"
                :single-select="true"
                @updateMultiSelectValues="updateMultiSelectValues"
                label="garage_count"
                register="true"
                has-zero="true"
                title="Número de Vagas"
              />
              <div style="display:none" class="filter-section mt-16">
                <h5>Mobiliado *</h5>
                <div class="flex">
                  <div
                    class="simple-button"
                    :class="{active: furnished === 'yes'}"
                    @click="furnished = 'yes'"
                  >Sim</div>
                  <div
                    class="simple-button"
                    :class="{active: furnished === 'no'}"
                    @click="furnished = 'no'"
                  >Não</div>
                  <div
                    class="simple-button"
                    :class="{active: furnished === 'semi'}"
                    @click="furnished = 'semi'"
                  >Semimobiliado</div>
                </div>
                <!--p
                  class="form-error ml-2 mt-2"
                  v-if="sentStepOne && $v.furnished.$invalid"
                >Esse campo é obrigatório</p-->
              </div>
              <div style="display:none" class="filter-section mt-8">
                <h5>Pet Friendly *</h5>
                <div class="flex">
                  <div
                    class="simple-button"
                    :class="{active: petFriendly === 'yes'}"
                    @click="petFriendly = 'yes'"
                  >Sim</div>
                  <div
                    class="simple-button"
                    :class="{active: petFriendly === 'no'}"
                    @click="petFriendly = 'no'"
                  >Não</div>
                </div>

                <!--p
                  class="form-error ml-2 mt-2"
                  v-if="sentStepOne && $v.petFriendly.$invalid"
                >Esse campo é obrigatório</p-->
              </div>
              <InputBox style="display:none"
                class="mt-16"
                :selected-data="inputBoxValues['customFeaturesProperty']"
                :form-data="orderedPropertyAttrs"
                @updateCheckedValues="updateCheckedValues"
                description="Possui churrasqueira, sacada, jardim?"
                label="customFeaturesProperty"
                title="Atributos do Imóvel"
              />

              <InputBox style="display:none"
                :selected-data="inputBoxValues['customFeaturesCondominium']"
                :form-data="orderedCondominiumAttrs"
                @updateCheckedValues="updateCheckedValues"
                description="Possui playground, portaria 24h?"
                label="customFeaturesCondominium"
                title="Atributos do Condomínio"
              />
              <button :class="{valid: true}" v-on:click.prevent="sendStepOne">Continuar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step == 2" class="step-2">
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="complementary-info col-md-6">
              <div class="complementary-info-content">
                <small>Cadastro:</small>
                <h2><font-awesome-icon data-toggle="modal" data-target="#permutaModal" class="info-permuta" icon="info-circle" /> Deseja estudar permuta?</h2>
                <!-- <p>
                  Broker ipsum dolor sit amet, consectetuer picturesque elit.
                  <strong>
                    High Line commodo
                    ligula eget dolor cum sociis.
                  </strong>
                </p> -->
              </div>
              <img src="@/images/search-filters-desktop-house.svg" />
            </div>

            

            <div class="main-info flex flex-col justify-center col-md-6">
              <div class="arrows-wrapper hidden">
                <a href class="button-wrapper">
                  <i class="icon arrow-back-icon"></i>
                </a>
                <a href class="button-wrapper">
                  <i class="icon arrow-forward-icon"></i>
                </a>
              </div>
              <p class="mobile-title"><font-awesome-icon style="font-size:22px !important;" data-toggle="modal" data-target="#permutaModal" class="info-permuta" icon="info-circle" /> Você deseja estudar permuta como forma de pagamento?</p>
              <div class="row hidden">
                <div class="col-md-4">
                  <div class="advantages-cards active">Lorem Ipsum vantagens da permuta</div>
                </div>
                <div class="col-md-4">
                  <div class="advantages-cards">
                    <img src="@/images/illustration.svg" />
                    Pague menos Imposto de Renda sobre o negócio
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="advantages-cards">
                    <img src="@/images/illustration.svg" />
                    Has ex high ceilings persecuti
                  </div>
                </div>
              </div>
              <div class="mt-8">
                <div
                  class="modal-link"
                  data-toggle="modal"
                  data-target="#modal-property"
                  @click="modalKey++;propidx=null"
                >
                  Adicionar um Imóvel
                  <i class="icon icon-plus"></i>
                </div>
                <div
                  class="flex justify-content-between exchange-item-wrapper mb-2"
                  :key="index + 1313"
                  v-for="(exchange, index) in this.$store.getters.getExchangesRegisterPropertyObj['property']"
                >
                  <span
                    data-toggle="modal" data-target="#modal-property" @click="changePropIdx(exchange.index)"
                    class="exchange-item"
                  >{{exchange.exchange.property_type | toSentence('ou')}}{{exchange.exchange | exchangeFormat}}</span>
                  <i
                    class="icon icon-discard highlight cursor-pointer mr-2"
                    @click="$store.commit('removeExchangesRegisterProperty', exchange.index)"
                  ></i>
                </div>
                <RegisterPropertyModal :key="modalKey" :propidx="propidx" :auto="false" id="modal-property" />
                <hr />
                <div
                  class="modal-link"
                  data-toggle="modal"
                  data-target="#modal-auto"
                  @click="modalKeyAuto++;propidx=null"
                >
                  Adicionar um Automóvel
                  <i class="icon icon-plus"></i>
                </div>
                <div
                  class="flex justify-content-between exchange-item-wrapper mb-2"
                  :key="index + 2313"
                  v-for="(exchange, index) in this.$store.getters.getExchangesRegisterPropertyObj['vehicle']"
                >
                  <span data-toggle="modal" data-target="#modal-auto" @click="changePropIdx(exchange.index)" class="exchange-item">{{exchange.exchange | exchangeFormat}}</span>
                  <i
                    class="icon icon-discard highlight cursor-pointer mr-2"
                    @click="$store.commit('removeExchangesRegisterProperty', exchange.index)"
                  ></i>
                </div>
                <RegisterPropertyModal :key="modalKeyAuto" :propidx="propidx" :auto="true" id="modal-auto" />
              </div>
            </div>
          </div>
          <div class="content-footer row">
            <div class="col-md-6 mb-4">
              <button
                :class="{valid: this.$store.getters.getExchangesRegisterProperty.length !== 0}"
                v-on:click.prevent="nextStep"
              >Continuar</button>
            </div>
            <div
              class="col-md-6 flex justify-center md:justify-end"
              v-show="this.$store.getters.getExchangesRegisterProperty.length === 0"
            >
              <a
                class="skip-button cursor-pointer"
                @click="nextStep"
                v-show="this.$store.getters.getExchangesRegisterProperty.length === 0"
              >
                <i class="icon icon-close link"></i>
                Não desejo estudar permuta
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step == 3" class="step-3">
      <div class="content">
        <div class="container">
          <p
            class="mobile-title md:hidden"
          >Confirme os dados do imóvel e preencha seus dados de contato.</p>
          <div class="row">
            <div class="complementary-info col-md-6">
              <div class="complementary-info-content">
                <small>Cadastro:</small>
                <h2>Confirme seus dados e do seu imóvel</h2>
                <!-- <p>
                  Broker ipsum dolor sit amet, consectetuer picturesque elit.
                  <strong>
                    High Line commodo
                    ligula eget dolor cum sociis.
                  </strong>
                </p> -->
              </div>
              <img src="@/images/search-filters-desktop-house.svg" />
            </div>
            <form class="main-info col-md-6">
              <div class="register-property-card">
                <div class="register-property-body">
                  <h3>{{inputBoxValues['propertyType'][0]}} {{dealType === "Vendas" ? 'à venda' : 'para alugar'}} no {{address.neighborhood}}, {{address.city}}</h3>
                  <div class="demand-card-values">
                    <div class="value mr-8">
                      <span>Valor Aproximado</span>
                      <div class="currency-display">
                        <p class="currency-type">R$</p>
                        <p
                          class="currency-value"
                        >{{removeMoneyFormattingIgnoreCommas(price) | currency}}</p>
                        <p class="currency-cents">,00</p>
                      </div>
                    </div>
                    <!-- div class="value">
                      <span>Área Útil</span>
                      <div class="currency-display">
                        <p class="currency-value">{{removeAreaFormatting(totalArea)}}</p>
                        <p class="currency-cents">m²</p>
                      </div>
                    </div -->
                  </div>
                  <div class="demand-features row">
                    <div class="feature col-6 col-xl-3">
                      <i class="icon icon-bed light-gray"></i>
                      <div>
                        <p class="feature-value">{{multiSelectValues['room_count']['min']}}</p>
                        <p class="feature-name">Dorm.</p>
                      </div>
                    </div>
                    <div class="feature col-6 col-xl-3">
                      <i class="icon icon-toilet light-gray"></i>
                      <div>
                        <p class="feature-value">{{multiSelectValues['bathroom_count']['min']}}</p>
                        <p class="feature-name">Ban.</p>
                      </div>
                    </div>
                    <div class="feature col-6 col-xl-3">
                      <i class="icon icon-suite light-gray"></i>
                      <div>
                        <p class="feature-value">{{multiSelectValues['suite_count']['min']}}</p>
                        <p class="feature-name">Suítes</p>
                      </div>
                    </div>
                    <div class="feature col-6 col-xl-3">
                      <i class="icon icon-car light-gray"></i>
                      <div>
                        <p class="feature-value">{{multiSelectValues['garage_count']['min']}}</p>
                        <p class="feature-name">Vagas</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="register-property-tags"
                  v-show="this.$store.getters.getExchangesRegisterProperty.length !== 0"
                >
                  <p>Estuda bens como parte de pagamento:</p>
                  <div
                    class="tag"
                    :key="index + 3313"
                    v-for="(exchange, index) in this.$store.getters.getExchangesRegisterPropertyObj['property']"
                  >{{exchange.exchange.property_type | toSentence('ou')}} {{exchange.exchange | exchangeFormat}}</div>
                  <div
                    class="tag"
                    :key="index + 4313"
                    v-for="(exchange, index) in this.$store.getters.getExchangesRegisterPropertyObj['vehicle']"
                  >{{exchange.exchange | exchangeFormat}}</div>
                </div>
                <div class="register-property-footer">
                  <a class="cursor-pointer" @click="step = 1">Editar Dados</a>
                </div>
              </div>
              <div class="input-set">
                <input class="form-input forceBlurFields" id="fullName" v-model="$v.fullName.$model" required />
                <label class="form-label" for="fullName" :class="{'hide-small': !fullName}">
                  Nome
                  completo
                </label>
                <p class="form-error" v-show="sent && !$v.fullName.required">
                  Esse campo é
                  obrigatório
                </p>
              </div>
              <div class="input-set">
                <input
                  class="form-input forceBlurFields"
                  id="phone"
                  v-model="$v.phone.$model"
                  type="tel"
                  v-mask="['## #### ####', '## ##### ####']"
                  required
                />
                <label class="form-label" for="phone" :class="{'hide-small': !phone}">
                  Telefone para
                  contato
                </label>
                <p class="form-error" v-show="sent && !$v.phone.required">Esse campo é obrigatório</p>
              </div>
              <div class="input-set">
                <input class="form-input forceBlurFields" id="email" v-model="$v.email.$model" required autocapitalize="none"/>
                <label class="form-label" for="email" :class="{'hide-small': !email}">E-mail</label>
                <p class="form-error" v-show="sent && !$v.email.required">Esse campo é obrigatório</p>
                <p class="form-error" v-show="sent && !$v.email.email">Insira um e-mail válido</p>
              </div>
              <button :class="{valid: true}" v-on:click.prevent="sendForm">Concluir Cadastro</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-show="sent" class="send-register-property-backdrop"></div>
    <div v-show="sent" class="send-register-property">
      <div class="send-message">
          <div class="spinner-border text-success-darker" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="text">
            Estamos enviando seus dados!
          </div>
      </div>
    </div>

    <PermutaModal id="permutaModal" />
  </div>
</template>

<script>
//import axios from 'axios'
import jQuery from 'jquery';
import RegisterPropertyModal from "../components/property/RegisterPropertyModal";
import MultiSelect from "../components/filters/MultiSelect";
import InputBox from "../components/filters/InputBox";
import StepCounter from "../components/StepCounter";
import Autocomplete from "../components/filters/Autocomplete.vue";
import { required, email } from "vuelidate/lib/validators";
import { VMoney } from "v-money";
import { mask } from "vue-the-mask";
import FetchCities from "../js/mixins/fetchCities";
import FetchPropertyTypes from "../js/mixins/fetchPropertyTypes";
import FetchCustomFeatures from "../js/mixins/fetchCustomFeatures";
import CityService from "../js/api/CityService";
import NeighborhoodClass from "../js/classes/neighborhood";
//import VueGoogleAutocomplete from "vue-google-autocomplete";
import PropertyService from "../js/api/PropertyService";
//const notZero = (value) => value !== "R$ 0,00" && value !== "0m²";
import PermutaModal from "../components/PermutaModal";

export default {
  name: "Filters",
  components: {
    StepCounter,
    MultiSelect,
    InputBox,
    Autocomplete,
    RegisterPropertyModal,
    PermutaModal
    //VueGoogleAutocomplete,
  },
  directives: { money: VMoney, mask },
  mixins: [FetchCities, FetchPropertyTypes, FetchCustomFeatures],
  props: {
    initial_city: {
      default: null,
      type: Object,
    },
    initial_dealType: {
      default: "Vendas",
      type: String,
    },
  },
  data() {
    return {
      inputBoxValues: {
        neighborhood: [],
        propertyType: []
      },
      multiSelectValues: {},
      step: 1,
      dealType: "Vendas",
      city: null,
      neighborhoods: null,
      modalKey: 0,
      modalKeyAuto: 29348190234890, // random key para modais para forcar refresh toda vez que abre
      fullName: null,
      phone: null,
      email: null,
      address: {
        city: null,
        address: null,
        neighborhood: null,
        number: null,
        latitude: null,
        longitude: null,
      },
      demand_origin_id: null,
      price: null,
      totalArea: null,
      furnished: null,
      petFriendly: null,
      minValue: null,
      maxValue: null,
      minArea: null,
      maxArea: null,
      sent: false,
      sentStepOne: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true /* doesn't work with directive */,
      },
      area: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "m²",
        precision: 0,
        masked: true /* doesn't work with directive */,
      },
      propidx: null
    };
  },
  validations: {
    fullName: {
      required,
    },
    phone: {
      required,
    },
    email: {
      required,
      email,
    },
    city: {
      required,
    },
    minValue: {
      required,
    },
    maxValue: {
      required,
    },
    minArea: {
      required,
    },
    maxArea: {
      required,
    },
    address: {
      required,
    },

    //furnished: {
    //  required,
    //},
    //petFriendly: {
    //  required,
    //},
    /*price: {
      required,
      notZero,
    },*/
    /*totalArea: {
      required,
      notZero,
    },*/
  },
  created() {
    
    console.log("Mounted: routes: ", this.$route)

    this.demand_origin_id = this.$route.params.demand_id ? this.$route.params.demand_id : null

    if (this.initial_city) {
      this.city = this.initial_city;
    }
    if (this.initial_dealType) {
      this.dealType = this.initial_dealType;
    }
    this.$v.$touch();
    this.inputBoxValues["propertyType"] = [];
    this.inputBoxValues["neighborhood"] = [];
    this.multiSelectValues["room_count"] = { min: 1, max: 1 };
    this.multiSelectValues["bathroom_count"] = { min: 1, max: 1 };
    this.multiSelectValues["suite_count"] = { min: 0, max: 0 };
    this.multiSelectValues["garage_count"] = { min: 0, max: 0 };
    this.scrollToTop()

    console.log("F", this.customFeatures[`property`])
  },
  computed: {
      orderedPropertyAttrs() {
          if (!this.customFeatures[`property`]) {
              return false
          }

          return this.orderList(this.customFeatures[`property`])
      },
      orderedCondominiumAttrs() {
          if (!this.customFeatures[`condominium`]) {
              return false
          }

          return this.orderList(this.customFeatures[`condominium`])
      },
      alphabeticallySortedNeighborhoods() {
          if (!this.neighborhoods) {
              return null;
          }

          return this.orderList(this.neighborhoods)
      }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    slugify(str) {
      const map = {
        a: "á|à|ã|â|À|Á|Ã|Â",
        e: "é|è|ê|É|È|Ê",
        i: "í|ì|î|Í|Ì|Î",
        o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
        u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
        c: "ç|Ç",
        n: "ñ|Ñ",
      };

      str = str.toLowerCase();

      for (let pattern in map) {
        str = str.replace(new RegExp(map[pattern], "g"), pattern);
      }

      return str;
    },
    orderList(list) {
        if (!list) {
            return null;
        }

        console.log("List to oder: ", list)

        let listOrdered = list;

        listOrdered.sort((a,b) => (this.slugify(a.label) > this.slugify(b.label)) ? 1 : ((this.slugify(b.label) > this.slugify(a.label)) ? -1 : 0))

        return listOrdered;
    },
    async sendForm() {
      this.sent = true;
      this.$v.$touch();
      if (
        !this.$v.fullName.$invalid &&
        !this.$v.email.$invalid &&
        !this.$v.phone.$invalid
      ) {
        let payload = {
          demand_origin_id: this.demand_origin_id,
          client: {
            name: this.fullName,
            phone_number: this.phone,
            email: this.email,
          },
          approx_value: this.price != '' && this.price != null ? this.removeMoneyFormattingIgnoreCommas(this.price) : null,
          total_area: null,//this.removeAreaFormatting(this.totalArea),
          feature: {
            property_age: 0,
            min_rooms: this.multiSelectValues["room_count"]["min"],
            max_rooms: this.multiSelectValues["room_count"]["max"],
            min_bathrooms: this.multiSelectValues["bathroom_count"]["min"],
            max_bathrooms: this.multiSelectValues["bathroom_count"]["max"],
            min_suites: this.multiSelectValues["suite_count"]["min"],
            max_suites: this.multiSelectValues["suite_count"]["max"],
            min_garages: this.multiSelectValues["garage_count"]["min"],
            max_garages: this.multiSelectValues["garage_count"]["max"],
            petFriendly: null,//this.petFriendly,
            furnished: null,//this.furnished,
          },
          address: {
            address: this.address.address,
            city: this.address.city,
            neighborhood: this.address.neighborhood,
            number: this.address.number || 0,
            radius: 0,
            latitude: this.address.latitude,
            longitude: this.address.longitude,
          },
          property_type: this.inputBoxValues["propertyType"][0],
          deal_type: this.dealType,
          custom_features: {
            property: this.inputBoxValues["customFeaturesProperty"],
            condominium: this.inputBoxValues["customFeaturesCondominium"],
          },
          exchange: this.$store.getters.getExchangesRegisterProperty,
        };
        try {
          await PropertyService.postPendingProperty(payload);
          this.$store.commit("clearExchangesRegisterProperty");
          this.$toasted.show("Enviado com sucesso!");
          await this.$router.push({
            name: "demands-page",
          });
        } catch (err) {
          // console.log(err.response);
        }
      }
    },

    sendStepOne() {
      this.sentStepOne = true;
      this.$v.$touch();
      if (
        !this.$v.city.$invalid &&
        !this.$v.address.$invalid &&
        //!this.$v.totalArea.$invalid &&
        //!this.$v.price.$invalid &&
        //!this.$v.furnished.$invalid &&
        //!this.$v.petFriendly.$invalid &&
        this.inputBoxValues["propertyType"].length !== 0
      ) {
        this.step++;
      }
    },

    onSelectCity(result, searchString) {
      if (
        searchString.toString().toLowerCase() ===
        result[0].toString().toLowerCase()
      ) {
        this.city = this.cities.find((city) => city.label === result[0]);

        //console.log("Cidade Selecionda: ", this.city)
        this.address.city = this.city.label

        this.fetchNeighborhoods(this.city.id, null)
      } else {
        this.city = null;
      }
    },

    async fetchNeighborhoods(cityId, callback) {
        try {
            const {data} = await CityService.getById(cityId);
            this.neighborhoods = data['data']['neighborhoods'];
            this.neighborhoods = this.neighborhoods.map((v) => new NeighborhoodClass(v));

            if (callback != null && typeof callback != "undefined") {
                callback()
            }
        } catch (err) {
            // console.log(err);
        }
    },

    nextStep() {
      this.$v.$touch();
      this.step++;
    },

    updateCheckedValues(value) {
      const label = Object.keys(value)[0];
      this.inputBoxValues[label] = value[label];
      if (label == "neighborhood") {
        this.address.neighborhood = this.inputBoxValues[label][0]
      }
    },

    updateMultiSelectValues(value) {
      const label = Object.keys(value)[0];
      this.multiSelectValues[label] = value[label];
    },

    setAddressLatLong() {

      if (this.address.city && this.address.address && this.address.neighborhood && this.address.number) {

          let location = `${this.address.address}, ${this.address.number}, ${this.address.neighborhood}, ${this.address.city} - Brazil`
          let mapbox_url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(location)}.json?access_token=pk.eyJ1IjoiYXBvbGFyIiwiYSI6ImNrYnNkc3RnMTAwbDEzM3FkNmhqemhqOWkifQ.j_hbZ475KcqoM4vteMlkAQ`
          jQuery.ajax({
            type: 'get',
            url: mapbox_url,
            success:(response)=> {
              var center = null
              jQuery.each(response.features, (i, v) => {
                  if ( center == null && v.id.indexOf('address') !== -1 && v.place_name.toLowerCase().indexOf(this.address.city.toLowerCase()) !== -1 ) {
                      center = v.center
                  }
              })
              this.address.latitude = center[1]
              this.address.longitude = center[0]
            } 
          })
      }
    },

    getAddressData(address) {
      this.clearAddress();
      let addressInputValue = document.getElementById(
        "map-autocomplete-on-address"
      ).value;
      const cityInfo = this.getAddressCityInfo(addressInputValue);
      this.address = {
        address: address["route"],
        number: address["street_number"],
        latitude: address["latitude"],
        longitude: address["longitude"],
        neighborhood: cityInfo["neighborhood"],
        city: cityInfo["city"],
      };
    },

    getAddressCityInfo(address) {
      //Rodovia Anchieta - Sacomã, São Paulo - SP, Brasil => [neighborhood: Sacomã, city: São Paulo]
      address = address.split(`-`)[1].split(",");
      return { neighborhood: address[0].trim(), city: address[1].trim() };
    },
    clearAddress() {
      this.address = {
        address: null,
        number: null,
        latitude: null,
        longitude: null,
        city: null,
        neighborhood: null,
      };
    },

    removeMoneyFormatting(value) {
      if (value) {
        let newValue = value
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", "");
        return parseInt(newValue);
      }
    },

    removeMoneyFormattingIgnoreCommas(value) {
      if (value) {
        let newValue = value
          .replace("R$ ", "")
          .split(",")[0]
          .replace(/\./g, "");
        return parseInt(newValue);
      }

      return 0;
    },

    removeAreaFormatting(value) {
      let newValue = value.replace("m²", "").replace(".", "");
      return parseInt(newValue);
    },

    changePropIdx(index) {
        this.propidx = null
        setTimeout(()=>{
            this.propidx = index
        },500)
    }
  },
};
</script>
