<template>
    <div class="input-options-wrapper">
        <div class="input-options-icons" @click.stop>
            <a class="return-button" :title="title" :isOpen="isOpen" @click="closeModal"><i class="icon icon-arrow-link-left dark-gray"></i></a>
            <h1 v-observe-visibility="checkIfMobile">{{title}}</h1>
        </div>
        <input @click.stop type="text" placeholder="Buscar" v-model="type_search" class="type-search">
        <div @click.stop class="input-options-checked-items" v-show="checkedValues.length > 0">
            <div class="checked-item-wrapper" ref="checkedItemWrapper">
                <span :key="checkedItem" class="checked-item" v-for="checkedItem in checkedValues">{{checkedItem}} <span
                        @click="selectCheckedValue(checkedItem)"
                        class="input-options-checked-items-close"> x </span></span>
            </div>
            <span @click="clearCheckedValues" class="checked_items_clear"
                  v-show="checkedValues.length !== 0">Limpar</span>
        </div>

        <div @click.stop class="input-options-component" v-if="multi">
            <details :key="list[0]" v-for="list in filteredOptions">
                <summary class="input-item first">
                    {{list[0]}}
                    <i class="icon icon-arrow-down gray"></i>
                </summary>
                <div class="border-t border-lightest-gray">
                    <p :class="{active: checkedValues.includes(item)}" :key="item"
                       @click.stop="selectCheckedValue(item)" class="input-subitem"
                       v-for="item in list[1]"> {{item}}

                        <label class="checkbox-container pointer-events-none" >
                            <input
                                    :value="item" type="checkbox" v-model="checkedValues">
                            <span class="checkmark blue"></span>
                        </label></p>
                </div>
            </details>
        </div>
        <div class="input-options-component" v-if="!multi">
            <div class="border-t border-lightest-gray">
                <p :class="{active: checkedValues.includes(data.label)}" :key="data.label"
                   @click.stop="selectCheckedValue(data.label)"
                   class="input-subitem" v-for="data in filteredOptions">
                    {{data.label}}
                    <label class="checkbox-container pointer-events-none" >
                        <input
                                :value="data.label" type="checkbox" v-model="checkedValues">
                        <span class="checkmark blue"></span>
                    </label></p>
            </div>

        </div>
        <!-- <button :class="{valid:!$v.$invalid}" v-on:click.prevent="sendForm">Continuar</button> -->
    </div>
</template>

<script>
    import jQuery from 'jquery'
    export default {
        name: 'InputOptions',
        watch: {
            checkedValues() {
                this.$emit('updateValue', this.checkedValues);
            },
            formData: {
                handler() {
                    this.checkedValues = this.selectedData || [];
                },
                deep: true
            }
        },
        props: {
            formData: {
                default: null
            },
            multi: {
                default: false,
                type: Boolean
            },
            selectedData: {
                type: Array
            },
            title: {
                type: String
            },
            isOpen: {
                type: Boolean
            },
            allowMultiple: {
                type: Boolean
            }

        },
        computed: {
            filteredOptions() {

                if ( this.type_search == '' ) {
                    return this.formData
                } else {
                    let filterd = []
                    let type_search_sanitize = this.type_search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                    this.formData.forEach((item)=>{
                        let label_sanitize = item.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                        if ( label_sanitize.indexOf(type_search_sanitize) != -1) {
                            filterd.push(item)
                        }
                    })
                    return filterd
                }
                
            }
        },
        data() {
            return {
                checkedValues: this.selectedData || [],
                sent: false,
                type_search: ''
            }
        },
        updated() {
            this.$refs.checkedItemWrapper.scrollLeft = this.$refs.checkedItemWrapper.scrollLeft + 999;
        },
        created(){
            history.pushState(null, null, location.href);
            window.onpopstate = function () {
                    jQuery(".return-button").each(function(){
                    if (this.attributes.isOpen){
                        this.click()
                    }
                })
            };
        },
        methods: {
            selectCheckedValue(value) {
                if(this.allowMultiple){
                    if (this.checkedValues.includes(value)) {
                        this.checkedValues = this.checkedValues.filter((checkedVal) => checkedVal !== value)
                    } else {
                        this.checkedValues.push(value);
                    }
                } else {
                    if (this.checkedValues.includes(value)) {
                        this.checkedValues = this.checkedValues.filter((checkedVal) => checkedVal !== value)
                    } else {
                        this.checkedValues = [];
                        this.checkedValues.push(value);
                    }
                }
            },

            clearCheckedValues() {
                this.checkedValues = [];
            },

            checkIfMobile(visible){
                if(visible){
                    document.documentElement.style.overflow = 'hidden'
                    return
                }
                document.documentElement.style.overflow = 'auto'
            },

            closeModal(){
                this.$emit('closeModal');
            },




        }
    }
</script>
<style lang="scss" scoped>
    .input-options-wrapper {
        input.type-search {
            width: 100%;
            border: 1px solid rgba(0,0,0,0.1);
            padding: 5px 10px;
            margin-top: 5px;
        }
        .input-options-component {
            margin-top: 5px;
        }
        @media only screen and (max-width: 768px) {
               input.type-search {
                   width: 98%;
                   margin: 0 auto;
                   display: block;
                   position: relative;
               }
        }
    }
</style>
